import type { AxiosError } from 'axios';
import { create, type StoreApi, type UseBoundStore } from 'zustand';

interface UseErrorBoundaryValues {
  APIError?: AxiosError;
}

interface ErrorBoundaryFunctions {
  setAPIError: (APIError: UseErrorBoundaryValues['APIError']) => void;
}

type ErrorBoundaryStore = UseErrorBoundaryValues & ErrorBoundaryFunctions;

export const useErrorBoundaryStore: UseBoundStore<StoreApi<ErrorBoundaryStore>> = create<ErrorBoundaryStore>()(set => ({
  setAPIError: APIError => set({ APIError }),
  APIError: undefined
}));
