import { BALANCE_APP_ID, NEMADIGITAL_APP_ID, PROTECTOR_APP_ID } from 'config/constants';
import { Entitlements } from 'core/shared/enums/entitlements.enum';
import { hasEntitlements } from 'core/utils/entitlement';
import type { LicensingStatusAppsResponse, LicensingStatusResponse } from 'entities/company/company.models';
import { getAppFromLastAppListByAppId } from 'entities/company/company.selector';
import { isEmpty } from 'lodash';
import moment from 'moment';
import type { PermissionFlagEntitlement, PermissionFlags } from './auth.models';

export const getPermissionFlags = (
  companyPage: LicensingStatusResponse,
  permissionFlags: Record<string, PermissionFlagEntitlement>
): PermissionFlags => {
  const todayDate = new Date();

  const appsFilteredByValidContractStartDate = companyPage.apps.filter(
    contFilter => moment(todayDate).diff(contFilter.contract.start, 'd') >= 0
  );

  const protectorApp = getAppFromLastAppListByAppId(appsFilteredByValidContractStartDate, PROTECTOR_APP_ID);
  const nemaDigitalApp = getAppFromLastAppListByAppId(appsFilteredByValidContractStartDate, NEMADIGITAL_APP_ID);

  const nemaDigitalValidContract = !!nemaDigitalApp && moment(nemaDigitalApp?.contract.end).diff(todayDate) > 0;
  const entitlementsKeys = protectorApp ? protectorApp.plan.entitlements.map(entitlement => entitlement.key) : [];
  let newPermissionFlags = Object.keys(permissionFlags).reduce(
    (acc, curr) => ({
      ...acc,
      [curr]: {
        entitlementKey: permissionFlags[curr].entitlementKey,
        value: entitlementsKeys.includes(permissionFlags[curr].entitlementKey as Entitlements)
      }
    }),
    permissionFlags
  );

  if (!isEmpty(protectorApp) && protectorApp) {
    newPermissionFlags = {
      ...newPermissionFlags,
      simpleCompanyDashboard: {
        ...newPermissionFlags.simpleCompanyDashboard,
        value: hasEntitlements(protectorApp, [Entitlements.SIMPLE_CEREALS]) || newPermissionFlags.simpleCompanyDashboard.value
      },
      hidePropertyDashboard: {
        ...newPermissionFlags.hidePropertyDashboard,
        value: hasEntitlements(protectorApp, [Entitlements.SIMPLE_CEREALS]) || newPermissionFlags.hidePropertyDashboard.value
      },
      tasksNotificationEmail: {
        ...newPermissionFlags.tasksNotificationEmail,
        value: hasEntitlements(protectorApp, [Entitlements.TASKS_NOTIFICATION_EMAIL]) || newPermissionFlags.tasksNotificationEmail.value
      },
      showScoutingScore: {
        ...newPermissionFlags.showScoutingScore,
        value: hasEntitlements(protectorApp, [Entitlements.SCOUTING_SCORE]) || newPermissionFlags.showScoutingScore.value
      }
    };
  }

  newPermissionFlags.nemaDigital = {
    ...newPermissionFlags.nemaDigital,
    value: nemaDigitalValidContract
  };

  return newPermissionFlags as unknown as PermissionFlags;
};

export const getLicensingStatus = (companyPage: LicensingStatusResponse): LicensingStatusResponse => {
  const todayDate = new Date();
  const appsFilteredByValidContractStartDate = companyPage.apps.filter(
    contFilter => moment(todayDate).diff(contFilter.contract.start, 'd') >= 0
  );

  const protectorApp = getAppFromLastAppListByAppId(appsFilteredByValidContractStartDate, PROTECTOR_APP_ID);
  const nemaDigitalApp = getAppFromLastAppListByAppId(appsFilteredByValidContractStartDate, NEMADIGITAL_APP_ID);
  const balanceDigitalApp = getAppFromLastAppListByAppId(appsFilteredByValidContractStartDate, BALANCE_APP_ID);

  const apps: LicensingStatusAppsResponse[] = [];

  if (protectorApp) apps.push(protectorApp);
  if (nemaDigitalApp) apps.push(nemaDigitalApp);
  if (balanceDigitalApp) apps.push(balanceDigitalApp);

  return {
    ...companyPage,
    apps
  };
};
