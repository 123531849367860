import type React from 'react';

export const OpenInNewIcon: React.FC = () => {
  return (
    <svg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.99999 2.5V1.5H10.5V5H9.49999V3.205L4.58499 8.12L3.87999 7.415L8.79499 2.5H6.99999ZM2.5 9.50002H9.5V6.00002H10.5V9.50002C10.5 10.05 10.05 10.5 9.5 10.5H2.5C1.945 10.5 1.5 10.05 1.5 9.50002V2.50001C1.5 1.95001 1.945 1.50001 2.5 1.50001H6V2.50001H2.5V9.50002Z'
        fill='#C2C7D0'
      />
    </svg>
  );
};
