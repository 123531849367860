import { TEN_MINUTES } from 'config/constants';
import type { UUID } from 'core/utils/basic.models';
import { useQuery } from 'core/utils/data-store/tools';
import { getParametersData } from 'pages/borer-risk/borer-risk-map/borer-risk-map.service';
import type { ParametersDataDTO } from 'pages/borer-risk/borer-risk-map/borer-risk.models';
import { ControlStrategyQuery } from 'pages/control-strategy/control-strategy-model';
import { getControlStrategyPhenomena } from 'pages/control-strategy/control-strategy.utils';
import { useParams } from 'react-router';
import type { Phenomenon } from '../../entities/phenomenon/phenomenon.models';
import type { PestsRiskParametersQuery } from './control-strategy.model';

export const useGetPhenomena = (seasonIds: UUID[], areaIds: UUID[], enabled = true): { data?: Phenomenon[]; isLoading: boolean } => {
  const { propertyId, companyId } = useParams();
  const { data, isLoading } = useQuery(
    [ControlStrategyQuery.PHENOMENA, propertyId],
    () => {
      return getControlStrategyPhenomena(companyId ?? '', propertyId ?? '', seasonIds, areaIds);
    },
    {
      enabled: !!(seasonIds?.length && areaIds?.length) && enabled,
      cacheTime: TEN_MINUTES
    }
  );

  return { data, isLoading };
};

export const getPestsRiskParametersData = async (propertyId?: UUID, seasonId?: UUID): Promise<ParametersDataDTO | undefined> => {
  if (!propertyId || !seasonId) {
    return undefined;
  }
  return getParametersData(propertyId, seasonId);
};

export const useGetPestsRiskParametersData = (propertyId?: UUID, seasonId?: UUID, disabled?: boolean): PestsRiskParametersQuery => {
  const { data, isLoading } = useQuery({
    queryKey: [ControlStrategyQuery.PESTS_RISK_DATA, propertyId, seasonId],
    queryFn: () => {
      return getPestsRiskParametersData(propertyId, seasonId);
    },
    enabled: !!propertyId && !!seasonId && !disabled,
    cacheTime: TEN_MINUTES,
    staleTime: Infinity
  });
  return { data, isLoading };
};
