import { Button, DatePicker, Drawer, Popconfirm, Radio, Spin, TreeSelect } from 'antd';
import { COLOR_OPTIONS } from 'config/style';
import STTypo from 'core/shared/typo';
import { TypeOptions } from 'core/shared/typo/typo.models.ts';
import type React from 'react';
import type { ReactNode } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SvgAlertGlyph from 'st-protector-icons/Glyph/AlertGlyph.tsx';
import '../report/report.styles.less';
import { ReportsTypes } from '../report/reports-types.enum.ts';
import { ExplicativeText } from '../report/drawers/explicative-text.component.tsx';
import HeaderTitle from '../report/drawers/header-title.component.tsx';
import type { UUID } from '../../../../utils/basic.models.ts';
import { useListPropertiesByOrgs, useSeasonDate } from './useSeasonReport.ts';
import { LoadingOutlined } from '@ant-design/icons';
import STTextArea from '../../../../../components/textArea/textArea.component.tsx';
import {
  SeasonReportStyledCalendar,
  SeasonReportStyledComments,
  SeasonReportStyledContainer,
  SeasonReportStyledSelector
} from './season-report.styles.ts';
import { SeasonReportOptions } from './useSeasonReport.models.ts';
import { getCurrentLanguage, validatePlansAndEntitlements } from '../../../../utils/functions.ts';
import { Entitlements } from '../../../../shared/enums/entitlements.enum.ts';
import { useDispatch, useSelector } from 'react-redux';
import type { AppState } from '../../../../../redux/app-state.ts';
import { RequestReport } from '../../../../../entities/reports/report.actions.ts';
import type { RequestReportBody } from '../../../../../entities/reports/report.models.ts';
import useSegmentTracking from '../../../../utils/segment/useSegmentTracking.ts';

interface SeasonReportInterface {
  reportType: ReportsTypes;
  callbackDrawer: () => void;
  icon?: ReactNode;
  visibleDrawer: boolean;
}

const { RangePicker } = DatePicker;

export const SeasonReport: React.FC<SeasonReportInterface> = ({ reportType, callbackDrawer, icon, visibleDrawer }) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const segmentTracking = useSegmentTracking();

  const licensingStatus = useSelector((state: AppState) => state.entities.company.licensingStatus);
  const SeasonReportWorkspaceEntitlement = validatePlansAndEntitlements(licensingStatus, null, [Entitlements.SEASON_REPORT_WORKSPACE]);

  const [selectedProperty, setSelectedProperty] = useState<UUID[]>([]);
  const [comment, setComment] = useState('');
  const [seasonReportOption, setSeasonReportOption] = useState<SeasonReportOptions>(
    SeasonReportWorkspaceEntitlement ? SeasonReportOptions.BY_ORG : SeasonReportOptions.BY_PROPERTY
  );

  const { orgTree, mutate: getPropertiesInfo } = useListPropertiesByOrgs(SeasonReportWorkspaceEntitlement);

  const { timeRangeFrom, timeRangeTo, disabledDate, handleTimeRange } = useSeasonDate();

  const generateReport = () => {
    const reportPayload: RequestReportBody = {
      type: ReportsTypes.SEASON_REPORT,
      data_request: {
        language: getCurrentLanguage(),
        orgIds: selectedProperty,
        comment: comment,
        timeRangeFrom: timeRangeFrom.format('YYYY-MM-DD'),
        timeRangeTo: timeRangeTo.format('YYYY-MM-DD'),
        seasonReportOption: seasonReportOption
      }
    };
    dispatch(RequestReport(reportPayload));
    segmentTracking.track('Reports - End season - Click on Generate Report');
    callbackDrawer();
  };

  const onLoadData = async (treeNode: { props: { value: string } }): void => {
    const orgId = treeNode.props.value;
    await new Promise<void>((resolve, reject) => {
      getPropertiesInfo(orgId, {
        onSuccess: () => resolve(),
        onError: () => reject(new Error('Something went wrong'))
      });
    });
  };

  return (
    <Drawer
      className='st-report-drawer'
      placement='right'
      visible={visibleDrawer}
      width={400}
      title={<HeaderTitle icon={icon} type={reportType} />}
      onClose={callbackDrawer}
      maskClosable>
      <div className='st-report-drawer__content' data-testid='season-report-drawer'>
        <ExplicativeText type={reportType} />

        <div className='st-report-drawer__form' data-testid='season-report-form'>
          {!orgTree?.length && <Spin indicator={<LoadingOutlined style={{ fontSize: 16 }} spin />} />}
          <STTypo type={TypeOptions.P1} color={COLOR_OPTIONS.NEUTRAL_60}>
            {SeasonReportWorkspaceEntitlement ? t('modals.season_report.select_orgs') : t('modals.season_report.select_properties')}
          </STTypo>
          <TreeSelect
            showSearch
            style={{ width: '100%' }}
            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
            allowClear
            suffixIcon
            treeCheckable
            loadData={onLoadData}
            onChange={setSelectedProperty}
            value={selectedProperty}
            treeData={orgTree}
            maxTagCount={2}
            filterTreeNode={(
              inputValue,
              treeNode: {
                title: string;
              }
            ) => treeNode?.title?.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0}
          />
        </div>
        <SeasonReportStyledContainer>
          <STTypo type={TypeOptions.P1} color={COLOR_OPTIONS.NEUTRAL_60}>
            {t('modals.season_report.options')}
          </STTypo>
          <Radio.Group onChange={event => setSeasonReportOption(event.target.value as SeasonReportOptions)} value={seasonReportOption}>
            <SeasonReportStyledSelector>
              <Radio data-testid={SeasonReportOptions.BY_ORG} value={SeasonReportOptions.BY_ORG}>
                <STTypo type={TypeOptions.P1} color={COLOR_OPTIONS.NEUTRAL_100}>
                  {t('modals.season_report.options_by_org')}
                </STTypo>
              </Radio>
              <Radio data-testid={SeasonReportOptions.BY_PROPERTY} value={SeasonReportOptions.BY_PROPERTY}>
                <STTypo type={TypeOptions.P1} color={COLOR_OPTIONS.NEUTRAL_100}>
                  {t('modals.season_report.options_by_property')}
                </STTypo>
              </Radio>
            </SeasonReportStyledSelector>
          </Radio.Group>
          <STTypo type={TypeOptions.P1} color={COLOR_OPTIONS.NEUTRAL_60}>
            {t('modals.timeline_xray_report.fields.time_range')}
          </STTypo>
          <SeasonReportStyledCalendar>
            <RangePicker
              value={[timeRangeFrom, timeRangeTo]}
              style={{ width: '432px' }}
              format='L'
              size='large'
              separator='—'
              onChange={handleTimeRange}
              allowClear={false}
              disabledDate={disabledDate}
            />
          </SeasonReportStyledCalendar>
          <SeasonReportStyledComments>
            <STTypo type={TypeOptions.P1} color={COLOR_OPTIONS.NEUTRAL_60}>
              {t('modals.season_report.comments')}
            </STTypo>
            <STTextArea
              setValue={setComment}
              value={comment}
              testID='season-report-drawer-textarea'
              rows={4}
              maxLength={4400}
              alignCounter='right'
            />
          </SeasonReportStyledComments>
        </SeasonReportStyledContainer>
        <div className='st-report-drawer__actions'>
          <Popconfirm
            placement='bottomRight'
            icon={
              <div className='st-report-drawer__confirm-icon-container'>
                <SvgAlertGlyph fill='#F74141' />
              </div>
            }
            overlayClassName='st-report-drawer__confirm'
            title={
              <div className='st-report-drawer__confirm-delete'>
                <STTypo type={TypeOptions.P1}>{`${t('report.back')}`}</STTypo>
              </div>
            }
            onConfirm={callbackDrawer}
            okText={t('general.complete')}
            okButtonProps={{
              style: {
                backgroundColor: '#F74141',
                border: 'none',
                borderRadius: '4px',
                paddingTop: '2px'
              }
            }}
            cancelButtonProps={{
              style: {
                backgroundColor: '#F2F4F6',
                border: 'none',
                borderRadius: '4px',
                paddingTop: '2px',
                color: COLOR_OPTIONS.PRIMARY
              }
            }}
            cancelText={t('general.back')}>
            <Button
              type='ghost'
              size='default'
              className='st-report-drawer_actions-cancel'
              data-testid='report-detailed-consolidated-cancel'>
              {t('general.cancel')}
            </Button>
          </Popconfirm>
          <Button
            type='primary'
            size='default'
            className='st-report-drawer_actions-submit'
            onClick={generateReport}
            disabled={!selectedProperty.length}
            data-testid='report-detailed-consolidated-submit'>
            {t('report.action')}
          </Button>
        </div>
      </div>
    </Drawer>
  );
};
