import leafhopper from 'assets/images/ScoutingPlans/leafhopper-min.jpg';
import postHarvest from 'assets/images/ScoutingPlans/postHarvest-min.jpg';
import sugarcaneBorer from 'assets/images/ScoutingPlans/sugarcane-borer-min.jpg';
import scoutingPlanPage from 'assets/images/ScoutingPlans/sugarcane-overlay-min.jpg';
import taskRouting from 'assets/images/ScoutingPlans/taskRouting-min.jpg';
import john_deer_application from 'assets/images/application-icon.svg';
import area_icon from 'assets/images/area-icon.svg';
import base_icon from 'assets/images/base.svg';
import check_circle_outline from 'assets/images/check_circle_outline.svg';
import configured_icon from 'assets/images/configured_icon.svg';
import construction_icon from 'assets/images/construction-icon.svg';
import all_seen_control_strategy from 'assets/images/control-strategy/all-seen-empty-state.png';
import delete_icon from 'assets/images/delete-icon.svg';
import dr_bg_finished from 'assets/images/disease-risk/bg-finished.png';
import dr_bg_welcome from 'assets/images/disease-risk/bg-welcome.png';
import dr_mileos_by_arvalis from 'assets/images/disease-risk/dr-mileos-by-arvalis.svg';
import dr_syt_icon from 'assets/images/disease-risk/dr-syt-icon.png';
import no_data from 'assets/images/ds/empty-state/no-data.png';
import no_result from 'assets/images/ds/empty-state/no-result.png';
import edit_icon from 'assets/images/edit-icon.svg';
import empty_data from 'assets/images/empty-data.png';
import empty_state_integration from 'assets/images/empty-state-integration.png';
import falbackImageMinor from 'assets/images/fallback-image-48x32.png';
import fallbackimage from 'assets/images/fallback-image.png';
import farms_icon from 'assets/images/farms_icon.svg';
import logo_farmshots from 'assets/images/farmshots-logo.svg';
import farmshots from 'assets/images/farmshots.svg';
import fieldClusterLevel1Example from 'assets/images/field-clustering/field-cluster-lvl-1.png';
import fieldClusterLevel2Example from 'assets/images/field-clustering/field-cluster-lvl-2.png';
import folder_empty_state from 'assets/images/folder-empty-state.svg';
import john_deer_harvest from 'assets/images/harvest-icon.svg';
import logo_protector from 'assets/images/icon-protector.svg';
import trendGraphEmptyState from 'assets/images/illustration_emptyStateLegal.svg';
import info_icon from 'assets/images/info-glyph.svg';
import nectar_logo from 'assets/images/integrations/nectar/nectar-logo.png';
import rea_logo_img from 'assets/images/integrations/rea/rea-logo-img.png';
import rea_logo from 'assets/images/integrations/rea/rea_logo.png';
import saa_logo_white from 'assets/images/integrations/saa/saa-logo-white.png';
import saa_logo from 'assets/images/integrations/saa/saa-logo.png';
import siagri_logo from 'assets/images/integrations/siagri/siagri-logo.png';
import john_deer_no_activity from 'assets/images/jd-no-activity.svg';
import john_deer_no_area_chain from 'assets/images/jd-no-area-chain.svg';
import john_deer_no_area from 'assets/images/jd-no-area.svg';
import john_deer_logo from 'assets/images/jdoc-logo.svg';
import john_deer from 'assets/images/johndeer.svg';
import logo_cropwise from 'assets/images/logo-cropwise.svg';
import logo_imagery from 'assets/images/logo-imagery.svg';
import logo_protector_collpsed_menu_black from 'assets/images/logo-protector-black.svg';
import logo_protector_blue from 'assets/images/logo-protector-blue.svg';
import logo_protector_white from 'assets/images/logo-protector-cropwiser-white.svg';
import logo_green from 'assets/images/logo-protector-default.svg';
import logo_protector_collpsed_menu from 'assets/images/logo-protector-white.svg';
import logo_protector_default from 'assets/images/logo-protector.svg';
import logo_syngenta_digital_collpsed_menu_black from 'assets/images/logo-syngenta-digital-black.svg';
import logo_protector_login from 'assets/images/logo_protector_login.svg';
import map_subtitle_icon from 'assets/images/map-subtitle.svg';
import map_pattern_stripe_spray from 'assets/images/map/pattern-stripe-spray.png';
import map_pattern_stripe from 'assets/images/map/pattern-stripe.png';
import no_stations_found from 'assets/images/no-stations-found.png';
import page_couldnt_load from 'assets/images/page-couldnt-load.svg';
import paper_icon from 'assets/images/paper-icon.svg';
import perfect_flight_big from 'assets/images/perfect-flight-big.png';
import perfect_flight_logo from 'assets/images/perfect-flight-logo.svg';
import perfect_flight_small from 'assets/images/perfect-flight-small.png';
import perfect_flight from 'assets/images/perfect-flight.svg';
import john_deer_planting from 'assets/images/planting-icon.svg';
import falbackImagePopup from 'assets/images/popup-default-fallback-image.png';
import protector_icon_integration from 'assets/images/protector-icon-integration.svg';
import protector_icon from 'assets/images/protector-icon.svg';
import logo_syngenta_digital from 'assets/images/syngenta-digital.svg';
import trapview_logo from 'assets/images/trapview-logo.svg';
import widget_empty_state from 'assets/images/widget-empty-state.png';
import heatmap_close from 'assets/images/widget-thumbnails/heatmap-over-time-close.png';
import heatmap_fullscreen from 'assets/images/widget-thumbnails/heatmap-over-time-fullscreen.png';
import heatmap_move from 'assets/images/widget-thumbnails/heatmap-over-time-move.png';

const images = {
  logo_green,
  logo_syngenta_digital,
  logo_protector_login,
  logo_cropwise,
  logo_protector_collpsed_menu,
  logo_protector_collpsed_menu_black,
  logo_syngenta_digital_collpsed_menu_black,
  logo_protector_s: logo_protector,
  logo_protector_default,
  logo_protector_blue,
  logo_imagery,
  logo_protector,
  fallbackimage,
  falbackImagePopup,
  falbackImageMinor,
  farmshots,
  perfect_flight,
  perfect_flight_logo,
  perfect_flight_big,
  perfect_flight_small,
  john_deer,
  john_deer_no_area,
  john_deer_no_area_chain,
  john_deer_no_activity,
  john_deer_logo,
  john_deer_planting,
  john_deer_application,
  perfect_flight_application: john_deer_application,
  john_deer_harvest,
  folder_empty_state,
  logo_farmshots,
  widget_empty_state,
  heatmap_move,
  heatmap_fullscreen,
  heatmap_close,
  empty_data,
  empty_state_integration,
  map_pattern_stripe,
  map_pattern_stripe_spray,
  no_stations_found,
  all_seen_control_strategy,
  trapview_logo,
  saa_logo,
  saa_logo_white,
  rea_logo,
  rea_logo_img,
  no_data,
  no_result,
  area_icon,
  base_icon,
  edit_icon,
  delete_icon,
  construction_icon,
  configured_icon,
  farms_icon,
  protector_icon,
  protector_icon_integration,
  info_icon,
  paper_icon,
  dr_bg_welcome,
  dr_bg_finished,
  dr_mileos_by_arvalis,
  dr_syt_icon,
  check_circle_outline,
  map_subtitle_icon,
  siagri_logo,
  nectar_logo,
  scoutingPlanPage,
  leafhopper,
  sugarcaneBorer,
  postHarvest,
  fieldClusterLevel1Example,
  fieldClusterLevel2Example,
  logo_protector_white,
  trendGraphEmptyState,
  taskRouting,
  page_couldnt_load
};

export { images as default, images };
