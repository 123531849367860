import { useQuery } from '@tanstack/react-query';
import type { AxiosError, CancelToken as CancelTokenType } from 'axios';
import axios from 'axios';
import { NEMADIGITAL_URL } from 'config/constants';
import type { FeatureCollection, GeoJsonProperties, Geometry } from 'geojson';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import type { AppState } from 'redux/app-state';
import sentryService from 'sentry/service';
import type { NemadigitalCropCycleReport } from 'syngenta-digital-react-cropwise-nemadigital/dist/module/map-overlay/map-overlay.types';
import type { UUID } from 'syngenta-digital-react-cropwise-notes/dist/src/utils/models';
import { mapNemadigitalExecutionsByCropCycle, sortNemadigitalExecution } from './nemadigital.functions';
import type { NemadigitalExecution, NemadigitalPropertyReportsQueryResponse } from './nemadigital.model';
import { NemadigitalQueryType } from './nemadigital.model';
import { useSuggestionPointsStore } from '../../pages/tasks/shared/suggest-points/suggest-points.domain.ts';

const nemaDigitalApiUrl = NEMADIGITAL_URL;

const { CancelToken } = axios;

export const getPropertyLatestExecution = async (propertyId: UUID | undefined): Promise<NemadigitalExecution | undefined> => {
  if (propertyId) {
    const response = await axios.get<NemadigitalExecution>(`${nemaDigitalApiUrl}/reports/download/${propertyId}`);
    return response.data;
  }
  return undefined;
};

export const useGetPropertyLatestExecution = (enabled: boolean): NemadigitalPropertyReportsQueryResponse => {
  const [t] = useTranslation();
  const [cropCycles, setCropCycles] = useState<string[]>([]);
  const [reportsPerCropCycles, setReportsPerCropCycles] = useState<Record<string, NemadigitalCropCycleReport>>({});

  const { setExecutionId, setLastCropCyclesFinishedName, lastCropCyclesFinishedName, setCurrentStatus } = useSuggestionPointsStore();
  const { propertyId } = useParams();

  const systemFlags = useSelector((state: AppState) => state.uiState.global.systemFlags);

  const { data, isFetching } = useQuery(
    [NemadigitalQueryType.GET_PROPERTY_NEMADIGITAL_JOBS, propertyId],
    () => {
      return getPropertyLatestExecution(propertyId);
    },
    { enabled: enabled && !!systemFlags?.P40_26299_nemadigital_map }
  );

  useEffect(() => {
    if (data) {
      const dataSorted = sortNemadigitalExecution(data);

      const mappedExecutionsByCycle = mapNemadigitalExecutionsByCropCycle(dataSorted, t);

      const name = mappedExecutionsByCycle?.cropCycles?.[mappedExecutionsByCycle?.cropCycles?.length - 1] ?? '';
      const report = mappedExecutionsByCycle?.reportsPerCropCycles[name];
      setExecutionId(report?.executionId?.toString());
      setLastCropCyclesFinishedName(name);
      setCurrentStatus(report?.status);

      setCropCycles(mappedExecutionsByCycle.cropCycles);
      setReportsPerCropCycles(mappedExecutionsByCycle.reportsPerCropCycles);
    }
  }, [data]);

  return {
    cropCycles,
    reportsPerCropCycles,
    isLoadingNemadigital: isFetching,
    lastCropCyclesFinished: lastCropCyclesFinishedName
  };
};

export const getBatchNemadigitalGeonjson = async (
  urlList: string[],
  setPropertyNemadigitalGeojson: React.Dispatch<React.SetStateAction<FeatureCollection<Geometry, GeoJsonProperties> | undefined>>,
  setGroupsDownloadFinished: React.Dispatch<React.SetStateAction<number>>,
  cancelToken: CancelTokenType
): Promise<void> => {
  const promises = urlList.map(geojsonUrl => axios.get<GeoJSON.FeatureCollection>(geojsonUrl, { cancelToken }));

  const data = await Promise.all(promises).catch((error: Error | AxiosError) => {
    sentryService.captureException(
      error,
      {
        fileName: 'nemadigital.query.ts',
        method: 'getBatchNemadigitalGeonjson',
        description: 'Failed to download nemadigital geojson'
      },
      true
    );
  });

  try {
    if (data?.length) {
      setGroupsDownloadFinished(previuosGroupsFinished => previuosGroupsFinished + 1);
      setPropertyNemadigitalGeojson(previousNemadigitalGeojson => {
        const features = data.reduce<GeoJSON.Feature[]>((featureArray, geojson) => {
          return featureArray.concat(geojson.data.features);
        }, previousNemadigitalGeojson?.features ?? []);
        return {
          type: 'FeatureCollection',
          features
        };
      });
    }
  } catch (error) {
    sentryService.captureException(
      error as Error,
      {
        fileName: 'nemadigital.query.ts',
        method: 'getBatchNemadigitalGeonjson',
        description: 'Failed to process nemadigital geojson data'
      },
      true
    );
  }
};

export const useGetNemadigitalResultsGeojson = (urlListGroups: string[][], selectedNemadigitalCropCycle?: string) => {
  const [propertyNemadigitalGeojson, setPropertyNemadigitalGeojson] = useState<GeoJSON.FeatureCollection>();
  const [groupsDownloadFinished, setGroupsDownloadFinished] = useState<number>(0);
  const cancelSource = useRef(CancelToken.source());

  const { propertyId } = useParams();

  const isLoadingNemadigitalGeojson = !!urlListGroups.length && groupsDownloadFinished < urlListGroups.length;

  useEffect(() => {
    if (propertyId && selectedNemadigitalCropCycle) {
      setPropertyNemadigitalGeojson({
        type: 'FeatureCollection',
        features: []
      });
      setGroupsDownloadFinished(0);
      cancelSource.current.cancel();
    }
  }, [propertyId, selectedNemadigitalCropCycle]);

  useEffect(() => {
    cancelSource.current = CancelToken.source();
    urlListGroups.forEach(urlList => {
      void getBatchNemadigitalGeonjson(urlList, setPropertyNemadigitalGeojson, setGroupsDownloadFinished, cancelSource.current.token);
    });
  }, [urlListGroups]);

  return {
    propertyNemadigitalGeojson,
    isLoadingNemadigitalGeojson
  };
};
