import type React from 'react';
import type { ComponentProps } from 'react';

interface SvgNemaSamplingPointsProps extends ComponentProps<'svg'> {
  size?: number | string;
  fill?: string;
}
const SvgNemaSamplingPoints: React.FC<SvgNemaSamplingPointsProps> = props => (
  <svg width={props.size ?? '18'} height={props.size ?? '18'} viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8.25 11.5331V8.26728C7.45 8.26728 6.68125 8.11403 5.94375 7.80753C5.20625 7.50153 4.55625 7.06729 3.99375 6.50479C3.43125 5.94229 3 5.29228 2.7 4.55478C2.4 3.81728 2.25 3.04854 2.25 2.24854V0.748535H3.75C4.5375 0.748535 5.3 0.901535 6.0375 1.20754C6.775 1.51404 7.425 1.94853 7.9875 2.51103C8.375 2.89853 8.697 3.32353 8.9535 3.78604C9.2095 4.24853 9.40625 4.74228 9.54375 5.26728C9.60625 5.17978 9.675 5.09553 9.75 5.01453C9.825 4.93303 9.90625 4.84854 9.99375 4.76103C10.5562 4.19854 11.2063 3.76403 11.9438 3.45754C12.6813 3.15154 13.45 2.99853 14.25 2.99853H15.75V4.49853C15.75 5.29853 15.597 6.06728 15.291 6.80478C14.9845 7.54228 14.55 8.19228 13.9875 8.75478C13.425 9.31728 12.778 9.74853 12.0465 10.0485C11.3155 10.3485 10.55 10.4985 9.75 10.4985V11.5328L12.7503 13.033V15.4965L11.2503 16.2465V13.96L9.75 13.2099V17.2485H8.25V13.2102L6.75027 13.96V16.2465L5.25027 15.4965V13.033L8.25 11.5331ZM8.25 6.74853C8.25 6.14853 8.1345 5.57653 7.9035 5.03253C7.672 4.48903 7.34375 4.00479 6.91875 3.57979C6.49375 3.15479 6.00925 2.82678 5.46525 2.59578C4.92175 2.36428 4.35 2.24854 3.75 2.24854C3.75 2.84854 3.8625 3.42353 4.0875 3.97354C4.3125 4.52354 4.6375 5.01103 5.0625 5.43603C5.4875 5.86103 5.975 6.18604 6.525 6.41104C7.075 6.63603 7.65 6.74853 8.25 6.74853ZM9.75 8.99854C10.35 8.99854 10.922 8.88603 11.466 8.66103C12.0095 8.43603 12.4938 8.11103 12.9188 7.68603C13.3438 7.26103 13.672 6.77353 13.9035 6.22354C14.1345 5.67353 14.25 5.09853 14.25 4.49853C13.65 4.49853 13.075 4.61403 12.525 4.84503C11.975 5.07653 11.4875 5.40478 11.0625 5.82978C10.6375 6.25478 10.3125 6.73903 10.0875 7.28253C9.8625 7.82653 9.75 8.39853 9.75 8.99854Z'
      fill={props.fill ?? '#C1462B'}
    />
  </svg>
);

export default SvgNemaSamplingPoints;
