import { StyledCard, StyledContentWrapper, StyledDescription, StyledIconWrapper, StyledTitle } from './report-disease-risk-card.styles';

interface ReportNotesCardProps {
  onClick?: () => void;
  title: string;
  description: string;
  icon: React.ReactNode;
}

export const ReportNotesCard = ({ onClick, title, description, icon }: ReportNotesCardProps) => {
  return (
    <StyledCard data-testid='report-disease-risk-card' onClick={onClick}>
      <StyledContentWrapper>
        <StyledIconWrapper>{icon}</StyledIconWrapper>

        <StyledTitle>{title}</StyledTitle>

        <StyledDescription>{description}</StyledDescription>
      </StyledContentWrapper>
    </StyledCard>
  );
};
