import { useSelector } from 'react-redux';
import { getCompanyEntitiesInEntities } from '../../../../core.selectors.ts';
import type { Property } from '../../../../../entities/property/property.models.ts';
import { useMemo, useState } from 'react';
import type { Company } from 'entities/company/company.models.ts';
import { getPropertyByOrg } from './season-report.service.ts';
import moment from 'moment';
import type { Moment } from '../../../../utils/basic.models.ts';
import type { RangePickerValue } from 'antd/lib/date-picker/interface';
import { getSelectedCompany } from '../../../../../entities/company/company.reducer.ts';
import { useMutation } from '../../../../utils/data-store/tools.ts';
import type { TreeNode } from 'antd/lib/tree-select';
import type { Dictionary } from '../../../../../config/types.ts';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';

export const useListPropertiesByOrgs = (SeasonReportWorkspaceEntitlement: boolean) => {
  const [t] = useTranslation();

  const companies = useSelector(getCompanyEntitiesInEntities);
  const currentCompany = useSelector(getSelectedCompany)!;
  const [propertiesByOrgList, setPropertiesByOrgList] = useState<Dictionary<Property[]>>({});

  const allCompanies = useMemo(() => {
    if (SeasonReportWorkspaceEntitlement) return Object.values(companies);
    return [currentCompany];
  }, [SeasonReportWorkspaceEntitlement, currentCompany, companies]);

  const { mutate } = useMutation(
    ['getPropertiesInfo'],
    (companyId: string) => {
      return getPropertyByOrg(companyId);
    },
    {
      onSuccess: (data, value) => {
        setPropertiesByOrgList(prev => {
          return {
            ...prev,
            [value as unknown as string]: data
          };
        });
      },
      onError: () => {
        notification.error({ message: t('modals.season_report.alert.select_orgs') });
      }
    }
  );

  const orgTree = useMemo(() => {
    return buildOrgTree(allCompanies, propertiesByOrgList);
  }, [allCompanies, propertiesByOrgList]);

  return { orgTree, mutate };
};

export const buildOrgTree = (allCompanies: Company[], propertiesByOrg: Dictionary<Property[]>) => {
  const orgTree: TreeNode[] = [];

  allCompanies?.forEach(company => {
    orgTree.push({
      title: company.name,
      value: company.id,
      key: company.id,
      children: propertiesByOrg[company.id]?.map(property => {
        return {
          title: property.name,
          value: property.id,
          key: property.id,
          isLeaf: true
        };
      })
    });
  });
  return orgTree;
};

export const useSeasonDate = () => {
  const initialTimeRangeTo = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  const initialTimeRangeFrom = moment().subtract(7, 'd').set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

  const [timeRangeFrom, setTimeRangeFrom] = useState(initialTimeRangeFrom);
  const [timeRangeTo, setTimeRangeTo] = useState(initialTimeRangeTo);

  const disabledDate = (current: Moment | null) => {
    return current ? current > moment().endOf('day') : false;
  };

  const handleTimeRange = (dates: RangePickerValue) => {
    if (dates?.[0] && dates[1]) {
      setTimeRangeFrom(dates[0].set({ hour: 0, minute: 0, second: 0, millisecond: 0 }));
      setTimeRangeTo(dates[1].set({ hour: 0, minute: 0, second: 0, millisecond: 0 }));
    }
  };

  return { timeRangeFrom, timeRangeTo, disabledDate, handleTimeRange };
};
