export const NemaSamplingReportSvgCard = () => {
  return (
    <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_35509_69023)'>
        <rect width='32' height='32' rx='8' fill='white' />
        <rect width='32' height='32' rx='4' fill='#FFF1ED' />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M15.25 18.5331V15.2673C14.45 15.2673 13.6813 15.114 12.9438 14.8075C12.2063 14.5015 11.5562 14.0673 10.9937 13.5048C10.4312 12.9423 10 12.2923 9.7 11.5548C9.4 10.8173 9.25 10.0485 9.25 9.24854V7.74854H10.75C11.5375 7.74854 12.3 7.90154 13.0375 8.20754C13.775 8.51404 14.425 8.94853 14.9875 9.51103C15.375 9.89853 15.697 10.3235 15.9535 10.786C16.2095 11.2485 16.4063 11.7423 16.5438 12.2673C16.6063 12.1798 16.675 12.0955 16.75 12.0145C16.825 11.933 16.9062 11.8485 16.9937 11.761C17.5562 11.1985 18.2063 10.764 18.9438 10.4575C19.6813 10.1515 20.45 9.99853 21.25 9.99853H22.75V11.4985C22.75 12.2985 22.597 13.0673 22.291 13.8048C21.9845 14.5423 21.55 15.1923 20.9875 15.7548C20.425 16.3173 19.778 16.7485 19.0465 17.0485C18.3155 17.3485 17.55 17.4985 16.75 17.4985V18.5328L19.7503 20.033V22.4965L18.2503 23.2465V20.96L16.75 20.2099V24.2485H15.25V20.2102L13.7503 20.96V23.2465L12.2503 22.4965V20.033L15.25 18.5331ZM15.25 13.7485C15.25 13.1485 15.1345 12.5765 14.9035 12.0325C14.672 11.489 14.3438 11.0048 13.9188 10.5798C13.4938 10.1548 13.0093 9.82678 12.4653 9.59578C11.9218 9.36428 11.35 9.24854 10.75 9.24854C10.75 9.84854 10.8625 10.4235 11.0875 10.9735C11.3125 11.5235 11.6375 12.011 12.0625 12.436C12.4875 12.861 12.975 13.186 13.525 13.411C14.075 13.636 14.65 13.7485 15.25 13.7485ZM16.75 15.9985C17.35 15.9985 17.922 15.886 18.466 15.661C19.0095 15.436 19.4938 15.111 19.9188 14.686C20.3438 14.261 20.672 13.7735 20.9035 13.2235C21.1345 12.6735 21.25 12.0985 21.25 11.4985C20.65 11.4985 20.075 11.614 19.525 11.845C18.975 12.0765 18.4875 12.4048 18.0625 12.8298C17.6375 13.2548 17.3125 13.739 17.0875 14.2825C16.8625 14.8265 16.75 15.3985 16.75 15.9985Z'
          fill='#C1462B'
        />
      </g>
      <defs>
        <clipPath id='clip0_35509_69023'>
          <rect width='32' height='32' rx='8' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};
