import type Quill from 'quill';
import { useCallback, useRef } from 'react';
import type { RecommendationNote } from './report-disease-risk-notes.type';

/**
 * Hook to manage disease risk notes with modification tracking
 * @example
 * const {
 *   notesRef,
 *   richTextRef,
 *   handleOnChange,
 *   getModifiedNotes,
 *   clearModifiedStatus,
 *   hasModifiedNotes
 * } = useReportDiseaseRiskNotes();
 *
 * // Save modified notes
 * const handleSave = async () => {
 *   if (!hasModifiedNotes()) return;
 *
 *   try {
 *     const modifiedNotes = getModifiedNotes();
 *     await Promise.all(modifiedNotes.map(note => api.updateNote(note.season_id, note.notes)));
 *     clearModifiedStatus();
 *   } catch (error) {
 *     console.error('Failed to save notes:', error);
 *   }
 * };
 */
const useReportDiseaseRiskNotes = () => {
  const notesRef = useRef<RecommendationNote[]>([]);
  const modifiedNoteIdsRef = useRef<Set<string>>(new Set());

  const handleOnChange = useCallback((seasonId: string, ref: Quill | null) => {
    if (!ref) return;

    const hasText = ref.getLength() > 1;
    const newNote = hasText ? ref.getSemanticHTML() : '';

    notesRef.current = updateUniqueNotes(notesRef.current, seasonId, newNote);
    modifiedNoteIdsRef.current.add(seasonId);
  }, []);

  const getModifiedNotes = useCallback(() => {
    return notesRef.current.filter(note => modifiedNoteIdsRef.current.has(note.season_id));
  }, []);

  const clearModifiedStatus = useCallback(() => {
    modifiedNoteIdsRef.current = new Set();
  }, []);

  const hasModifiedNotes = useCallback(() => {
    return modifiedNoteIdsRef.current.size > 0;
  }, []);

  return {
    notesRef,
    handleOnChange,
    getModifiedNotes,
    clearModifiedStatus,
    hasModifiedNotes
  };
};

export default useReportDiseaseRiskNotes;

export const updateUniqueNotes = (notes: RecommendationNote[], seasonId: string, newNote: string): RecommendationNote[] => [
  ...notes.filter(note => note.season_id !== seasonId),
  { season_id: seasonId, notes: newNote }
];
