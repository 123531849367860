import { debounce } from 'lodash';
import Quill, { type Delta } from 'quill';
import 'quill/dist/quill.snow.css';
import { forwardRef, useEffect, useMemo, useRef } from 'react';

interface RichTextProps {
  defaultValue?: Delta;
  className?: string;
  onChange?: () => void;
  debounceTime?: number;
  placeholder?: string;
}

const RichText = forwardRef<Quill, RichTextProps>(({ defaultValue, className, onChange, debounceTime = 300, placeholder }, ref) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const defaultValueRef = useRef(defaultValue);

  const debouncedOnChange = useMemo(() => (onChange ? debounce(onChange, debounceTime) : undefined), [onChange, debounceTime]);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const editorContainer = container.appendChild(container.ownerDocument.createElement('div'));
    const quill = new Quill(editorContainer, {
      theme: 'snow',
      placeholder: placeholder
    });

    if (ref && 'current' in ref) {
      ref.current = quill;
    }

    if (defaultValueRef.current) {
      quill.setContents(defaultValueRef.current);
    }

    quill.on('text-change', () => {
      debouncedOnChange?.();
    });

    return () => {
      debouncedOnChange?.cancel();
      if (ref && 'current' in ref) {
        ref.current = null;
      }
      container.innerHTML = '';
    };
  }, [ref, debouncedOnChange, debounceTime, placeholder]);

  return <div ref={containerRef} className={className}></div>;
});

RichText.displayName = 'RichText';

export default RichText;
