import { useConfig, type CWElementsConfig } from '@cw-elements/config';
import MfeDynamicSlot, { CROPWISE_MFES_PATH, initMfeDynamicSlot } from '@cw-elements/mfe-dynamic-slot';
import MainLayout from 'components/layout.component';
import RouteByPermissionFlag from 'infra/routes/by-permission-flag.route';
import NemaDigital from 'infra/routes/nema-digital.route';
import Notes from 'infra/routes/notes.route';
import ProtectedRoute from 'infra/routes/protected.route';
import SmartPlan from 'infra/routes/smart-plan.route';
import Routes from 'infra/third-party/sentry.routes';
import LoginOauth2 from 'pages/login/login.oauth2.container';
import React, { useEffect } from 'react';
import { Route, useNavigate } from 'react-router-dom';

const OpportunityMachineContainer = React.lazy(() => import('pages/opportunity-machine/dashboard/opportunity-machine.container'));
const OpportunityMachineSetupContainer = React.lazy(() => import('pages/opportunity-machine/setup/opportunity-machine-setup.container'));
const HallOfCompanies = React.lazy(() => import('pages/hall-of-companies/hall-of-companies.container'));
const CLevelReportContainer = React.lazy(() => import('pages/c-level-report/c-level-report.container'));
const HallOfProperties = React.lazy(() => import('pages/dashboard/dashboard.container'));
const CompanyPresentation = React.lazy(() => import('pages/presentation-mode/presentation-mode.container'));
const CompanyWarehouse = React.lazy(() => import('pages/warehouse/company-warehouse.container'));
const Financial = React.lazy(() => import('pages/finance/finance.container'));
const PropertyDashboard = React.lazy(() => import('pages/dashboard-farm/dashboard-farm.container'));
const AreaInfo = React.lazy(() => import('pages/timeline/area-info/area-info'));
const Timeline = React.lazy(() => import('pages/timeline/timeline.page.container'));
const FixedPoints = React.lazy(() => import('pages/fixed-points/fixed-points.container'));
const StaticPoints = React.lazy(() => import('pages/static-points/static-points.container'));
const ScoutingPlan = React.lazy(() => import('pages/scouting-plan/scouting-plan.container'));
const ScoutingPlanVisualization = React.lazy(() => import('pages/scouting-plan-visualization/scouting-plan-visualization.container'));
const Tasks = React.lazy(() => import('pages/tasks/tasks.container'));
const TasksNewScouting = React.lazy(() => import('pages/tasks/scouting/scouting-create'));
const TasksNewSampling = React.lazy(() => import('pages/tasks/sampling/sampling-create'));
const TasksNewPrescription = React.lazy(() => import('pages/tasks/task-create/task-prescription.container'));
const SmartSpray = React.lazy(() => import('pages/smart-spray/smart-spray.container'));
const Integrations = React.lazy(() => import('pages/integrations/integrations.container'));
const NewSmartSprayNewPlan = React.lazy(() => import('pages/smart-spray/plan/plan.container'));
const CottonGrowth = React.lazy(() => import('pages/cotton/cotton.container'));
const DiseaseRisk = React.lazy(() => import('pages/disease-risk/disease-risk.container'));
const DiseaseRiskConfiguration = React.lazy(
  () => import('pages/disease-risk/disease-risk-configuration/disease-risk-configuration.container')
);
const DiseaseRiskCropCalendar = React.lazy(() => import('pages/disease-risk/disease-risk-crop-calendar/disease-risk-crop-calendar'));
const ScoutingScoreHallContainer = React.lazy(() => import('pages/scouting-score/scouting-score-hall.container'));
const ScoutingScoreCreateFormContainer = React.lazy(() => import('pages/scouting-score/scouting-score-create-form.container'));
const SugarCane = React.lazy(() => import('pages/sugarcane/sugarcane.container'));
const SprayRegistration = React.lazy(() => import('pages/tasks/task-create/spray-registration/spray-registration.container'));
const EditMethodology = React.lazy(() => import('pages/edit-methodology/pages/edit-methodology.container'));
const EditMethodologyList = React.lazy(() => import('pages/edit-methodology/pages/edit-methodology-list.container'));
const PowerBIReports = React.lazy(() => import('pages/powerbi-reports/powerbi-reports-container'));
const IntegrationsPerfectFlightLinkingFarmComponent = React.lazy(
  () => import('pages/integrations/perfect-flight/integrations-perfect-flight-linking-farm-component')
);
const IntegrationsPerfectFlightLinkingArea = React.lazy(
  () => import('pages/integrations/perfect-flight/integrations-perfect-flight-linking-area.component')
);
const IntegrationsPerfectFlightContainer = React.lazy(
  () => import('pages/integrations/perfect-flight/integrations-perfect-flight-container')
);
const IntegrationsPerfectFlightInstall = React.lazy(() => import('pages/integrations/perfect-flight/integrations-perfect-flight-install'));
const IntegrationsJDOCLinkingProperty = React.lazy(() => import('pages/integrations/jdoc/integrations-jdoc-linking-property.component'));
const CreateControlStategyContainer = React.lazy(
  () => import('pages/control-strategy/create-control-strategy/create-control-strategy.container')
);
const IntegrationsJDOCLinkingArea = React.lazy(() => import('pages/integrations/jdoc/integration-jdoc-linking-area.component'));
const IntegrationsJdocContainer = React.lazy(() => import('pages/integrations/jdoc/integrations-jdoc-container'));
const IntegrationsJDOCInstall = React.lazy(() => import('pages/integrations/jdoc/integrations-jdoc-install.component'));
const UnlicensedUserContainer = React.lazy(() => import('pages/login/login.unlicensed-user'));
const IntegrationsJDOCLogin = React.lazy(() => import('pages/integrations/jdoc/integrations-jdoc-login.component'));
const CompanyEmptyState = React.lazy(() => import('pages/hall-of-companies/hall-of-companies-empty-state'));
const IntegrationsTrapViewInstall = React.lazy(() => import('pages/integrations/trap-view/integrations-trapview-install'));
const IntegrationsSaa = React.lazy(() => import('pages/integrations/saa/integrations-saa'));
const RegionalOverviewContainer = React.lazy(() => import('pages/regional-overview/regional-overview-container'));
const RoutesConfiguration = React.lazy(() => import('pages/routes-configuration/routes-configuration.container'));
const PostHarvestMap = React.lazy(() => import('pages/post-harvest-map/post-harvest-map.container'));
const ScoutingPlanPage = React.lazy(() => import('pages/routes-configuration/scouting-plan-page/scouting-plan-page'));
const TaskFactory = React.lazy(() => import('pages/tasks/ag-operations/task-factory'));
const FieldBookContainer = React.lazy(() => import('pages/field-book/field-book.container'));
const FieldClusteringConfiguration = React.lazy(() => import('pages/field-clustering/configuration/field-clustering-configuration'));
const FieldClusteringMapResult = React.lazy(() => import('pages/field-clustering/map-result/field-clustering-map-result'));

const FieldClusteringMapVisualization = React.lazy(
  () => import('pages/field-clustering/map-visualization/field-clustering-map-visualization')
);
const SetupIntegration = React.lazy(() => import('pages/integrations/rea/SetupIntegration'));
const ViewSyncedData = React.lazy(() => import('pages/integrations/rea/ViewSyncedData'));
const IntegrationsRea = React.lazy(() => import('pages/integrations/rea/integrations-rea'));
const ProductDetailsPage = React.lazy(() => import('pages/product-details/product-details-page'));

const LocalRoutes = () => {
  const navigate = useNavigate();

  useEffect(() => {
    initMfeDynamicSlot(navigate);
  }, [navigate]);
  
  return (
    <Routes>
      <Route path='authenticate' element={<LoginOauth2 />} />
      <Route element={<ProtectedRoute />}>
        <Route path='/' element={<MainLayout />}>
          <Route path='/no-companies' element={<CompanyEmptyState />} />
          <Route path='/unlicensed' element={<UnlicensedUserContainer />} />
          <Route path='/product-details/:productId' element={<ProductDetailsPage />} />

          <Route path='/company/:companyId/smart-plan/*' element={<SmartPlan />} />
          <Route path='/company/:companyId/property/:propertyId/smart-plan/*' element={<SmartPlan routeLevel='property' />} />

          <Route path='/hall-of-companies' element={<HallOfCompanies />} />
          <Route path='/company/:companyId/overview' element={<CLevelReportContainer />} />
          <Route path='/company/:companyId' element={<HallOfProperties />} />
          <Route path='/company/:companyId/presentation' element={<CompanyPresentation />} />
          <Route path='/company/:companyId/edit-methodology' element={<EditMethodologyList />} />
          <Route path='/company/:companyId/edit-methodology/:methodologyId' element={<EditMethodology />} />
          <Route element={<RouteByPermissionFlag permissionFlag='hideWarehouse' reverseCondition />}>
            <Route path='/company/:companyId/warehouses' element={<CompanyWarehouse />} />
          </Route>
          <Route path='/company/:companyId/regional-overview' element={<RegionalOverviewContainer />} />
          <Route path='/company/:companyId/reports' element={<PowerBIReports />} />
          <Route element={<RouteByPermissionFlag permissionFlag='hidePropertyDashboard' reverseCondition />}>
            <Route path='/company/:companyId/property/:propertyId' element={<PropertyDashboard />} />
          </Route>
          <Route element={<RouteByPermissionFlag permissionFlag='hideWarehouse' reverseCondition />}>
            <Route path='/company/:companyId/property/:propertyId/warehouses' element={<CompanyWarehouse />} />
          </Route>
          <Route element={<RouteByPermissionFlag permissionFlag='hideFinancial' reverseCondition />}>
            <Route path='/company/:companyId/property/:propertyId/financial' element={<Financial />} />
          </Route>
          <Route path='/company/:companyId/property/:propertyId/area-info' element={<AreaInfo />} />
          <Route path='/company/:companyId/property/:propertyId/timeline' element={<Timeline />} />

          <Route path='/company/:companyId/property/:propertyId/disease-risk' element={<DiseaseRisk />} />
          <Route path='/company/:companyId/property/:propertyId/disease-risk/configuration' element={<DiseaseRiskConfiguration />} />
          <Route path='/company/:companyId/property/:propertyId/disease-risk/crop-calendar' element={<DiseaseRiskCropCalendar />} />

          <Route path='/company/:companyId/property/:propertyId/fixed-points' element={<FixedPoints />} />
          <Route element={<RouteByPermissionFlag permissionFlag='hideTrapsManagement' reverseCondition />}>
            <Route path='/company/:companyId/property/:propertyId/static-points' element={<StaticPoints />} />
          </Route>
          <Route path='/company/:companyId/property/:propertyId/static-points/scouting-plan' element={<ScoutingPlan />} />
          <Route path='/company/:companyId/property/:propertyId/static-points/scouting-plan/map' element={<ScoutingPlanVisualization />} />
          <Route path='/company/:companyId/property/:propertyId/tasks' element={<Tasks />} />
          <Route path='/company/:companyId/property/:propertyId/tasks/new-scouting' element={<TasksNewScouting />} />
          <Route path='/company/:companyId/property/:propertyId/tasks/new-sampling' element={<TasksNewSampling />} />
          <Route path='/company/:companyId/property/:propertyId/tasks/new-prescription' element={<TasksNewPrescription />} />
          <Route path='/company/:companyId/property/:propertyId/tasks/spray-registration' element={<SprayRegistration />} />
          <Route path='/company/:companyId/property/:propertyId/tasks/ag-operations/:taskType' element={<TaskFactory />} />
          <Route element={<RouteByPermissionFlag permissionFlag='hideSmartSpray' reverseCondition />}>
            <Route path='/company/:companyId/property/:propertyId/smart-spray' element={<SmartSpray />} />
          </Route>
          <Route element={<RouteByPermissionFlag permissionFlag='hideSmartSpray' reverseCondition />}>
            <Route path='/company/:companyId/property/:propertyId/smart-spray/new-plan' element={<NewSmartSprayNewPlan />} />
          </Route>
          <Route path='/company/:companyId/property/:propertyId/cotton' element={<CottonGrowth />} />
          <Route element={<RouteByPermissionFlag permissionFlag='hideIntegrations' reverseCondition />}>
            <Route path='/company/:companyId/property/:propertyId/integrations' element={<Integrations />} />
          </Route>
          <Route element={<RouteByPermissionFlag permissionFlag='hideIntegrations' reverseCondition />}>
            <Route
              path='/company/:companyId/property/:propertyId/integrations/perfect-flight'
              element={<IntegrationsPerfectFlightContainer />}>
              <Route path='install' element={<IntegrationsPerfectFlightInstall />} />
              <Route path='linking-property' element={<IntegrationsPerfectFlightLinkingFarmComponent />} />
              <Route path='linking-areas' element={<IntegrationsPerfectFlightLinkingArea />} />
            </Route>
          </Route>
          <Route element={<RouteByPermissionFlag permissionFlag='hideIntegrations' reverseCondition />}>
            <Route path='/company/:companyId/property/:propertyId/integrations/jdoc' element={<IntegrationsJdocContainer />}>
              <Route path='install' element={<IntegrationsJDOCInstall />} />
              <Route path='login' element={<IntegrationsJDOCLogin />} />
              <Route path='linking-property' element={<IntegrationsJDOCLinkingProperty />} />
              <Route path='linking-areas/org/:orgId/farm/:farmId/' element={<IntegrationsJDOCLinkingArea />} />
            </Route>
          </Route>
          <Route path='/company/:companyId/property/:propertyId/integrations/trap-view' element={<IntegrationsTrapViewInstall />} />
          <Route path='/company/:companyId/property/:propertyId/integrations/saa' element={<IntegrationsSaa />} />
          <Route path='/company/:companyId/property/:propertyId/integrations/rea' element={<IntegrationsRea />} />
          <Route path='/company/:companyId/property/:propertyId/integrations/rea/view-synced-data' element={<ViewSyncedData />} />
          <Route path='/company/:companyId/property/:propertyId/integrations/rea/rea-integration-setup' element={<SetupIntegration />} />

          <Route path='/company/:companyId/property/:propertyId/borer-risk' element={<SugarCane />} />

          <Route path='/company/:companyId/property/:propertyId/borer-risk/new-strategy' element={<CreateControlStategyContainer />} />
          <Route path='/company/:companyId/property/:propertyId/reports' element={<PowerBIReports />} />
          <Route path='/company/:companyId/property/:propertyId/scouting-score' element={<ScoutingScoreHallContainer />} />
          <Route path='/company/:companyId/property/:propertyId/scouting-score/configuration' element={<ScoutingScoreCreateFormContainer />}>
            <Route path=':configurationId' element={<ScoutingScoreCreateFormContainer />} />
          </Route>

          <Route path='/company/:companyId/property/:propertyId/integration/:typeIntegration'>
            <Route path='disease-risk' element={<DiseaseRisk />} />
          </Route>

          <Route
            path='/company/:companyId/property/:propertyId/integration/:typeIntegration/disease-risk/configuration'
            element={<DiseaseRiskConfiguration />}
          />

          <Route path='/company/:companyId/field-book' element={<FieldBookContainer />} />

          <Route path='/company/:companyId/property/:propertyId/tasks/ScoutingPlanPage' element={<ScoutingPlanPage />} />
          <Route path='/company/:companyId/property/:propertyId/tasks/routesConfiguration' element={<RoutesConfiguration />} />
          <Route path='/company/:companyId/property/:propertyId/tasks/postHarvest/map' element={<PostHarvestMap />} />

          <Route path='/company/:companyId/property/:propertyId/notes' element={<Notes />} />

          <Route path='/company/:companyId/property/:propertyId/nemadigital' element={<NemaDigital />} />

          <Route path='/company/:companyId/opportunity-machine' element={<OpportunityMachineContainer />} />
          <Route path='/company/:companyId/opportunity-machine/setup' element={<OpportunityMachineSetupContainer />} />

          <Route path='/company/:companyId/property/:propertyId/field-clustering/configuration' element={<FieldClusteringConfiguration />} />
          <Route path='/company/:companyId/property/:propertyId/field-clustering/map/' element={<FieldClusteringMapResult />} />
          <Route path='/company/:companyId/property/:propertyId/field-clustering' element={<FieldClusteringMapVisualization />} />
          <Route
            path={CROPWISE_MFES_PATH}
            element={<MfeDynamicSlot getConfigInstance={useConfig as () => CWElementsConfig} onCancel={() => navigate(-1)} />}
          />
        </Route>
      </Route>
    </Routes>
  )
};

export default LocalRoutes;
