import { notification } from 'antd';
import { Button, Flex, Spin, Typography } from 'antdV5';
import { usePostRecommendationNotes } from 'querys/disease-risk/disease-risk.recommendation-notes.query';
import { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import sentryService from 'sentry/service';
import ReportDiseaseRiskNotesTabs from './report-disease-risk-notes-tabs';
import ReportDiseaseRiskNotesTitle from './report-disease-risk-notes-title';
import useReportDiseaseRiskNotes from './report-disease-risk-notes.hook';
import { StyledDrawer } from './report-disease-risk-select-options.style';

interface ReportDiseaseRiskNotesProps {
  open: boolean;
  onClose: () => void;
  closeMainDrawer: () => void;
}

const ReportDiseaseRiskNotes = ({ open, onClose, closeMainDrawer }: ReportDiseaseRiskNotesProps) => {
  const [t] = useTranslation();
  const { propertyId } = useParams<{ propertyId: string }>();
  const { hasModifiedNotes, getModifiedNotes, clearModifiedStatus, handleOnChange, notesRef } = useReportDiseaseRiskNotes();
  const { mutateAsync: saveRecommendationNotesAsync } = usePostRecommendationNotes();
  const isLoadingRef = useRef(false);

  const handleCloseDrawerOnSuccess = useCallback(() => {
    clearModifiedStatus();
    closeMainDrawer();
    onClose();
  }, [clearModifiedStatus, onClose, closeMainDrawer]);

  const handleSaveRecommendationNotes = useCallback(async () => {
    if (!hasModifiedNotes() || !propertyId) return;

    try {
      isLoadingRef.current = true;
      const modifiedNotes = getModifiedNotes();
      await Promise.all(modifiedNotes.map(note => saveRecommendationNotesAsync({ propertyId, note })));

      notification.success({
        key: 'save-recommendation-notes',
        message: t('report.disease_risk.options.report_notes_creation.request_success.message'),
        description: t('report.disease_risk.options.report_notes_creation.request_success.description')
      });
      handleCloseDrawerOnSuccess();
    } catch (error) {
      sentryService.captureException(error as Error, {
        fileName: 'report-disease-risk-notes.tsx',
        method: 'handleSaveRecommendationNotes',
        description: 'failed to save notes'
      });
    } finally {
      isLoadingRef.current = false;
    }
  }, [hasModifiedNotes, propertyId, getModifiedNotes, saveRecommendationNotesAsync, handleCloseDrawerOnSuccess, t]);

  return (
    <StyledDrawer
      destroyOnClose
      title={<ReportDiseaseRiskNotesTitle />}
      open={open}
      onClose={onClose}
      width={480}
      maskClosable
      footer={
        <Flex justify='end' gap={8}>
          <Button type='text' onClick={onClose}>
            {t('general.cancel')}
          </Button>
          <Button
            type='primary'
            onClick={() => {
              void handleSaveRecommendationNotes();
            }}>
            {isLoadingRef.current ? <Spin /> : t('report.disease_risk.options.report_notes_creation.save_all_notes')}
          </Button>
        </Flex>
      }>
      <Flex vertical>
        <Typography.Paragraph>{t('report.disease_risk.options.report_notes_creation.description')}</Typography.Paragraph>
        <ReportDiseaseRiskNotesTabs handleOnChange={handleOnChange} notesRef={notesRef} />
      </Flex>
    </StyledDrawer>
  );
};

export default ReportDiseaseRiskNotes;
