import { Flex, Typography } from 'antdV5';
import { COLOR_OPTIONS } from 'config/style';
import { useTranslation } from 'react-i18next';
import PaperPencilIcon from 'st-protector-icons/Glyph/PaperPencilIcon';
import { RoundedIconWrapper } from './report-disease-risk-notes-title.style';

const ReportDiseaseRiskNotesTitle = () => {
  const [t] = useTranslation();

  return (
    <Flex align='center' gap='10px'>
      <RoundedIconWrapper>
        <PaperPencilIcon fontSize='24px' fill={COLOR_OPTIONS.ORANGE_50} />
      </RoundedIconWrapper>
      <Typography.Title level={5}>{t('report.disease_risk.options.report_notes_creation.card_title')}</Typography.Title>
    </Flex>
  );
};

export default ReportDiseaseRiskNotesTitle;
