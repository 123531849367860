import { notification } from 'antd';
import { getRegionEntitiesInEntities } from 'core/core.selectors';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

export const useShowAreasWarning = () => {
  const [t] = useTranslation();
  const regions = useSelector(getRegionEntitiesInEntities);

  const showAreasWarning = (areasWithProblems: string[], areasWithoutPoints: string[]) => {
    if (areasWithProblems?.length) {
      const filteredAreas = areasWithProblems.filter(areaWithProblem => regions?.[areaWithProblem]?.name);
      notification.warning({
        key: JSON.stringify(areasWithProblems),
        message: t('pages.tasks.task_create.suggest_points.errors.areas_with_problems'),
        description: (
          <ul>
            {filteredAreas.map(areaId => (
              <li key={areaId}>{regions[areaId].name}</li>
            ))}
          </ul>
        )
      });
    }

    if (areasWithoutPoints?.length) {
      const filteredAreas = areasWithoutPoints.filter(areaWithProblem => regions?.[areaWithProblem]?.name);
      notification.warning({
        key: JSON.stringify(areasWithoutPoints),
        message: t('pages.tasks.task_create.suggest_points.errors.areas_without_points'),
        description: (
          <ul>
            {filteredAreas.map(areaId => (
              <li key={areaId}>{regions[areaId].name}</li>
            ))}
          </ul>
        )
      });
    }
  };

  return {
    showAreasWarning
  };
};
