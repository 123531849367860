import { Menu, Tooltip, notification } from 'antd';
import type { Dictionary } from 'config/types';
import type { PermissionFlags } from 'core/services/auth/auth.models';
import type { Crop } from 'core/utils/basic.models';
import type { AnalyticsInfo } from 'core/utils/segment/user-events.types';
import type { LicensingStatusResponse } from 'entities/company/company.models';
import type { Property } from 'entities/property/property.models';
import { getSelectedProperty } from 'entities/property/property.reducer';
import { getSelectedSeasonCrops } from 'entities/season/season.reducer';
import { useGetRBACAuthorityUser } from 'querys/rbac/rbac.query';
import type React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useSelector } from 'react-redux';
import type { To } from 'react-router-dom';
import { NavLink as BugNavLink, useMatch } from 'react-router-dom';
import type { AppState } from 'redux/app-state';
import SvgCotton from 'st-protector-icons/Minor/Cotton';
import SvgDiseaseRisk from 'st-protector-icons/Minor/DiseaseRisk';
import SvgFinances from 'st-protector-icons/Minor/Finance';
import SvgFixedPointMenu from 'st-protector-icons/Minor/FixedPointMenu';
import SvgGridOutline from 'st-protector-icons/Minor/GridOutline';
import SvgIntegrations from 'st-protector-icons/Minor/Integrations';
import SvgReport from 'st-protector-icons/Minor/Report';
import SvgSmartSpray from 'st-protector-icons/Minor/SmartSpray';
import SvgSprayOutline from 'st-protector-icons/Minor/SprayOutline';
import SvgStaticPointMenu from 'st-protector-icons/Minor/StaticPointMenu';
import SvgClockTask from 'st-protector-icons/Minor/SvgClockTask';
import SvgTask from 'st-protector-icons/Minor/Task';
import SvgTimeline from 'st-protector-icons/Minor/Timeline';
import SvgWarehouse from 'st-protector-icons/Minor/Warehouse';
import { CropTypesEnum } from '../../core.models';

import OpenInBalance from 'components/open-in-balance/open-in-balance.component';
import { BALANCE_URL } from 'config/constants';
import { COLOR_OPTIONS } from 'config/style';
import { hasHideMenuOptionWithoutSeason, hasRTRPlanEntitlement, selectHasDiseaseRiskLandingPage } from 'core/core.selectors';
import { selectCurrentUser } from 'core/services/auth/auth.reducer';
import { Entitlements } from 'core/shared/enums/entitlements.enum';
import STTypo from 'core/shared/typo';
import {
  userHasNoAccessTasksPrescriptionInRegionalRisk,
  validatePlansAndEntitlements,
  verifyBalanceAppAccess,
  verifyUserAccessInRegionalOverview
} from 'core/utils/functions';
import useSegmentTracking from 'core/utils/segment/useSegmentTracking';
import { useDashboardWidgetsStatusStore } from 'pages/dashboard-farm/dashboard-farm-widgets.zustand';
import { RBACActions, RBACLevels, RBACPermissionTypesEnum } from 'querys/rbac/rbac.query.model';
import SvgNotesGlyph from 'st-protector-icons/Glyph/NotesGlyph';
import SvgFieldBook from 'st-protector-icons/Minor/FieldBook';
import SvgOpportunityMachineIcon from 'st-protector-icons/Minor/OpportunityMachineIcon';
import PowerBIReports from 'st-protector-icons/Minor/PowerBIReports';
import SvgRegionalRisk from 'st-protector-icons/Minor/RegionalRisk';
import SvgBorerRiskIcon from '../../../st-protector-icons/Minor/BorerRiskIcon';
import MenuIcon from './menu-icon.component';
import { handleDiseaseRiskActivation, willShowDiseaseRiskIcon } from './menu.functions';
import './menu.styles.less';

const NavLink = BugNavLink;

export interface ISTMenu {
  selectedCrops: Crop[];
  property?: Property | null;
  companyId: string | null;
  licensingStatus: LicensingStatusResponse | null;
  permissionFlags: PermissionFlags;
  isLoadingLicensingStatus: boolean;
  productsError?: string | null;
}

export const STMenu: React.FC<ISTMenu> = ({
  selectedCrops,
  property,
  companyId,
  licensingStatus,
  permissionFlags,
  isLoadingLicensingStatus,
  productsError
}) => {
  const [t] = useTranslation();
  const { resetWidgetsStatus } = useDashboardWidgetsStatusStore();
  const matchCompany = useMatch({ path: '/company/:companyId', end: false });
  const matchProperty = useMatch({ path: '/company/:companyId/property/:propertyId', end: false });
  const [diseaseRisk, setDiseaseRisk] = useState<boolean>(false);
  const [borerRisk, setBorerRisk] = useState<boolean>(false);
  const [hasFieldBook, setHasFieldBook] = useState<boolean>(false);
  const [hasSmartPlan, setHasSmartPlan] = useState<boolean>(false);
  const [hasBalanceApp, setHasBalanceApp] = useState<boolean>(false);
  const [opportunityMachine, setOpportunityMachine] = useState<boolean>(false);

  const systemFlags = useSelector<AppState, Dictionary<boolean | string> | null>(state => state.uiState.global.systemFlags);
  const hasDiseaseRiskLandingPage = useSelector(selectHasDiseaseRiskLandingPage);
  const user = useSelector(selectCurrentUser);
  const [hideTasks, setHideTasks] = useState<boolean>(true);
  const [hideFixedPoints, setHideFixedPoints] = useState<boolean>(false);

  const showOnlyNotesFeature = useSelector(hasRTRPlanEntitlement);
  const hideMenuOptionWithoutSeason = useSelector(hasHideMenuOptionWithoutSeason);

  const inventoryPermission = useGetRBACAuthorityUser({
    rbacActions: RBACActions.INVENTORY,
    rbacLevels: RBACLevels.ORG,
    rbacPermission: [RBACPermissionTypesEnum.READ, RBACPermissionTypesEnum.WRITE]
  });

  const financialPermission = useGetRBACAuthorityUser({
    rbacActions: RBACActions.FINANCIAL,
    rbacLevels: RBACLevels.ORG,
    rbacPermission: [RBACPermissionTypesEnum.READ, RBACPermissionTypesEnum.WRITE]
  });

  const sugarcanePermission = useGetRBACAuthorityUser({
    rbacActions: RBACActions.SUGARCANE,
    rbacLevels: RBACLevels.ORG,
    rbacPermission: [RBACPermissionTypesEnum.READ, RBACPermissionTypesEnum.WRITE]
  });

  const regionalRiskPermission = useGetRBACAuthorityUser({
    rbacActions: RBACActions.REGIONAL_RISK,
    rbacLevels: RBACLevels.ORG,
    rbacPermission: [RBACPermissionTypesEnum.NOT_READ]
  });

  const timelinePermission = useGetRBACAuthorityUser({
    rbacActions: RBACActions.TIMELINE,
    rbacLevels: RBACLevels.ORG,
    rbacPermission: [RBACPermissionTypesEnum.NOT_READ]
  });

  const {
    simpleCompanyDashboard,
    hidePropertyDashboard,
    hideWarehouse,
    hideFinancial,
    hideSmartSpray,
    hideIntegrations,
    hideTrapsManagement
  } = permissionFlags;

  useEffect(() => {
    const hasOpportunityMachineEntitlement = validatePlansAndEntitlements(licensingStatus, null, [Entitlements.OPPORTUNITY_MACHINE]);

    const shouldEnableOpportunityMachine = !!sugarcanePermission?.length && hasOpportunityMachineEntitlement;

    setOpportunityMachine(shouldEnableOpportunityMachine);
  }, [licensingStatus, sugarcanePermission]);

  useEffect(() => {
    if (productsError) {
      notification.error({ message: t('general.spray_error_message') });
    }
  }, [productsError]);

  useEffect(() => {
    const fieldbook = validatePlansAndEntitlements(licensingStatus, null, [Entitlements.FIELD_BOOK_SPAIN, Entitlements.FIELD_BOOK_HUNGARY]);
    const smartPlan = validatePlansAndEntitlements(licensingStatus, null, [Entitlements.SMART_PLAN]);
    const balanceApp = verifyBalanceAppAccess(licensingStatus);
    setHasFieldBook(fieldbook);
    setHasSmartPlan(smartPlan);
    setHasBalanceApp(balanceApp && !!systemFlags?.P40_44342_interoperability_balance_protector);
  }, [licensingStatus, systemFlags]);

  useEffect(() => {
    handleDiseaseRiskActivation(property, selectedCrops, companyId, licensingStatus, setDiseaseRisk);
  }, [property, selectedCrops, companyId, licensingStatus]);

  useEffect(() => {
    if (!property || !companyId || !licensingStatus || !selectedCrops?.length) return;
    setBorerRisk(false);
    if (!licensingStatus?.apps) return;
    const hasBorerRisk = validatePlansAndEntitlements(licensingStatus, null, [Entitlements.BORER_RISK]);
    setBorerRisk(hasBorerRisk && !!selectedCrops.find(c => c.wk_slug.toLowerCase() === CropTypesEnum.SUGARCANE.toLowerCase()));
    setHideTasks(userHasNoAccessTasksPrescriptionInRegionalRisk(licensingStatus));

    setHideFixedPoints(validatePlansAndEntitlements(licensingStatus, null, [Entitlements.HIDE_FIXED_POINTS]));
  }, [property, companyId, licensingStatus, selectedCrops, systemFlags]);

  const validateNotes = useMemo(() => {
    if (!licensingStatus) return undefined;

    const notesPage = validatePlansAndEntitlements(licensingStatus, null, [Entitlements.NOTES_PAGE]);
    return notesPage && systemFlags?.P40_24023_notes_module;
  }, [systemFlags, licensingStatus]);

  const segmentTracking = useSegmentTracking();

  const trackSegment = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, action: string) => {
    event.stopPropagation();

    const payload: AnalyticsInfo = {
      userId: user?.id ?? '',
      baseRole: user?.role ?? '',
      protectorRole: user?.job_title ?? '',
      from: 'Sidebar Menu'
    };

    segmentTracking.track(action, payload);
  };

  const renderPowerBI = (link: To, segment: string) => (
    <>
      {(systemFlags?.P40_24951_render_power_bi as string)?.includes(companyId ?? '') && (
        <NavLink
          end
          data-testid='menu-btn-powerbi-reports'
          className={({ isActive }) => `ant-menu-item ${isActive ? 'ant-menu-item-selected' : ''}`}
          onClick={e => trackSegment(e, segment)}
          to={link}>
          <Tooltip title={t('pages.side_menu.powerbi_reports')} placement='right'>
            <div>
              <div className='st-menu-active' />
              <PowerBIReports fill='#707374' />
              <STTypo>{t('pages.side_menu.powerbi_reports')}</STTypo>
            </div>
          </Tooltip>
        </NavLink>
      )}
    </>
  );

  const CLevelReportButton = () => {
    const cLevelReportPermitted =
      (systemFlags?.P40_27952_c_level_report_by_userID as string)?.includes(user?.id ?? '') ||
      !!systemFlags?.P40_27952_c_level_report_by_companyID ||
      !!systemFlags?.P40_27952_c_level_report;
    const cLevelPermittedByEntitlement = validatePlansAndEntitlements(licensingStatus, null, [Entitlements.MANAGEMENT_REPORT]);
    return (
      (cLevelPermittedByEntitlement || cLevelReportPermitted) && (
        <NavLink
          target='_blank'
          data-testid='menu-btn-c-level-report'
          className={({ isActive }) => `ant-menu-item ${isActive ? 'ant-menu-item-selected' : ''}`}
          onClick={e => trackSegment(e, `C Level Report`)}
          to={`/company/${matchCompany!.params.companyId}/overview`}>
          <Tooltip title={t('pages.side_menu.c_level')} placement='right'>
            <div>
              <div className='st-menu-active' />
              <SvgReport fill={COLOR_OPTIONS.SECONDARY} />
              <STTypo>{t('pages.side_menu.c_level')}</STTypo>
            </div>
          </Tooltip>
        </NavLink>
      )
    );
  };

  const companyDashboardPage = (
    <>
      {!simpleCompanyDashboard?.value && !validatePlansAndEntitlements(licensingStatus, null, [Entitlements.HIDE_PROPERTY_HALL]) && (
        <NavLink
          end
          data-testid='menu-btn-company-dashboard'
          className={({ isActive }) => `ant-menu-item ${isActive ? 'ant-menu-item-selected' : ''}`}
          to={`/company/${matchCompany!.params.companyId}`}>
          <Tooltip title={t('pages.side_menu.hall_of_company')} placement='right'>
            <div>
              <div className='st-menu-active' />
              <SvgGridOutline />
              <STTypo>{t('pages.side_menu.hall_of_company')}</STTypo>
            </div>
          </Tooltip>
        </NavLink>
      )}
    </>
  );

  const handleCompany = (
    <>
      {companyDashboardPage}
      {hasSmartPlan && (
        <NavLink
          end
          data-testid='menu-btn-company-smartplan'
          className={({ isActive }) => `ant-menu-item ${isActive ? 'ant-menu-item-selected' : ''}`}
          to={`/company/${matchCompany!.params.companyId}/smart-plan`}>
          <Tooltip title={t('pages.smartplan.title')} placement='right'>
            <div>
              <div className='st-menu-active' />
              <SvgClockTask />
              <STTypo>{t('pages.smartplan.title')}</STTypo>
            </div>
          </Tooltip>
        </NavLink>
      )}
      {!!inventoryPermission?.length && !hideWarehouse.value && (
        <NavLink
          end
          data-testid='menu-btn-company-warehouse'
          className={({ isActive }) => `ant-menu-item ${isActive ? 'ant-menu-item-selected' : ''}`}
          target={hasBalanceApp ? '_blank' : ''}
          to={
            hasBalanceApp
              ? `${BALANCE_URL}stock/summary?organizationId=${matchCompany!.params.companyId}`
              : `/company/${matchCompany!.params.companyId}/warehouses`
          }>
          <Tooltip
            title={
              <div>
                <STTypo>{t('pages.warehouse.title')}</STTypo>
                {hasBalanceApp && <OpenInBalance />}
              </div>
            }
            placement='right'>
            <div>
              <div className='st-menu-active' />
              <SvgWarehouse />
              <STTypo>{t('pages.warehouse.title')}</STTypo>
            </div>
          </Tooltip>
        </NavLink>
      )}
      {/* remove this component case remove feature flag */}
      {verifyUserAccessInRegionalOverview(licensingStatus) && !regionalRiskPermission?.length && (
        <NavLink
          end
          data-testid='menu-btn-company-regional-risk'
          className={({ isActive }) => `ant-menu-item ${isActive ? 'ant-menu-item-selected' : ''}`}
          to={`/company/${matchCompany!.params.companyId}/regional-overview`}>
          <Tooltip title={t('pages.regional_overview.regional_risk.title')} placement='right'>
            <div>
              <div className='st-menu-active' />
              <SvgRegionalRisk />
              <STTypo>{t('pages.regional_overview.regional_risk.title')}</STTypo>
            </div>
          </Tooltip>
        </NavLink>
      )}
      {hasFieldBook && (
        <NavLink
          end
          data-testid='menu-btn-company-field-book'
          className={({ isActive }) => `ant-menu-item ${isActive ? 'ant-menu-item-selected' : ''}`}
          to={`/company/${matchCompany!.params.companyId}/field-book`}>
          <Tooltip title={t('pages.field_book.menu_title')} placement='right'>
            <div>
              <div className='st-menu-active' />
              <SvgFieldBook />
              <STTypo>{t('pages.field_book.menu_title')}</STTypo>
            </div>
          </Tooltip>
        </NavLink>
      )}
      {opportunityMachine && (
        <NavLink
          end
          data-testid='menu-btn-opportunities-dashboard'
          className={({ isActive }) => `ant-menu-item ${isActive ? 'ant-menu-item-selected' : ''}`}
          to={`/company/${matchCompany!.params.companyId}/opportunity-machine`}>
          <Tooltip title={t('pages.opportunity_machine.title')} placement='right'>
            <div>
              <div className='st-menu-active' />
              <SvgOpportunityMachineIcon fill='#707374' />
              <STTypo>{t('pages.opportunity_machine.title')}</STTypo>
            </div>
          </Tooltip>
        </NavLink>
      )}
      <CLevelReportButton />
      {renderPowerBI(`/company/${matchCompany!.params.companyId}/reports`, 'User accesses customized report by hall of properties')}
    </>
  );

  const getDiseaseRiskIcon = useCallback(() => {
    return (
      willShowDiseaseRiskIcon(diseaseRisk, property) && (
        <MenuIcon
          data-testid='menu-btn-disease-risk'
          segmentMessage='Open Disease Risk'
          title={t('pages.disease_risk.configuration.name')}
          name={t('pages.disease_risk.configuration.name')}
          icon={<SvgDiseaseRisk fill='#707374' />}
        />
      )
    );
  }, [diseaseRisk, property]);

  const notesPage = (
    <>
      {validateNotes && (
        <NavLink
          end
          data-testid='menu-btn-notes'
          className={({ isActive }) => `ant-menu-item ${isActive ? 'ant-menu-item-selected' : ''}`}
          onClick={e => trackSegment(e, `Notes - Click on side menu`)}
          to={`/company/${matchCompany!.params.companyId}/property/${matchProperty?.params.propertyId}/notes`}>
          <Tooltip title={t('pages.side_menu.notes')} placement='right'>
            <div>
              <div className='st-menu-active' />
              <SvgNotesGlyph height={24} width={24} />
              <STTypo>{t('pages.side_menu.notes')}</STTypo>
            </div>
          </Tooltip>
        </NavLink>
      )}
    </>
  );

  const handleProperty = (
    <>
      {!hidePropertyDashboard.value && (
        <NavLink
          end
          data-testid='menu-btn-property-dashboard'
          className={({ isActive }) => `ant-menu-item ${isActive ? 'ant-menu-item-selected' : ''}`}
          onClick={e => {
            resetWidgetsStatus();
            trackSegment(e, `Open Property Dashboard`);
          }}
          to={`/company/${matchCompany!.params.companyId}/property/${matchProperty?.params.propertyId}`}>
          <Tooltip title={t('pages.side_menu.hall_of_properties')} placement='right'>
            <div>
              <div className='st-menu-active' />
              <SvgGridOutline />
              <STTypo>{t('pages.side_menu.hall_of_properties')}</STTypo>
            </div>
          </Tooltip>
        </NavLink>
      )}
      {hasDiseaseRiskLandingPage && getDiseaseRiskIcon()}
      {notesPage}
      {!timelinePermission?.length && (
        <NavLink
          end
          data-testid='menu-btn-timeline'
          className={({ isActive }) => `ant-menu-item ${isActive ? 'ant-menu-item-selected' : ''}`}
          onClick={e => trackSegment(e, `Open Timeline`)}
          to={`/company/${matchCompany!.params.companyId}/property/${matchProperty?.params.propertyId}/timeline`}>
          <Tooltip title={t('pages.side_menu.timeline')} placement='right'>
            <div>
              <div className='st-menu-active' />
              <SvgTimeline />
              <STTypo>{t('pages.side_menu.timeline')}</STTypo>
            </div>
          </Tooltip>
        </NavLink>
      )}
      {hideTasks && (
        <NavLink
          end
          data-testid='menu-btn-tasks'
          className={({ isActive }) => `ant-menu-item ${isActive ? 'ant-menu-item-selected' : ''}`}
          onClick={e => trackSegment(e, `Open Tasks`)}
          to={`/company/${matchCompany!.params.companyId}/property/${matchProperty?.params.propertyId}/tasks`}>
          <Tooltip title={t('pages.side_menu.tasks')} placement='right'>
            <div>
              <div className='st-menu-active' />
              <SvgTask />
              <STTypo>{t('pages.side_menu.tasks')}</STTypo>
            </div>
          </Tooltip>
        </NavLink>
      )}
      {!hideFixedPoints && (
        <NavLink
          end
          data-testid='menu-btn-fixed-points'
          className={({ isActive }) => `ant-menu-item ${isActive ? 'ant-menu-item-selected' : ''}`}
          onClick={e => trackSegment(e, `Open Fixed Points`)}
          to={`/company/${matchCompany!.params.companyId}/property/${matchProperty?.params.propertyId}/fixed-points`}>
          <Tooltip title={t('pages.side_menu.fixed_points')} placement='right'>
            <div>
              <div className='st-menu-active' />
              <SvgFixedPointMenu />
              <STTypo>{t('pages.side_menu.fixed_points')}</STTypo>
            </div>
          </Tooltip>
        </NavLink>
      )}
      {!hideTrapsManagement.value && (
        <NavLink
          end
          data-testid='menu-btn-static-points'
          className={({ isActive }) => `ant-menu-item ${isActive ? 'ant-menu-item-selected' : ''}`}
          onClick={e => trackSegment(e, `Open Traps Management`)}
          to={`/company/${matchCompany!.params.companyId}/property/${matchProperty?.params.propertyId}/static-points`}>
          <Tooltip title={t('pages.side_menu.traps_management')} placement='right'>
            <div>
              <div className='st-menu-active' />
              <SvgStaticPointMenu />
              <STTypo>{t('pages.side_menu.traps_management')}</STTypo>
            </div>
          </Tooltip>
        </NavLink>
      )}
      <NavLink
        end
        data-testid='menu-btn-spray-registration'
        className={({ isActive }) => `ant-menu-item ${isActive ? 'ant-menu-item-selected' : ''}`}
        onClick={e => trackSegment(e, 'Open Spray Registration')}
        to={`/company/${matchCompany!.params.companyId}/property/${matchProperty?.params.propertyId}/tasks/spray-registration`}>
        <Tooltip title={t('pages.side_menu.spray_registration')} placement='right'>
          <div>
            <div className='st-menu-active' />
            <SvgSprayOutline />
            <STTypo>{t('pages.side_menu.spray_registration')}</STTypo>
          </div>
        </Tooltip>
      </NavLink>
      {hasSmartPlan && (
        <NavLink
          end
          data-testid='menu-btn-company-smartplan'
          className={({ isActive }) => `ant-menu-item ${isActive ? 'ant-menu-item-selected' : ''}`}
          to={`/company/${matchCompany!.params.companyId}/property/${matchProperty?.params.propertyId}/smart-plan`}>
          <Tooltip title={t('pages.smartplan.title')} placement='right'>
            <div>
              <div className='st-menu-active' />
              <SvgClockTask />
              <STTypo>{t('pages.smartplan.title')}</STTypo>
            </div>
          </Tooltip>
        </NavLink>
      )}
      {(hasBalanceApp || (!!inventoryPermission?.length && !hideWarehouse.value)) && (
        <NavLink
          end
          data-testid='menu-btn-property-warehouse'
          className={({ isActive }) => `ant-menu-item ${isActive ? 'ant-menu-item-selected' : ''}`}
          onClick={e => trackSegment(e, `Open Warehouse Property`)}
          target={hasBalanceApp ? '_blank' : ''}
          to={
            hasBalanceApp
              ? `${BALANCE_URL}stock/summary?organizationId=${matchCompany!.params.companyId}&farmId=${matchProperty?.params.propertyId}`
              : `/company/${matchCompany!.params.companyId}/property/${matchProperty?.params.propertyId}/warehouses`
          }>
          <Tooltip
            title={
              <div>
                <STTypo>{t('pages.warehouse.title')}</STTypo>
                {hasBalanceApp && <OpenInBalance />}
              </div>
            }
            placement='right'>
            <div>
              <div className='st-menu-active' />
              <SvgWarehouse />
              <STTypo>{t('pages.warehouse.title')}</STTypo>
            </div>
          </Tooltip>
        </NavLink>
      )}

      {(hasBalanceApp || (!!financialPermission?.length && !hideFinancial.value)) && (
        <NavLink
          end
          data-testid='menu-btn-finance'
          className={({ isActive }) => `ant-menu-item ${isActive ? 'ant-menu-item-selected' : ''}`}
          onClick={e => trackSegment(e, `Open Financial`)}
          target={hasBalanceApp ? '_blank' : ''}
          to={
            hasBalanceApp
              ? `${BALANCE_URL}financial?organizationId=${matchCompany!.params.companyId}&farmId=${matchProperty?.params.propertyId}`
              : `/company/${matchCompany!.params.companyId}/property/${matchProperty?.params.propertyId}/financial`
          }>
          <Tooltip
            title={
              <div>
                <STTypo>{t('pages.side_menu.finance')}</STTypo>
                {hasBalanceApp && <OpenInBalance />}
              </div>
            }
            placement='right'>
            <div>
              <div className='st-menu-active' />
              <SvgFinances />
              <STTypo>{t('pages.side_menu.finance')}</STTypo>
            </div>
          </Tooltip>
        </NavLink>
      )}
      {!hideSmartSpray.value && (
        <NavLink
          end
          data-testid='menu-btn-smart-spray'
          className={({ isActive }) => `ant-menu-item ${isActive ? 'ant-menu-item-selected' : ''}`}
          onClick={e => trackSegment(e, `Open Smart Spray`)}
          to={`/company/${matchCompany!.params.companyId}/property/${matchProperty?.params.propertyId}/smart-spray`}>
          <Tooltip title={t('pages.smart-spray.title')} placement='right'>
            <div>
              <div className='st-menu-active' />
              <SvgSmartSpray className='smart-spray-icon' />
              <STTypo>{t('pages.smart-spray.title')}</STTypo>
            </div>
          </Tooltip>
        </NavLink>
      )}
      {!!selectedCrops?.length && selectedCrops.find(c => c.wk_slug.toLowerCase() === CropTypesEnum.COTTON.toLowerCase()) && (
        <NavLink
          end
          data-testid='menu-btn-cotton-growth'
          className={({ isActive }) => `ant-menu-item ${isActive ? 'ant-menu-item-selected' : ''}`}
          onClick={e => trackSegment(e, `Open Cotton Growth`)}
          to={`/company/${matchCompany!.params.companyId}/property/${matchProperty?.params.propertyId}/cotton`}>
          <Tooltip title={t('pages.side_menu.cotton')} placement='right'>
            <div>
              <div className='st-menu-active' />
              <SvgCotton />
              <STTypo>{t('pages.side_menu.cotton')}</STTypo>
            </div>
          </Tooltip>
        </NavLink>
      )}
      {!hideIntegrations.value && !systemFlags?.P40_45546_HIDE_INTEGRATIONS_OPTION && (
        <NavLink
          data-testid='menu-btn-integrations'
          className={({ isActive }) => `ant-menu-item ${isActive ? 'ant-menu-item-selected' : ''}`}
          onClick={e => trackSegment(e, `Open Integrations`)}
          to={`/company/${matchCompany!.params.companyId}/property/${matchProperty?.params.propertyId}/integrations`}>
          <Tooltip title={t('pages.integrations.title')} placement='right'>
            <div>
              <div className='st-menu-active' />
              <SvgIntegrations className='integrations-icon' />
              <STTypo>{t('pages.integrations.title')}</STTypo>
            </div>
          </Tooltip>
        </NavLink>
      )}
      {!hasDiseaseRiskLandingPage && getDiseaseRiskIcon()}
      {!!sugarcanePermission?.length && borerRisk && !!property && (
        <NavLink
          data-testid='menu-btn-borer-risk'
          className={({ isActive }) => `ant-menu-item ${isActive ? 'ant-menu-item-selected' : ''}`}
          onClick={e => trackSegment(e, `Open Borer Risk`)}
          to={`/company/${matchCompany!.params.companyId}/property/${matchProperty?.params.propertyId}/borer-risk`}>
          <Tooltip title={t('pages.borer_risk.title')} placement='right'>
            <div>
              <div className='st-menu-active' />
              <SvgBorerRiskIcon fill='#707374' />
              <STTypo>{t('pages.borer_risk.title')}</STTypo>
            </div>
          </Tooltip>
        </NavLink>
      )}

      {renderPowerBI(
        `/company/${matchCompany!.params.companyId}/property/${matchProperty?.params.propertyId}/reports`,
        `User access customized reports by property menu`
      )}
    </>
  );

  if (isLoadingLicensingStatus) {
    return null;
  }

  const defineHandleCompany = () => {
    if (showOnlyNotesFeature && hideMenuOptionWithoutSeason) {
      return companyDashboardPage;
    }
    return handleCompany;
  };

  const defineHandleProperty = () => {
    if (showOnlyNotesFeature && hideMenuOptionWithoutSeason) {
      return notesPage;
    }
    return handleProperty;
  };

  return (
    <Menu className='st-menu-container' mode='inline'>
      {!matchProperty ? defineHandleCompany() : defineHandleProperty()}
    </Menu>
  );
};

const mapStateToProps = (state: AppState) => ({
  selectedCrops: getSelectedSeasonCrops(state),
  licensingStatus: state.entities.company.licensingStatus,
  companyId: state.uiState.global.selectedCompany,
  property: getSelectedProperty(state),
  permissionFlags: state.uiState.auth.permissionFlags,
  isLoadingLicensingStatus: state.entities.company.isLoadingLicensingStatus,
  productsError: state.entities.product.error
});

export default connect(mapStateToProps)(STMenu);
