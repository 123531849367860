import type { Dictionary } from '../../../../config/types.ts';

interface FeatureProperties {
  type: string;
  stripe: number;
}

interface Feature {
  id: string;
  type: string;
  properties: FeatureProperties;
  geometry: GeoJSON.Point;
}

interface FeatureCollection {
  type: string;
  features: Feature[];
}

export type SamplingPointsSuggestion = Record<string, FeatureCollection>;

export interface SuggestPointsRequest {
  field_ids: string[];
  hectares_per_point: number;
}

export enum CurrentState {
  INITIAL = 'INITIAL',
  INPUT = 'INPUT',
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR'
}

export const suggestionPointsErrorCode: Dictionary<string> = {
  EISE002: 'EISE002',
  ERND006: 'ERND006',
  ERND007: 'ERND007',
  EIRE001: 'EIRE001',
  DEFAULT: 'default'
};

export interface RequestSuggestionErrorType {
  response: {
    data: {
      error: string;
    };
  };
}
