import { Button } from 'antdV5';
import { COLOR_OPTIONS } from 'config/style';
import styled from 'styled-components';

export const StyledErrorContainer = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 32px;
`;

export const StyledButton = styled(Button)`
  &.ant-btn-primary:hover,
  .ant-btn-primary:focus {
    background-color: ${COLOR_OPTIONS.GREEN_60} !important;
    border-color: ${COLOR_OPTIONS.GREEN_60} !important;
  }
`;

export const StyledTextLink = styled.a`
  text-decoration: underline;
  cursor: pointer;
`;
