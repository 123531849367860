import axios from 'axios';
import { PROTECTOR_API_URL } from '../../../../../config/constants';
import type { PropertiesByOrg } from './useSeasonReport.models.ts';

const protectorApiUrl = PROTECTOR_API_URL;

export const getPropertyByOrg = async (companyId: string) => {
  const URLPath = `${protectorApiUrl}/v2/orgs/${companyId}/properties`;
  const response = await axios.get<PropertiesByOrg>(URLPath);
  return response.data?.content;
};
