import type { Entitlements } from 'core/shared/enums/entitlements.enum';
import { hasEntitlements } from 'core/utils/entitlement';
import { selectMostCurrentApp } from 'entities/company/company.selector';
import _ from 'lodash';

import { useSelector } from 'react-redux';

export const useHasAllEntitlements = (entitlements: Entitlements[]): boolean => {
  const protectorApp = useSelector(selectMostCurrentApp);

  return !!protectorApp && _.every(entitlements, entitlement => hasEntitlements(protectorApp, [entitlement]));
};
