import { notification } from 'antd';
import axios from 'axios';
import type { TFunction } from 'i18next';
import type { Dispatch, SetStateAction } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { create } from 'zustand';
import { NEMADIGITAL_URL } from '../../../../config/constants/index.ts';
import type { ISTMarkerProps } from '../../../../core/shared/map/map.models.ts';
import { useMutation } from '../../../../core/utils/data-store/tools.ts';
import { generateUUID } from '../../../../core/utils/functions.ts';
import { useShowAreasWarning } from './hooks/use-show-areas-warning.tsx';
import type { RequestSuggestionErrorType, SamplingPointsSuggestion, SuggestPointsRequest } from './suggest-points.models.ts';
import { CurrentState, suggestionPointsErrorCode } from './suggest-points.models.ts';

interface IStoreSuggestionPoints {
  executionId?: string;
  setExecutionId: (executionId?: string) => void;
  lastCropCyclesFinishedName: string;
  setLastCropCyclesFinishedName: (name: string) => void;
  currentStatus?: string;
  setCurrentStatus: (status: string) => void;
}

export const useSuggestionPointsStore = create<IStoreSuggestionPoints>(set => ({
  executionId: undefined,
  setExecutionId: executionId => set({ executionId }),
  lastCropCyclesFinishedName: '',
  setLastCropCyclesFinishedName: name => set({ lastCropCyclesFinishedName: name }),
  currentStatus: '',
  setCurrentStatus: status => set({ currentStatus: status })
}));

const nemaDigitalApiUrl = NEMADIGITAL_URL;

export const generateMarkers = (
  pointsDict: SamplingPointsSuggestion,
  warningCallback: (areasWithProblems: string[], areasWithoutPoints: string[]) => void
) => {
  const markersList: ISTMarkerProps[] = [];
  const areasWithProblems: string[] = [];
  const areasWithoutPoints: string[] = [];

  const latLngList = Object.values(pointsDict)
    .map((value, index) => {
      if ((value as unknown as { errorMessage: string })?.errorMessage) {
        areasWithProblems.push(Object.keys(pointsDict)[index]);
        return [];
      }

      if (!value?.features?.length) {
        areasWithoutPoints.push(Object.keys(pointsDict)[index]);
        return [];
      }

      return value.features.map(feature => {
        return feature.geometry.coordinates;
      });
    })
    .flat();

  if (areasWithProblems.length || areasWithoutPoints.length) {
    warningCallback(areasWithProblems, areasWithoutPoints);
  }

  latLngList.forEach((latLng, index) => {
    const marker: ISTMarkerProps = {
      id: generateUUID(),
      description: `${index + 1}`,
      parentAreaId: 'areaId',
      markerProps: {
        position: [latLng[1], latLng[0]],
        status: [],
        type: 'custom'
      }
    };
    markersList.push(marker);
  });

  return markersList;
};

const postNemaSuggestionPoints = async (
  body: SuggestPointsRequest,
  warningCallback: (areasWithProblems: string[], areasWithoutPoints: string[]) => void,
  executionId?: string
) => {
  if (!executionId) return [];
  const response = await axios.post<SamplingPointsSuggestion>(
    `${nemaDigitalApiUrl}/executions/${executionId}/suggestions/sampling-points`,
    body
  );

  return generateMarkers(response.data, warningCallback);
};

export const useGetSuggestionPoints = (
  suggestionPointsPayload: SuggestPointsRequest,
  setCurrentComponentState: Dispatch<SetStateAction<CurrentState>>,
  updateAllMarkers: (newMarkers: ISTMarkerProps[]) => void
) => {
  const { executionId } = useSuggestionPointsStore();
  const [t] = useTranslation();
  const { showAreasWarning } = useShowAreasWarning();

  const { mutate, data } = useMutation({
    mutationFn: () => postNemaSuggestionPoints(suggestionPointsPayload, showAreasWarning, executionId),
    onSuccess: () => {
      setCurrentComponentState(CurrentState.SUCCESS);
    },
    onError: (err: RequestSuggestionErrorType) => {
      handleSuggestionRequestError(setCurrentComponentState, err?.response?.data?.error, t);
    },
    retry: 3,
    retryDelay: 3500
  });

  useEffect(() => {
    if (data) {
      updateAllMarkers(data);
    }
  }, [data]);
  return { requestSuggestionPoints: mutate };
};

export const handleSuggestionRequestError = (
  setCurrentComponentState: Dispatch<SetStateAction<CurrentState>>,
  errorStatus: string,
  t: TFunction<'translation', undefined>
) => {
  setCurrentComponentState(CurrentState.ERROR);

  const errorMessage = suggestionPointsErrorCode[errorStatus] ?? suggestionPointsErrorCode.DEFAULT;
  const message = t(`pages.tasks.task_create.suggest_points.errors.${errorMessage}`);

  notification.error({ message: `${errorMessage}: ${message}` });
};
