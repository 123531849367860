import type { GlobalUiState } from 'core/core.models';
import type { PermissionFlags } from 'core/services/auth/auth.models';
import type { Dispatch, SetStateAction } from 'react';
import { SvgBlocked } from 'st-protector-icons/Minor/Blocked';
import SvgDiseaseRisk from 'st-protector-icons/Minor/DiseaseRisk';
import SvgMapExport from 'st-protector-icons/Minor/ExportMap';
import FieldMonitoring from 'st-protector-icons/Minor/FieldMonitoring';
import SvgNemaSamplingPoints from 'st-protector-icons/Minor/NemaSamplingPoints';
import { RainReportSvg } from 'st-protector-icons/Minor/RainReport';
import SvgReport from 'st-protector-icons/Minor/Report';
import SvgReportConsolidated from 'st-protector-icons/Minor/ReportConsolidated';
import SvgReportDetailed from 'st-protector-icons/Minor/ReportDetailed';
import SvgSample from 'st-protector-icons/Minor/Sample';
import SvgSpray from 'st-protector-icons/Minor/Spray';
import SvgStaticPointReport from 'st-protector-icons/Minor/StaticPointReport';
import SvgPinSample from 'st-protector-icons/Minor/SvgPinSample';
import SvgTableChart from 'st-protector-icons/Minor/TableChart';
import SvgWarehouseReport from 'st-protector-icons/Minor/WarehouseReport';
import { ReactComponent as SvgPinSamplePoints } from 'st-protector-icons/Minor/svg-pin-sample-points.svg';
import type { IReportTypes } from '../reports-types.enum';
import { ReportsTypes } from '../reports-types.enum';
import SVGSeason from '../../../../../../st-protector-icons/Minor/SvgSeason.tsx';

interface GetReportFunctionTypes {
  setReportToOpen: Dispatch<SetStateAction<ReportsTypes | null>>;
  systemFlags: GlobalUiState['systemFlags'];
  permissionFlags: PermissionFlags;
  showDiseaseRiskReport: boolean;
  t: (label: string) => string;
  propertyMatch: boolean;
  companyId: string;
}

export const getReportTypes = ({
  showDiseaseRiskReport,
  permissionFlags,
  setReportToOpen,
  propertyMatch,
  systemFlags,
  companyId,
  t
}: GetReportFunctionTypes): IReportTypes => {
  const {
    simpleTimeline,
    hideWarehouse,
    hideReportAplicationStatistics,
    reportVisit,
    hideReportTracking,
    hideReportSampleAndAgricultural,
    hideReportTraps,
    hideReportRainFall,
    hideInventory,
    hideReportMonitoringStatistics,
    reportDiseaseRisk,
    hideSamplingReport,
    hideReportFarm,
    hideFarmScoutingReport
  } = permissionFlags;

  return {
    [ReportsTypes.APPLICATION_STATISTICS]: {
      className: 'st-report-drawer__item-spray',
      setReportToOpen,
      text: t('report.spray.name'),
      type: ReportsTypes.SPRAY,
      icon: <SvgSpray />,
      isVisible: !!propertyMatch && !hideReportAplicationStatistics?.value
    },
    [ReportsTypes.MONITORING]: {
      className: 'st-report-drawer__item-scouting',
      setReportToOpen,
      text: t('report.scouting.name'),
      type: ReportsTypes.SCOUTING,
      icon: <SvgSample fill='#f74141' />,
      isVisible: !!propertyMatch && !hideFarmScoutingReport?.value
    },
    [ReportsTypes.VISIT]: {
      className: 'st-report-drawer__item-visit',
      setReportToOpen,
      text: t('report.visit.name'),
      type: ReportsTypes.VISIT,
      icon: <FieldMonitoring />,
      isVisible: reportVisit?.value
    },
    [ReportsTypes.PROPERTY]: {
      className: 'st-report-drawer__item-property',
      setReportToOpen,
      text: t('report.property.name'),
      type: ReportsTypes.PROPERTY,
      icon: <SvgReport />,
      isVisible: !simpleTimeline?.value && !hideReportFarm?.value
    },
    [ReportsTypes.TRACKING]: {
      className: 'st-report-drawer__item-property',
      setReportToOpen,
      text: t('report.tracking.name'),
      type: ReportsTypes.TRACKING,
      icon: <SvgMapExport />,
      isVisible: !hideReportTracking?.value
    },
    [ReportsTypes.SAMPLE]: {
      className: 'st-report-drawer__item-sample',
      setReportToOpen,
      text: t('report.sample.name'),
      type: ReportsTypes.SAMPLE,
      icon: <SvgPinSample fill='#705BC1' />,
      isVisible: !hideReportSampleAndAgricultural?.value
    },
    [ReportsTypes.SAMPLING]: {
      className: 'st-report-drawer__item-sample_points',
      setReportToOpen,
      text: t('report.sample_points.name'),
      type: ReportsTypes.SAMPLING,
      icon: <SvgPinSamplePoints />,
      isVisible: !hideSamplingReport?.value
    },
    [ReportsTypes.TRAP]: {
      className: 'st-report-drawer__item-sample',
      setReportToOpen,
      text: t('report.trap.name'),
      type: ReportsTypes.TRAP,
      icon: <SvgStaticPointReport fill='#D9800A' />,
      isVisible: !hideReportTraps?.value
    },
    [ReportsTypes.WAREHOUSE_INVENTORY]: {
      className: 'st-report-drawer__item-sample',
      setReportToOpen,
      text: t('report.warehouse.name'),
      type: ReportsTypes.WAREHOUSE_INVENTORY,
      icon: <SvgWarehouseReport fill='#1A7AA3' />,
      isVisible: !hideWarehouse?.value && !hideInventory?.value
    },
    [ReportsTypes.RAIN_GAUGE_REPORT]: {
      className: 'st-report-drawer__item-sample',
      setReportToOpen,
      text: t('report.rain_gauge.name'),
      type: ReportsTypes.RAIN_GAUGE_REPORT,
      icon: <RainReportSvg />,
      isVisible: !!systemFlags?.P40_13021_rainGaugeReport && !hideReportRainFall?.value
    },
    [ReportsTypes.DISEASE_RISK]: {
      className: 'st-report-drawer__item-sample',
      setReportToOpen,
      text: t('report.disease_risk.name'),
      type: ReportsTypes.DISEASE_RISK,
      icon: <SvgDiseaseRisk fill='#EE5B3A' />,
      isVisible: reportDiseaseRisk?.value && showDiseaseRiskReport,
      testId: 'btn-disease-risk-report'
    },
    [ReportsTypes.MONITORING_DETAILED]: {
      className: 'st-report-drawer__report-detailed',
      setReportToOpen,
      text: t('report.report_detailed.name'),
      type: ReportsTypes.MONITORING_DETAILED,
      icon: <SvgReportDetailed />,
      isVisible:
        Boolean(systemFlags?.CompaniesWithDetailedConsolidatedFlags ?? ''.toString().indexOf(companyId || '') >= 0) &&
        !hideReportMonitoringStatistics?.value
    },
    [ReportsTypes.MONITORING_CONSOLIDATED]: {
      className: 'st-report-drawer__report-consolidated',
      setReportToOpen,
      text: t('report.report_consolidated.name'),
      type: ReportsTypes.MONITORING_CONSOLIDATED,
      icon: <SvgReportConsolidated />,
      isVisible:
        Boolean(systemFlags?.CompaniesWithDetailedConsolidatedFlags ?? ''.toString().indexOf(companyId || '') >= 0) &&
        !hideReportMonitoringStatistics?.value
    },
    [ReportsTypes.CONTROL_STRATEGIES]: {
      className: '',
      setReportToOpen,
      text: t('report.report_consolidated.name'),
      type: ReportsTypes.CONTROL_STRATEGIES,
      icon: <SvgTableChart fill='#0071CD' />,
      isVisible: false
    },
    [ReportsTypes.BLOCKED_FIELDS]: {
      className: '',
      setReportToOpen,
      text: 'Áreas Bloqueadas',
      type: ReportsTypes.BLOCKED_FIELDS,
      icon: <SvgBlocked />,
      isVisible: false
    },
    [ReportsTypes.NEMA_SAMPLING_POINTS]: {
      className: '',
      setReportToOpen,
      text: t('report.nema_soil_sampling.name'),
      type: ReportsTypes.NEMA_SAMPLING_POINTS,
      icon: <SvgNemaSamplingPoints />,
      isVisible: false
    },
    [ReportsTypes.SEASON_REPORT]: {
      icon: <SVGSeason />
    }
  };
};
