import { Alert, Drawer, Form } from 'antd';
import { Radio } from 'antdV5';
import RegionTreeSelectDefault from 'components/SD-region-tree-select/components/region-tree-select-default';
import type React from 'react';
import type { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import SvgInfoOutlineGlyph from 'st-protector-icons/Glyph/InfoOutlineGlyph';
import '../report.styles.less';
import { ReportsTypes } from '../reports-types.enum';
import FooterDrawer from './components/footer-drawer.component';
import { ExplicativeText } from './explicative-text.component';
import HeaderTitle from './header-title.component';
import useNemaSamplingPoints from './report-nema-sampling-points.hooks';
import type { AcceptedFileFormat } from './report-nema-sampling-points.types';

const radioOptions = [
  { label: 'KML', value: 'KML' },
  { label: 'KMZ', value: 'KMZ' },
  { label: 'CSV', value: 'CSV' },
  { label: 'GEOJSON', value: 'GEOJSON' }
];

interface ReportNemaSamplingPointsDrawer {
  visible: boolean;
  callbackDrawer: () => void;
  icon?: ReactNode;
}

const ReportNemaSamplingPointsDrawer: React.FC<ReportNemaSamplingPointsDrawer> = ({ visible, callbackDrawer, icon }) => {
  const [t] = useTranslation();
  const {
    closeDrawer,
    currentSeasons,
    fileFormat,
    handleGenerateReport,
    handleSelectAreas,
    hasSelectedAreas,
    property,
    regions,
    rootRegion,
    selectedAreas,
    setFileFormat,
    lastCropCyclesFinished,
    report
  } = useNemaSamplingPoints({
    callbackDrawer
  });

  const isGenerateButtonDisabled = !hasSelectedAreas || !report.isAvailable;

  const renderAlert = () => {
    if (report.isAvailable) {
      return (
        <Alert
          message={t('report.nema_sampling_points.form_alert.description')}
          description={lastCropCyclesFinished}
          type='info'
          icon={<SvgInfoOutlineGlyph fill='#0092E4' />}
          showIcon
          closable
        />
      );
    } else {
      return (
        <Alert
          message={t('report.nema_sampling_points.form_alert.error-title')}
          description={`${report.name} - ${report.status}`}
          type='error'
          showIcon
          closable
        />
      );
    }
  };

  return (
    <Drawer
      className='st-report-drawer'
      placement='right'
      visible={visible}
      width={400}
      title={<HeaderTitle icon={icon} type={ReportsTypes.NEMA_SAMPLING_POINTS} />}
      onClose={closeDrawer}
      maskClosable>
      <div className='st-report-drawer__content' data-testid='report-nema-sampling-points-drawer'>
        <ExplicativeText type={ReportsTypes.NEMA_SAMPLING_POINTS} />
        <div className='st-report-drawer__form' data-testid='report-nema-sampling-points-form'>
          <Form layout='vertical'>
            <Form.Item data-testid='report-nema-sampling-points-select' label={t('modals.timeline_xray_report.fields.areas')}>
              {property && (
                <RegionTreeSelectDefault
                  regions={regions}
                  rootRegionId={rootRegion?.id}
                  selectedSeasonsIds={currentSeasons}
                  value={selectedAreas}
                  onChange={handleSelectAreas}
                />
              )}
            </Form.Item>
            <Form.Item data-testid='report-nema-sampling-points-radio' label={t('report.nema_sampling_points.form_labels.file_format')}>
              <Radio.Group
                options={radioOptions}
                defaultValue='KML'
                value={fileFormat}
                onChange={e => setFileFormat(e.target.value as AcceptedFileFormat)}
                optionType='button'
                buttonStyle='solid'
                size='large'
                block
              />
            </Form.Item>
          </Form>
          {renderAlert()}
        </div>
        <FooterDrawer
          onPressConfirm={handleGenerateReport}
          isLoading={false}
          footerMessageShow={false}
          disableConfirmButton={isGenerateButtonDisabled}
          onPressCancel={closeDrawer}
        />
      </div>
    </Drawer>
  );
};

export default ReportNemaSamplingPointsDrawer;
