import type { AllGeoJSON, Feature, FeatureCollection, Point, Position } from '@turf/turf';
import { area, bbox, buffer, pointGrid, pointsWithinPolygon, polygon, squareGrid, transformRotate } from '@turf/turf';
import type { InputProps } from 'antd/lib/input';
import localeDE from 'antd/lib/locale/de_DE';
import localeEN from 'antd/lib/locale/en_US';
import localeES from 'antd/lib/locale/es_ES';
import localeFR from 'antd/lib/locale/fr_FR';
import localePL from 'antd/lib/locale/pl_PL';
import localeBR from 'antd/lib/locale/pt_BR';
import type { AxiosResponse } from 'axios';
import { BALANCE_APP_ID, S3_BUCKET_NAME, VITE_ENV } from 'config/constants';
import convert from 'convert-units';
import { Entitlements } from 'core/shared/enums/entitlements.enum';
import type { UserPlans } from 'core/shared/enums/userPlans.enum';
import { ZendeskKeys } from 'core/shared/enums/zendeskKeys.enum';
import type { LicensingStatusResponse } from 'entities/company/company.models';
import type { Region, RegionGeometry } from 'entities/region/region.models';
import type { Season } from 'entities/season/season.models';
import type { BBox, MultiPolygon } from 'geojson';
import type { TFunction } from 'i18next';
import i18next from 'i18next';
import _, { identity, pickBy } from 'lodash';
import type { MomentInput } from 'moment';
import moment from 'moment';
import type { TaskSprayCreate } from 'pages/tasks/task-create/tasks-create.models';
import { ProductCalculatedBy } from 'pages/tasks/task-create/tasks-create.models';
import type { MonitoringDetailThreshold } from 'pages/timeline/monitoring.models';
import type { Product } from 'pages/timeline/spray.models';
import v from 'voca';
import type { MappedThreshold } from '../core.models';
import { Threshold } from '../core.models';
import type { DateISOString, I18nMapDTO, Page, UUID, V4Page } from './basic.models';

const Turf = { area, bbox, pointGrid, buffer, pointsWithinPolygon, squareGrid, polygon };

export const Languages = (featureNewLanguage = false) => {
  if (featureNewLanguage) {
    return {
      EN: { abrev: 'en', label: 'English (GB)', testId: 'button-language-english' },
      'EN-US': { abrev: 'en-us', label: 'English (US)', testId: 'button-language-us-english' },
      'PT-BR': { abrev: 'pt-BR', label: 'Português (BR)', testId: 'button-language-portuguese' },
      ES: { abrev: 'es', label: 'Español (España)', testId: 'button-language-espanol' },
      'ES-AR': { abrev: 'es-ar', label: 'Español (AR)', testId: 'button-language-ar-espanol' },
      'PL-PL': { abrev: 'pl-PL', label: 'Polskie (PL)', testId: 'button-language-polskie' },
      'DE-DE': { abrev: 'de-DE', label: 'Deutsch (DE)', testId: 'button-language-deutsch' },
      'FR-FR': { abrev: 'fr-FR', label: 'Français (FR)', testId: 'button-language-francois' },
      'HU-HU': { abrev: 'hu-HU', label: 'Magyar (HU)', testId: 'button-language-hungarian' },
      IT: { abrev: 'it', label: 'Italian (IT)', testId: 'button-language-italian' }
    };
  }

  return {
    EN: { abrev: 'en', label: 'English (GB)', testId: 'button-language-english' },
    'EN-US': { abrev: 'en-us', label: 'English (US)', testId: 'button-language-us-english' },
    'PT-BR': { abrev: 'pt-BR', label: 'Português (BR)', testId: 'button-language-portuguese' },
    ES: { abrev: 'es', label: 'Español (España)', testId: 'button-language-espanol' },
    'ES-AR': { abrev: 'es-ar', label: 'Español (AR)', testId: 'button-language-ar-espanol' },
    'PL-PL': { abrev: 'pl-PL', label: 'Polskie (PL)', testId: 'button-language-polskie' },
    'DE-DE': { abrev: 'de-DE', label: 'Deutsch (DE)', testId: 'button-language-deutsch' },
    'FR-FR': { abrev: 'fr-FR', label: 'Français (FR)', testId: 'button-language-francois' }
  };
};

export const UnitSystem = {
  METRIC: 'metric',
  IMPERIAL: 'imperial'
};

const CurrencySystem = {
  REAL: 'real',
  DOLAR: 'dolar'
};

export const validatePlansAndEntitlements = (
  licensings: LicensingStatusResponse | null,
  plans: UserPlans[] | null,
  entitlements: Entitlements[] | null
): boolean => {
  let allApps = licensings?.apps;
  if (plans?.length) {
    allApps = allApps?.filter(item => plans.includes(item.plan.name));
  }
  if (!entitlements?.length) {
    return !!allApps?.length;
  }

  allApps = allApps?.filter(item => !!item.plan.entitlements.filter(entitlement => entitlements.includes(entitlement.key)).length);
  return !!allApps?.length;
};

export const getZendeskWidgetKey = (licensings: LicensingStatusResponse, entitlements: Entitlements[] | null): string => {
  let allApps = licensings.apps;

  if (!entitlements?.length || !licensings.apps.length) {
    return '';
  }

  allApps = allApps.filter(item => !!item.plan.entitlements.filter(entitlement => entitlements.includes(entitlement.key)).length);

  if (!allApps?.length) {
    return '';
  }

  if (allApps[0].plan.entitlements.find(el => el.key === Entitlements.ZENDESK)?.key) {
    return ZendeskKeys.EU_KEY;
  }
  if (allApps[0].plan.entitlements.find(el => el.key === Entitlements.ZENDESK_LATAM)?.key) {
    return ZendeskKeys.LATAM_KEY;
  }
  return '';
};

export const getNameByCurrentLanguage = (name: I18nMapDTO | undefined): string => {
  if (!name) return '';
  const currentLanguage = getCurrentLanguage().toLowerCase();
  let fallback = name.localized_strings[Object.keys(name.localized_strings)[0]];

  const nameReduce = Object.entries(name.localized_strings).reduce((arr, value) => {
    if (value[0].toLowerCase() === currentLanguage) return value[1];

    if (value[0].toLowerCase().split('-')[0] === currentLanguage.split('-')[0]) {
      fallback = value[1];
    }
    return arr;
  }, '');

  return nameReduce || fallback;
};

export const getStringOrLocalizedString = (value?: string | I18nMapDTO): string => {
  if (!value) return '';

  if (typeof value === 'string') return value;

  return getNameByCurrentLanguage(value);
};

export const getRightTranslateDoseSpray = (product: TaskSprayCreate): string => {
  if (product.calculated_by === ProductCalculatedBy.AREA) {
    return 'units.area_abreviation';
  }
  return `pages.tasks.tasks_products.calculation_types.${product.calculated_by}`;
};

export function getLocaleFromLanguage(language: string) {
  switch (language) {
    case 'pt-br':
      return localeBR;
    case 'de-de':
      return localeDE;
    case 'fr-fr':
      return localeFR;
    case 'pl-pl':
      return localePL;
    case 'es':
      return localeES;
    default:
      return localeEN;
  }
}

export const getDaysFromToday = (date: MomentInput) => {
  return Math.abs(moment().startOf('day').diff(moment(date).startOf('day'), 'days'));
};

export const getInitials = (name?: string): string => {
  if (typeof name === 'string') {
    const splittedName = name.split(' ');
    if (splittedName.length === 2) {
      const firstLetter = splittedName[0].charAt(0).toUpperCase();
      const secondLetter = splittedName[1].charAt(0).toUpperCase();
      return `${firstLetter}${secondLetter}`;
    }
    return name.slice(0, 2).toUpperCase();
  }
  return '';
};

export const sortArrayByDateAscending = <K extends string, T extends Record<K, MomentInput>>(array: T[], dateKey: K) => {
  return array.sort((a, b) => {
    const aDate = moment(a[dateKey]);
    const bDate = moment(b[dateKey]);

    return aDate < bDate ? -1 : aDate > bDate ? 1 : 0;
  });
};

export const sortArrayByDateDescending = <K extends string, T extends Record<K, MomentInput>>(array: T[], dateKey: K) => {
  return array.sort((a, b) => {
    const aDate = moment(a[dateKey]);
    const bDate = moment(b[dateKey]);

    return aDate > bDate ? -1 : aDate < bDate ? 1 : 0;
  });
};

export const formatAreaSimple = (value: number | string) => {
  const unitSystem = getCurrentUnitSystem();
  let areaValue = parseNumber(value);
  if (unitSystem === UnitSystem.IMPERIAL) areaValue = convert(areaValue).from('ha').to('ac');

  return areaValue;
};

export const formatLongDistance = ({
  value,
  precision = 1,
  shouldUseUOM = true
}: {
  value: string | number;
  precision?: number;
  shouldUseUOM?: boolean;
}): string => {
  const unitSystem = getCurrentUnitSystem();
  const distanceValueInKM = parseNumber(value);
  const isMetric = unitSystem === UnitSystem.METRIC;
  const distanceInSelectedUnits = isMetric ? distanceValueInKM : convert(distanceValueInKM).from('km').to('mi');
  const fixedPrecisionDistance = distanceInSelectedUnits.toFixed(precision);

  if (shouldUseUOM) {
    const uom = isMetric ? 'km' : 'mi';
    return `${fixedPrecisionDistance} ${uom}`;
  }

  return fixedPrecisionDistance;
};

export const formatArea = (value: number | string, min = 2, max = 2) => {
  const unitSystem = getCurrentUnitSystem();
  let areaValue = parseNumber(value);
  if (unitSystem === UnitSystem.IMPERIAL) areaValue = convert(areaValue).from('ha').to('ac');

  return formatNumber(areaValue, min, max);
};

export const formatNumber = (number: number | string | undefined, min = 2, max = 2): string => {
  if (number === undefined || number === null) return '';
  const parsedNumber = parseNumber(number);

  if (isNaN(parsedNumber)) {
    return '';
  }

  const minimumFractionDigits = min <= 18 ? min : 18;
  const maximumFractionDigits = max <= 18 ? max : 18;

  return parsedNumber.toLocaleString(getCurrentLanguage(), {
    minimumFractionDigits,
    maximumFractionDigits
  });
};

export function parseNumber(number: number | string) {
  if (number === undefined || number === null) return NaN;
  return typeof number === 'number' ? number : parseFloat(number.replace(',', '.'));
}

export function round(value: number, decimal = 2): number {
  return +`${Math.round(`${value}e+${decimal}` as unknown as number)}e-${decimal}`;
}

export function compareWithErrorMargin(x: number, y: number, margin = 0.1): boolean {
  return Math.abs(x - y) <= margin;
}

export const formatMinutes = (minutes: number, showHours = false): string => {
  const nonInteger = minutes - Math.floor(minutes);
  let hoursResult = '';
  let minutesResult = '';

  if (showHours) {
    hoursResult = `${Math.floor(minutes / 60)}:`;
    minutesResult = Math.floor(minutes % 60)
      .toString()
      .padStart(2, '0');
  } else {
    minutesResult = (minutes - nonInteger).toString().padStart(2, '0');
  }

  const secondsResult = (parseFloat(nonInteger.toFixed(2)) * 60).toFixed(0).padStart(2, '0');
  return `${hoursResult}${minutesResult}:${secondsResult}`;
};

export const openFullscreen = () => {
  void document.documentElement.requestFullscreen();
};

export const closeFullscreen = () => {
  void document.exitFullscreen();
};

export const getBreadcrumb = (breadcrumb: string): string => breadcrumb.replace(/ -> [^()]* -> /g, '/ ... /').replace(/ -> /g, '/ ');

export const formatDateWithEmptyState = (date: string) => {
  const mDate = date && moment(date).isValid();

  if (!mDate) return '--';

  return moment(date).format('DD/MM/YYYY');
};

export const formatDate = (
  date: string,
  options: { full: boolean; range: boolean; includeYear?: boolean } = {
    full: false,
    range: false,
    includeYear: true
  }
): string => {
  const currentLanguage = getCurrentLanguage();
  let formatPattern = `${currentLanguage === 'en' ? 'MMM DD' : 'DD MMM'} ${options.includeYear ? ', YY' : ''}`;
  if (options.full) {
    formatPattern += ' HH:mm';
  }
  let formatted = moment(date).format(formatPattern);

  if (options.range) {
    formatted += ` • ${moment(date).fromNow()}`;
  }
  return formatted;
};

export const mockPageableDataInAxiosResponse = (response: AxiosResponse<Page<unknown> | V4Page<unknown>>) => {
  if (response.data && !(response.data as Page<unknown>).mutableContent && !(response.data as V4Page<unknown>).content) {
    response.data = {
      number: 0,
      size: 1000,
      pages: 1,
      total: (response.data as unknown as []).length,
      mutableContent: [...(response.data as unknown as [])]
    };
    return response;
  }
  return response;
};

export const generateUUID = () => {
  if (crypto.randomUUID) {
    return crypto.randomUUID();
  }

  return '10000000-1000-4000-8000-100000000000'.replace(/[018]/g, c =>
    (+c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (+c / 4)))).toString(16)
  );
};

interface ColorManagerType {
  map: Map<UUID, string>;
  getColor: (id: UUID, colorScheme?: number) => string | undefined;
  nextColorFromArray: (id: UUID) => string;
}

export const ColorManager = function (this: ColorManagerType, colorArray = arrayOfColors) {
  this.map = new Map();
  let tempColors = colorArray;
  this.getColor = (id, colorScheme = 0) => {
    if (colorScheme === 1) {
      tempColors = arrayOfColorsSmooth;
    }
    if (this.map.has(id)) return this.map.get(id);

    return this.nextColorFromArray(id);
  };
  this.nextColorFromArray = id => {
    if (this.map.size < tempColors.length) {
      const color = tempColors[this.map.size];
      this.map.set(id, color);
      return color;
    }
    const color = tempColors[this.map.size % tempColors.length];
    this.map.set(id, color);
    return color;
  };
} as unknown as new (colorArray?: string[]) => ColorManagerType;

export function getNumberInHectares(number: number, flowConversion?: boolean): number {
  if (getCurrentUnitSystem() === UnitSystem.IMPERIAL) {
    return flowConversion ? number / convert(1).from('ac').to('ha') : convert(number).from('ac').to('ha');
  }
  return number;
}

export const getNumberConvertedVolume = (quantity?: number): string => formatNumber(quantity, 2, 3);

export function getLanguagesAble() {
  const oldLanguages = {
    pt: 'pt-BR',
    'pt-BR': 'pt-BR',
    es: 'es',
    de: 'de-DE',
    'de-DE': 'de-DE',
    pl: 'pl-PL',
    'pl-PL': 'pl-PL',
    fr: 'fr-FR',
    'fr-FR': 'fr-FR',
    'en-US': 'en-us',
    'en-GB': 'en',
    'es-ar': 'es-ar',
    'hu-hu': 'hu-HU'
  };

  const newLanguages = { ...oldLanguages, ...{ it: 'it' } };
  const arrayLanguages = VITE_ENV === 'production' ? oldLanguages : newLanguages;
  return arrayLanguages;
}

export function getCurrentLanguage(): string {
  let currentLanguage = localStorage.getItem('currentLanguage') ?? undefined;
  if (!currentLanguage) currentLanguage = i18next.language;
  // Returns Current language or Navigator or Default('en')
  const arrayLanguages = getLanguagesAble();

  return currentLanguage?.toLocaleLowerCase() ?? arrayLanguages[navigator.language as keyof typeof arrayLanguages] ?? 'en';
}

export function getCurrentUnitSystem(): string {
  return localStorage.getItem('currentUnitSystem') ?? UnitSystem.METRIC;
}

export function getCurrentCurrency(): string {
  return localStorage.getItem('currentCurrency') ?? CurrencySystem.REAL;
}

const arrayOfColors: UUID[] = [
  '#00EAFF',
  '#AA00FF',
  '#FF7F00',
  '#0040FF',
  '#EDB9B9',
  '#E7E9B9',
  '#8F2323',
  '#23628F',
  '#8F6A23',
  '#4F8F23',
  '#000000',
  '#737373'
];

export const arrayOfColorsSmooth: UUID[] = [
  '#3A6EBC',
  '#2AAF60',
  '#EC6E8D',
  '#3A9BBC',
  '#87BB32',
  '#BE6077',
  '#32BBBB',
  '#99CE42',
  '#F6A9BC'
];

export function getPhenomenonUrl(id: string): string {
  return `https://s3.amazonaws.com/${S3_BUCKET_NAME}/phenomenon/${id}.png`;
}

const convertPointsFeatureCollectionToPositionsCollection = (points: FeatureCollection<Point>): Position[] => {
  return points.features.map((feature: Feature<Point>) => [feature.geometry.coordinates[1], feature.geometry.coordinates[0]]);
};

export function makeGrid(
  areaPolygon: RegionGeometry,
  distanceBetweenPoints: number,
  region: RegionGeometry,
  rotation?: number,
  isMeters?: boolean
) {
  const distance = isMeters ? distanceBetweenPoints : Math.sqrt(distanceBetweenPoints * 10000);
  let originalArea = areaPolygon as AllGeoJSON;
  if (rotation) {
    originalArea = transformRotate(areaPolygon as AllGeoJSON, rotation * -1);
  }
  const extent = Turf.bbox(originalArea) as BBox;
  let grid = Turf.pointGrid(extent, distance, { units: 'meters' });
  if (rotation) {
    grid = transformRotate(grid, rotation);
  }
  const points = Turf.pointsWithinPolygon(grid, region as unknown as MultiPolygon);
  return convertPointsFeatureCollectionToPositionsCollection(points);
}

interface MinMax {
  xMin: number;
  xMax: number;
  yMin: number;
  yMax: number;
}

const findMinAndMaxBetweenRegions = (coordinatesMinAndMax: MinMax, minAndMax: MinMax): MinMax => {
  return {
    xMin: Math.min(coordinatesMinAndMax.xMin, minAndMax.xMin),
    xMax: Math.max(coordinatesMinAndMax.xMax, minAndMax.xMax),
    yMin: Math.min(coordinatesMinAndMax.yMin, minAndMax.yMin),
    yMax: Math.max(coordinatesMinAndMax.yMax, minAndMax.yMax)
  };
};

const findMinAndMaxByRegion = (areaCoordinates: Position[]): MinMax => {
  let xMin = Infinity;
  let xMax = -Infinity;
  let yMin = Infinity;
  let yMax = -Infinity;

  areaCoordinates.forEach(areaCoordinate => {
    const x = areaCoordinate[0];
    const y = areaCoordinate[1];
    xMin = Math.min(xMin, x);
    xMax = Math.max(xMax, x);
    yMin = Math.min(yMin, y);
    yMax = Math.max(yMax, y);
  });

  return { xMin, xMax, yMin, yMax };
};
export const findCoordinatesToCreateGrid = (areaPolygon: Region[]) => {
  let coordinatesMinAndMax: MinMax = {
    xMin: Infinity,
    xMax: -Infinity,
    yMin: Infinity,
    yMax: -Infinity
  };

  areaPolygon.forEach(region => {
    const isMultiplePolygon = ((region.geometry as Feature)?.geometry as MultiPolygon)?.type === 'MultiPolygon';

    const coordinates = _.flatten<Position>(
      ((region.geometry as Feature)?.geometry as MultiPolygon)?.coordinates as unknown as ArrayLike<Position[]>
    );

    const coordinatesMinAndMaxByRegion = findMinAndMaxByRegion(isMultiplePolygon ? _.flatten<Position>(coordinates) : coordinates);
    coordinatesMinAndMax = findMinAndMaxBetweenRegions(coordinatesMinAndMax, coordinatesMinAndMaxByRegion);
  });

  return coordinatesMinAndMax;
};

export const findCoordinatesToCreateGridByArea = (areaPolygon: Region[]) => {
  const coordinatesByArea: MinMax[] = [];

  areaPolygon.forEach(region => {
    const isMultiplePolygon = ((region.geometry as Feature)?.geometry as MultiPolygon)?.type === 'MultiPolygon';

    const coordinates = _.flatten<Position>(
      ((region.geometry as Feature)?.geometry as MultiPolygon)?.coordinates as unknown as ArrayLike<Position>
    );

    const coordinatesMinAndMaxByRegion = findMinAndMaxByRegion(isMultiplePolygon ? _.flatten<Position>(coordinates) : coordinates);
    coordinatesByArea.push(coordinatesMinAndMaxByRegion);
  });

  return coordinatesByArea;
};

export const makePoints = (areaPolygon: Region[], gridSize: number, isMeters?: boolean) => {
  const offset = 0.001;
  const size = isMeters ? gridSize : Math.sqrt(gridSize * 10000);
  const options = { units: 'meters' } as const;
  const pointsByArea: Feature[][] = [];
  const minMaxAreas = findCoordinatesToCreateGridByArea(areaPolygon);
  minMaxAreas.forEach((minMaxArea, index) => {
    const { xMin, xMax, yMin, yMax } = minMaxArea;
    const gridVertices = Turf.polygon([
      [
        [xMin - offset, yMin - offset],
        [xMax + offset, yMin - offset],
        [xMax + offset, yMax + offset],
        [xMin - offset, yMax + offset],
        [xMin - offset, yMin - offset]
      ]
    ]);
    const gridBuffer = Turf.buffer(gridVertices, size, options);
    const extent = Turf.bbox(gridBuffer) as BBox;
    const points = Turf.pointGrid(extent, size, options);
    const pointsInsideArea = Turf.pointsWithinPolygon(points, areaPolygon[index].geometry as unknown as MultiPolygon);

    pointsByArea.push(pointsInsideArea.features);
  });

  const gridPoints = _.flatten(pointsByArea);

  return {
    type: 'FeatureCollection',
    features: gridPoints
  };
};

export function makeGridPolygon(areaPolygon: Region[], gridSize: number, isMeters?: boolean): FeatureCollection {
  const { xMin, xMax, yMin, yMax } = findCoordinatesToCreateGrid(areaPolygon);

  const offset = 0.001;

  const gridVertices = Turf.polygon([
    [
      [xMin - offset, yMin - offset],
      [xMax + offset, yMin - offset],
      [xMax + offset, yMax + offset],
      [xMin - offset, yMax + offset],
      [xMin - offset, yMin - offset]
    ]
  ]);

  const size = isMeters ? gridSize : Math.sqrt(gridSize * 10000);
  const options = { units: 'meters' } as const;
  const gridBuffer = Turf.buffer(gridVertices, size, options);
  const extent = Turf.bbox(gridBuffer) as BBox;
  return Turf.squareGrid(extent, size, options);
}

export function arrayMove<T>(list: T[], from: number, to: number) {
  const fromValue = list[from];
  const toValue = list[to];

  return list.map((value, idx) => {
    let result = value;
    if (idx === from) {
      result = toValue;
    } else if (idx === to) {
      result = fromValue;
    }

    return result;
  });
}

export function checkImageExists(url: string, onLoad?: (image: HTMLImageElement) => void, onError?: OnErrorEventHandler): void {
  const image = new Image();
  image.onload = onLoad?.bind(null, image) ?? null;
  image.onerror = onError ?? null;
  image.src = url;
}

export function formatProductDose(t: TFunction, product: Product): string {
  let formatedDose = `${formatNumber(getNumberInHectares(product.dose), 2, 3)} ${t(`units.measure.${product.dose_unit}.abbreviation`)}`;
  switch (product.calculated_by) {
    case ProductCalculatedBy.AREA:
      formatedDose += `/${t(`units.${getCurrentUnitSystem()}.area_abbreviation`)}`;
      break;
    case ProductCalculatedBy.VOLUME:
      formatedDose += `/${t('pages.tasks.tasks_products.calculation_types.VOLUME')}`;
      break;
    case ProductCalculatedBy.TANK:
      formatedDose += `/${t('units.measure.TANK.name')}`;
  }

  return formatedDose;
}

export const selectText: InputProps['onFocus'] = e => {
  e.target.select();
};

function valueIsOnThresholdInterval(threshold: MonitoringDetailThreshold, value: number): boolean {
  const intervals = [threshold.left, threshold.right].sort((a, b) => a - b);
  return value >= intervals[0] && value <= intervals[1];
}

function isPositiveIndicator(acceptanceThreshold: MonitoringDetailThreshold, damageThreshold: MonitoringDetailThreshold) {
  return acceptanceThreshold.right > damageThreshold.right;
}

export function calculateThreshold(value: number, data?: MonitoringDetailThreshold[]): Threshold {
  if (!data?.length) return Threshold.NONE;

  const thresholds: MappedThreshold = data.reduce((acc, current) => {
    return { ...acc, [current.label]: current };
  }, {} as MappedThreshold);

  if (valueIsOnThresholdInterval(thresholds.CONTROL, value)) {
    return Threshold.CONTROL;
  }

  if (isPositiveIndicator(thresholds.ACCEPTANCE, thresholds.DAMAGE)) {
    if (value > thresholds.ACCEPTANCE.right) {
      return Threshold.ACCEPTANCE;
    }
    return Threshold.DAMAGE;
  }

  if (value > thresholds.DAMAGE.left) {
    return Threshold.DAMAGE;
  }
  return Threshold.ACCEPTANCE;
}

export function getHighestThreshold(thresholds: (Threshold | undefined)[]): Threshold | undefined {
  if (thresholds.includes(Threshold.DAMAGE)) {
    return Threshold.DAMAGE;
  }
  if (thresholds.includes(Threshold.CONTROL)) {
    return Threshold.CONTROL;
  }
  if (thresholds.includes(Threshold.ACCEPTANCE)) {
    return Threshold.ACCEPTANCE;
  }

  return Threshold.NONE;
}

export function textContains(left: string, right: string): boolean {
  const leftNormalized = v.chain(left).latinise().upperCase().value();
  const rightNormalized = v.chain(right).latinise().upperCase().value();

  return leftNormalized.includes(rightNormalized);
}

export function hashNumber(id: UUID): number {
  const value = id.split('-')[0];
  return parseInt(value, 16);
}

export function isUK() {
  const { host } = window.location;
  return host.search('.syngentadigital.') !== -1;
}

export function clearObject(obj: Record<string, unknown>) {
  return pickBy(obj, identity);
}

export function getImageBase64FromUrl(url: string, width?: number, height?: number): Promise<string> {
  return new Promise(resolve => {
    const mutableImage = new Image();
    mutableImage.setAttribute('crossOrigin', 'anonymous');

    mutableImage.onload = function () {
      const mutableCanvas = document.createElement('canvas');
      mutableCanvas.width = width ?? mutableImage.naturalWidth;
      mutableCanvas.height = height ?? mutableImage.naturalHeight;

      const mutableCtx = mutableCanvas.getContext('2d');
      if (mutableCtx) {
        mutableCtx.fillStyle = '#fff';
        mutableCtx.fillRect(0, 0, mutableCanvas.width, mutableCanvas.height);
        mutableCtx.drawImage(this as CanvasImageSource, 0, 0);
      }

      resolve(mutableCanvas.toDataURL('image/jpeg'));
    };

    mutableImage.src = url;
  });
}

export const getSeasonsDescription = (seasons: Season[]): string => {
  return seasons
    .map(season => {
      const startYear = moment(season.start_date).format('YY');
      const endYear = moment(season.end_date).format('YY');

      return `${season.name} | ${startYear === endYear ? startYear : `${startYear} - ${endYear}`}`;
    })
    .join(', ');
};

export const getDateAddedDays = (date: string, days?: number): DateISOString => {
  const newDate = new Date(date);
  if (days) newDate.setDate(newDate.getDate() + days);
  return newDate.toISOString();
};

export function defineDecimalSeparatorType(getDefaultLanguage: () => string): string {
  const currentLanguage = getDefaultLanguage();

  if (currentLanguage.includes('en') || currentLanguage.includes('en-us')) return '.';

  return ',';
}

export function defineDefaultPerLanguage(defaultLanguage: string): boolean {
  if (defaultLanguage === 'en' || defaultLanguage === 'en-us' || defaultLanguage === 'hu-hu') return true;

  return false;
}

export const removePathsFromURL = (value: number) => {
  return window.location.pathname.split('/').slice(0, value).join('/');
};

export const createDictionaryFromList = <T, U = T>(
  list: T[],
  property: keyof T,
  getter: ((value: T) => U) | undefined = undefined
): Record<string, U> => {
  return list.reduce<Record<string, U>>((acc, element) => {
    acc[element[property] as string] = getter ? getter(element) : (element as unknown as U);
    return acc;
  }, {});
};

export const areArraysWithSameContent = <T>(arr1: T[], arr2: T[]): boolean => {
  return arr1.length === arr2.length && arr1.filter(el => arr2.includes(el)).length === arr2.length;
};

export const verifyMycotoxinRiskEntitlement = (licensingStatus: LicensingStatusResponse | null): boolean => {
  const hasMycotoxinRiskEntitlement =
    licensingStatus?.apps && validatePlansAndEntitlements(licensingStatus, null, [Entitlements.REGIONAL_RISK_MYCOTOXIN]);

  return !!hasMycotoxinRiskEntitlement;
};

export const verifyUserAccessInRegionalOverview = (licensingStatus: LicensingStatusResponse | null): boolean => {
  const hasRegionalOverviewAccess =
    licensingStatus?.apps &&
    validatePlansAndEntitlements(licensingStatus, null, [Entitlements.REGIONAL_RISK, Entitlements.REGIONAL_RISK_MYCOTOXIN]);

  return !!hasRegionalOverviewAccess;
};

export const verifyBalanceAppAccess = (licensingStatus: LicensingStatusResponse | null): boolean => {
  const hasBalanceAccess = licensingStatus?.apps
    ?.filter(app => app.id === BALANCE_APP_ID)
    .find(app => moment().isBetween(moment(app.contract.start), moment(app.contract.end)));

  return !!hasBalanceAccess;
};

export const userHasNoAccessTasksPrescriptionInRegionalRisk = (licensingStatus: LicensingStatusResponse | null): boolean => {
  const hideTaskEntitlement = licensingStatus?.apps && validatePlansAndEntitlements(licensingStatus, null, [Entitlements.HIDE_TASKS]);

  if (hideTaskEntitlement) {
    return false;
  }

  return true;
};

export const threeDotsInABigNames = (phrase: string, lengthLeftName: number, lengthRightName: number): string => {
  if (phrase.length > 17) {
    return `${phrase.substring(0, lengthLeftName)} ... ${phrase.substring(phrase.length - lengthRightName, phrase.length)}`;
  }
  return phrase;
};

export const getCurrencySymbol = (locale: string, currency: string): string => {
  return (0)
    .toLocaleString(locale, {
      style: 'currency',
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    })
    .replace(/\d/g, '')
    .trim();
};

export const getDayTranslation = (numberOfDays: number, t: TFunction) => {
  return t('units.time.DAY.name', { count: numberOfDays });
};

export const includesName = (entityName: string, name: string): boolean => _.includes(entityName?.toLowerCase(), name?.toLowerCase());

export const mapEntitiesByAttr = <K extends string, T extends Record<K, unknown>>(entityList: T[] | undefined, attr: K | [K]) =>
  _.compact(_.map(entityList, node => _.get(node, attr)));

export const filterEntitiesByName = <T extends Record<'name', string>>(entityList: T[], name: string): T[] => {
  if (!name) {
    return entityList;
  }

  return _.filter(entityList, entity => includesName(entity.name, name));
};

export const compareArray = (itemA: Record<'name', string>, itemB: Record<'name', string>) => {
  if (itemA.name < itemB.name) return -1;
  if (itemA.name > itemB.name) return 1;
  return 0;
};

export const sortEntitiesAlphabeticallyByName = <T extends Record<'name', string>>(entityList: T[]): T[] => {
  return [...entityList].sort((a, b) => {
    return compareArray(a, b);
  });
};

export const getNumericValueFromLocalStore = (key: string): number | null => {
  const value = localStorage.getItem(key);
  return value ? Number(value) : null;
};

const languageToChange: Record<string, string> = { fr: 'fr-fr' };

export const handleInterfaceLanguage = (lang: string | null) => {
  if (!lang) return null;
  return languageToChange[lang] ?? lang;
};
