import { COLOR_OPTIONS } from 'config/style';
import styled from 'styled-components';

export const RoundedIconWrapper = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${COLOR_OPTIONS.GRAY_7};
  display: flex;
  align-items: center;
  justify-content: center;
`;
