import axios from 'axios';
import { PROTECTOR_API_URL } from 'config/constants';
import sentryService from 'sentry/service';
import type { IResponseGetRecommendationNotes, IResponsePostRecommendationNotes } from './disease-risk.type';

const baseUrl = `${PROTECTOR_API_URL}/v2/properties`;

export const postRecommendationNotes = async (propertyId: string, note: { notes: string; season_id: string }) => {
  try {
    const response = await axios.post<IResponsePostRecommendationNotes>(
      `${baseUrl}/${propertyId}/disease-risks/recommendation-notes`,
      note
    );
    return response.data;
  } catch (error) {
    sentryService.captureException(error as Error, {
      fileName: 'disease-risk.recommendation-notes.service.ts',
      method: 'postRecommendationNotes',
      description: 'error on post recommendation notes'
    });
  }
};

export const getRecommendationNotes = async (propertyId: string, seasonId: string) => {
  try {
    const response = await axios.get<IResponseGetRecommendationNotes[]>(
      `${baseUrl}/${propertyId}/disease-risks/recommendation-notes?seasonId=${seasonId}`
    );
    return response.data;
  } catch (error) {
    sentryService.captureException(error as Error, {
      fileName: 'disease-risk.recommendation-notes.service.ts',
      method: 'getRecommendationNotes',
      description: 'error on get recommendation notes'
    });
  }
};
