import { Tabs } from 'antdV5';
import { RichText } from 'components/rich-text';
import { COLOR_OPTIONS } from 'config/style';
import styled from 'styled-components';

export const StyledTabs = styled(Tabs)`
  .ant-tabs-tab {
    margin-right: 0;

    &:hover {
      color: ${COLOR_OPTIONS.BLUE_60};
    }
  }
`;

export const StyledRichText = styled(RichText)`
  .ql-editor {
    min-height: 150px;
  }
`;
