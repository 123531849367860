import type { Dictionary } from 'config/types';
import type { UUID } from 'core/utils/basic.models';
import { generateUUID, getCurrentLanguage } from 'core/utils/functions';
import type { AnalyticsInfo } from 'core/utils/segment/user-events.types';
import type { SegmentTrackingHookReturn } from 'core/utils/segment/useSegmentTracking';
import type { Company } from 'entities/company/company.models';
import type { Property } from 'entities/property/property.models';
import type { Region } from 'entities/region/region.models';
import { RequestReport } from 'entities/reports/report.actions';
import type { Dispatch } from 'redux';
import { ReportsTypes } from '../reports-types.enum';
import type { AcceptedFileFormat } from './report-nema-sampling-points.types';

interface GenerateNemaSamplingPointsReportProps {
  property?: Property;
  company?: Company;
  selectedAreas: UUID[];
  executionId: string;
  exportType: AcceptedFileFormat;
  regions: Dictionary<Region>;
  closeDrawer: () => void;
  dispatch: Dispatch;
  segmentTracking: SegmentTrackingHookReturn;
}

export const generateNemaSamplingPointsReport = ({
  property,
  company,
  executionId,
  exportType,
  selectedAreas,
  closeDrawer,
  regions,
  dispatch,
  segmentTracking
}: GenerateNemaSamplingPointsReportProps): void => {
  const isValidExportType = ['CSV', 'KML', 'KMZ', 'GEOJSON'].includes(exportType);
  if (property && company && selectedAreas && isValidExportType) {
    const id = generateUUID();
    const locale = getCurrentLanguage();
    const requestBody = {
      id,
      type: ReportsTypes.NEMA_SAMPLING_POINTS,
      property_id: property.id,
      data_request: {
        property_id: property.id,
        execution_id: executionId,
        field_ids: selectedAreas,
        export_type: exportType,
        language: locale
      }
    };
    const payload: AnalyticsInfo = {
      propertyId: property.id,
      propertyName: property.name,
      selectedAreasId: selectedAreas,
      selectedAreasName: Object.values(regions)
        .filter(selectedRegion => selectedAreas.includes(selectedRegion.id))
        .map(region => region.name),
      reportType: requestBody.type,
      from: 'Export Report Nema Sampling Points'
    };
    segmentTracking.track('Export Report Nema Sampling Points - Generated Report ', payload);
    dispatch(RequestReport(requestBody));
  }
  closeDrawer();
};
