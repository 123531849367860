import type { Property } from '../../../../../entities/property/property.models.ts';

export interface PropertiesByOrg {
  content: Property[];
}

export enum SeasonReportOptions {
  BY_ORG = 'BY_ORG',
  BY_PROPERTY = 'BY_PROPERTY'
}
