import type React from 'react';

export const BalanceIcon: React.FC = () => {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M9.03893 2.74219C5.56134 2.74219 2.74219 5.56335 2.74219 9.04343V14.5718L15.3888 5.02961H8.14461L9.80935 6.35927L4.60808 10.2887V11.0405L10.7872 6.37248L9.85729 5.62973H13.5932L3.34188 13.3608V9.04343C3.34188 5.89479 5.89254 3.34231 9.03893 3.34231H16.655V4.10003L17.2547 3.64625V2.74219H9.03893Z'
        fill='#73DC78'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17.2547 10.9534C17.2547 14.4335 14.4355 17.2547 10.9579 17.2547H2.74219V16.3386L6.94002 13.1646V16.6546H7.89952V12.4393L12.0974 9.27302V16.5405C12.4285 16.4733 12.7493 16.3772 13.0569 16.2552V8.54534L17.2547 5.37234V10.9534ZM16.655 10.9534C16.655 13.125 15.4417 15.013 13.6565 15.9757V8.84412L16.655 6.57769V10.9534ZM6.34033 16.6546V14.3702L3.34188 16.6373V16.6546H6.34033ZM8.49921 12.7385V16.6546H10.9579C11.14 16.6546 11.32 16.646 11.4977 16.6293V10.4769L8.49921 12.7385Z'
        fill='#73DC78'
      />
    </svg>
  );
};
