import type moment from 'moment';
import { create } from 'zustand';
import type { UUID } from '../../core/utils/basic.models.ts';
import type { LeafhopperPriorities, PrioritiesIds, ProductResidualInfo } from './leafhopper/leafhopper.models.ts';
import type {
  AssigneesGroupProductivityState,
  AssigneesGroupState,
  PrioritiesState,
  SamplingState
} from './post-harvest/post-harvest.models.ts';
import type { PestRiskRules } from './routing-configuration.model.ts';
import type { TrapDistribution } from './trap-routing/trap-routing.models.ts';

interface RoutingPlanConfigurationState {
  enableNextStep: boolean;
  setEnableNextStep: (value: boolean) => void;
  selectedInspectionGroups: UUID[];
  setSelectedInspectionGroups: (value: UUID[]) => void;
  priorities: PrioritiesState;
  setPriorities: (value: PrioritiesState) => void;
  selectedDays: moment.Moment[];
  setSelectedDays: (value: moment.Moment[]) => void;
  assigneesGroups: AssigneesGroupState[];
  setAssigneesGroups: (value: AssigneesGroupState[]) => void;
  assigneesGroupsProductivity: AssigneesGroupProductivityState;
  setAssigneesGroupsProductivity: (value: AssigneesGroupProductivityState) => void;
  assigneeMaxDistance: number | undefined;
  setAssigneeMaxDistance: (value: number | undefined) => void;
  sampling: SamplingState;
  setSampling: (value: SamplingState) => void;
  fieldPriorities: Record<PrioritiesIds, LeafhopperPriorities>;
  setFieldPriorities: (value: Record<PrioritiesIds, LeafhopperPriorities>) => void;
  productsResidual: ProductResidualInfo[];
  setProductsResidual: (value: ProductResidualInfo[]) => void;
  pestRiskRules: PestRiskRules;
  setPestRiskRules: (value: PestRiskRules) => void;
  trapDistribution: TrapDistribution;
  setTrapDistribution: (value: TrapDistribution) => void;
  showFieldClusterModal: boolean;
  setShowFieldClusterModal: (value: boolean) => void;
  fieldClusterPhenomenaIds: string[];
  setFieldClusterPhenomenaIds: (value: string[]) => void;
  previousFieldClusterIsEmpty: boolean;
  setPreviousFieldClusterIsEmpty: (value: boolean) => void;
  currentPage: number;
  setCurrentPage: (value: number) => void;
  fieldClusterPhenomenaIdsLoaded: boolean;
  setFieldClusterPhenomenaIdsLoaded: (value: boolean) => void;
  pestRiskIsLoaded: boolean;
  setPestRiskIsLoaded: (value: boolean) => void;
  resetAll: () => void;
}

export const productCardInfoVoid = {
  id: undefined,
  name: undefined,
  residual_days: undefined
};

export const useRoutingPlanConfigurationStore = create<RoutingPlanConfigurationState>()(set => ({
  enableNextStep: false,
  setEnableNextStep: value => set(() => ({ enableNextStep: value })),
  selectedInspectionGroups: [],
  setSelectedInspectionGroups: value => set(() => ({ selectedInspectionGroups: value })),
  priorities: {} as PrioritiesState,
  setPriorities: value => set(() => ({ priorities: value })),
  selectedDays: [],
  setSelectedDays: value => set(() => ({ selectedDays: value })),
  assigneesGroups: [],
  setAssigneesGroups: value => set(() => ({ assigneesGroups: value })),
  assigneesGroupsProductivity: {} as AssigneesGroupProductivityState,
  setAssigneesGroupsProductivity: value => set(() => ({ assigneesGroupsProductivity: value })),
  assigneeMaxDistance: undefined,
  setAssigneeMaxDistance: value => set(() => ({ assigneeMaxDistance: value })),
  sampling: {} as SamplingState,
  setSampling: value => set(() => ({ sampling: value })),
  fieldPriorities: {} as Record<PrioritiesIds, LeafhopperPriorities>,
  setFieldPriorities: value => set(() => ({ fieldPriorities: value })),
  productsResidual: [productCardInfoVoid],
  setProductsResidual: value => set(() => ({ productsResidual: value })),
  pestRiskRules: {},
  setPestRiskRules: value => set(() => ({ pestRiskRules: value })),
  trapDistribution: {},
  setTrapDistribution: value => set(() => ({ trapDistribution: value })),
  showFieldClusterModal: false,
  setShowFieldClusterModal: value => set(() => ({ showFieldClusterModal: value })),
  fieldClusterPhenomenaIds: [],
  setFieldClusterPhenomenaIds: value => set(() => ({ fieldClusterPhenomenaIds: value })),
  previousFieldClusterIsEmpty: false,
  setPreviousFieldClusterIsEmpty: value => set(() => ({ previousFieldClusterIsEmpty: value })),
  currentPage: 0,
  setCurrentPage: value => set(() => ({ currentPage: value })),
  fieldClusterPhenomenaIdsLoaded: false,
  setFieldClusterPhenomenaIdsLoaded: value => set(() => ({ fieldClusterPhenomenaIdsLoaded: value })),
  pestRiskIsLoaded: false,
  setPestRiskIsLoaded: value => set(() => ({ pestRiskIsLoaded: value })),
  resetAll: () => {
    set(() => ({
      enableNextStep: false,
      selectedInspectionGroups: [],
      priorities: {} as PrioritiesState,
      selectedDays: [],
      assigneesGroups: [],
      assigneesGroupsProductivity: {} as AssigneesGroupProductivityState,
      sampling: {} as SamplingState,
      fieldPriorities: {} as Record<PrioritiesIds, LeafhopperPriorities>,
      productsResidual: [productCardInfoVoid],
      pestRiskRules: {},
      fieldClusterPhenomenaIds: [],
      previousFieldClusterIsEmpty: false,
      showFieldClusterModal: false,
      currentPage: 0,
      fieldClusterPhenomenaIdsLoaded: false,
      pestRiskIsLoaded: false
    }));
  }
}));
