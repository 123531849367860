import _ from 'lodash';
import type { EntityAdapter } from 'redux-ngrx-entity';
import { createEntityAdapter } from 'redux-ngrx-entity';
import { createSelector } from 'reselect';
import { selectSelectedProperty } from '../../core/core.reducer';
import type { UUID } from '../../core/utils/basic.models';
import type { AppState } from '../../redux/app-state';
import { WarehouseActionsTypes } from './warehouse.actions';
import type { Warehouse, WarehousePayloadReducer, WarehouseState } from './warehouse.models';

interface IActionWareshouse {
  type: string;
  payload: WarehousePayloadReducer;
}

function selectWarehouseUuid(warehouse: Warehouse): string {
  return warehouse.id;
}

const entity: EntityAdapter<Warehouse> = createEntityAdapter<Warehouse>({
  selectId: selectWarehouseUuid
});

export const initialState = entity.getInitialState<WarehouseState>({
  ids: [],
  entities: {},
  isLoading: 0,
  isLoadingCompanies: false,
  error: null,
  creating: false,
  transfering: false,
  list: {},
  stocks: {},
  removing: false,
  transactions: {},
  isLoadingTransactions: 0,
  isLoadingAllTransactions: false,
  lastVendor: null,
  creatingVendor: false,
  creatingEntry: false,
  lastEntry: null
});

export default (state = initialState, action: IActionWareshouse) => {
  switch (action.type) {
    case WarehouseActionsTypes.LOAD_WAREHOUSE:
      return {
        ...state,
        isLoadingCompanies: true
      };
    case WarehouseActionsTypes.LOAD_WAREHOUSE_SUCCESS:
      return {
        ...state,
        list: { ...state.list, [action.payload.id]: action.payload.warehouses },
        isLoadingCompanies: false,
        error: null
      };
    case WarehouseActionsTypes.LOAD_WAREHOUSE_FAILURE:
      return {
        ...state,
        isLoadingCompanies: false,
        error: action.payload
      };
    case WarehouseActionsTypes.LOAD_TRANSACTIONS_WAREHOUSE:
      return {
        ...state,
        isLoadingTransactions: state.isLoadingTransactions + 1
      };
    case WarehouseActionsTypes.LOAD_TRANSACTIONS_WAREHOUSE_SUCCESS:
      return {
        ...state,
        transactions: { ...state.transactions, [action.payload.id]: action.payload.warehouses },
        isLoadingTransactions: state.isLoadingTransactions - 1,
        error: null
      };
    case WarehouseActionsTypes.LOAD_TRANSACTIONS_WAREHOUSE_FAILURE:
      return {
        ...state,
        isLoadingTransactions: state.isLoadingTransactions - 1,
        error: action.payload
      };
    case WarehouseActionsTypes.LOAD_ALL_TRANSACTIONS_FROM_WAREHOUSES:
      return {
        ...state,
        isLoadingAllTransactions: true
      };
    case WarehouseActionsTypes.LOAD_ALL_TRANSACTIONS_FROM_WAREHOUSES_SUCCESS:
      return {
        ...state,
        transactions: { ...state.transactions, ...action.payload },
        isLoadingAllTransactions: false,
        error: null
      };
    case WarehouseActionsTypes.LOAD_ALL_TRANSACTIONS_FROM_WAREHOUSES_FAILURE:
      return {
        ...state,
        isLoadingAllTransactions: false,
        error: action.payload
      };
    case WarehouseActionsTypes.REMOVE_WAREHOUSE:
      return {
        ...state,
        removing: true,
        error: null
      };
    case WarehouseActionsTypes.REMOVE_WAREHOUSE_SUCCESS: {
      const tempList = state.list;
      let result;
      if (tempList[action.payload.companyId]) {
        result = Object.values(tempList[action.payload.companyId]).filter((item: { id: string }) => item.id !== action.payload.warehouseId);
      }
      return entity.removeOne(action.payload.warehouseId, {
        ...state,
        list: { ...state.list, [action.payload.companyId]: result },
        removing: false
      });
    }
    case WarehouseActionsTypes.REMOVE_WAREHOUSE_FAILURE:
      return {
        ...state,
        removing: false,
        error: action.payload
      };
    case WarehouseActionsTypes.LOAD_INDIVIDUAL_STOCK_WAREHOUSE:
      return {
        ...state,
        isLoading: state.isLoading + 1
      };
    case WarehouseActionsTypes.LOAD_INDIVIDUAL_STOCK_WAREHOUSE_SUCCESS:
      return {
        ...state,
        stocks: { ...state.stocks, [action.payload.id]: action.payload.warehouses },
        isLoading: state.isLoading - 1,
        error: null
      };
    case WarehouseActionsTypes.LOAD_INDIVIDUAL_STOCK_WAREHOUSE_FAILURE:
      return {
        ...state,
        isLoading: state.isLoading - 1,
        error: action.payload
      };
    case WarehouseActionsTypes.LOAD_COMPANY_STOCK_WAREHOUSE:
      return {
        ...state,
        isLoading: state.isLoading + 1
      };
    case WarehouseActionsTypes.LOAD_COMPANY_STOCK_WAREHOUSE_SUCCESS:
      return {
        ...state,
        stocks: { ...state.stocks, [action.payload.id]: action.payload.warehouses },
        isLoading: state.isLoading - 1,
        error: null
      };
    case WarehouseActionsTypes.LOAD_COMPANY_STOCK_WAREHOUSE_FAILURE:
      return {
        ...state,
        isLoading: state.isLoading - 1,
        error: action.payload
      };
    case WarehouseActionsTypes.LOAD_STOCK_WAREHOUSE:
      return {
        ...state,
        isLoading: state.isLoading + 1,
        error: null
      };
    case WarehouseActionsTypes.LOAD_STOCK_WAREHOUSE_SUCCESS:
      return entity.upsertMany(action.payload as unknown as Warehouse[], {
        ...state,
        isLoading: state.isLoading - 1,
        error: null
      });
    case WarehouseActionsTypes.LOAD_STOCK_WAREHOUSE_FAILURE:
      return entity.upsertMany([], {
        ...state,
        isLoading: state.isLoading - 1,
        error: true
      });
    case WarehouseActionsTypes.NEW_WAREHOUSE:
      return {
        ...state,
        creating: true,
        error: null
      };
    case WarehouseActionsTypes.NEW_WAREHOUSE_SUCCESS:
      return {
        ...state,
        creating: false
      };
    case WarehouseActionsTypes.NEW_WAREHOUSE_FAILURE:
      return {
        ...state,
        creating: false,
        error: action.payload
      };
    case WarehouseActionsTypes.UPDATE_WAREHOUSE:
      return {
        ...state,
        creating: true,
        error: null
      };
    case WarehouseActionsTypes.UPDATE_WAREHOUSE_SUCCESS:
      return {
        ...state,
        creating: false
      };
    case WarehouseActionsTypes.UPDATE_WAREHOUSE_FAILURE:
      return {
        ...state,
        creating: false,
        error: action.payload
      };
    case WarehouseActionsTypes.NEW_TRANSFER_WAREHOUSE:
      return {
        ...state,
        transfering: true,
        error: null
      };
    case WarehouseActionsTypes.NEW_TRANSFER_WAREHOUSE_SUCCESS:
      return {
        ...state,
        transfering: false
      };
    case WarehouseActionsTypes.NEW_TRANSFER_WAREHOUSE_FAILURE:
      return {
        ...state,
        transfering: false,
        error: action.payload
      };
    case WarehouseActionsTypes.NEW_VENDOR:
      return {
        ...state,
        creatingVendor: true,
        error: null
      };
    case WarehouseActionsTypes.NEW_VENDOR_SUCCESS:
      return {
        ...state,
        creatingVendor: false,
        lastVendor: action.payload
      };
    case WarehouseActionsTypes.NEW_VENDOR_FAILURE:
      return {
        ...state,
        creatingVendor: false,
        error: action.payload
      };
    case WarehouseActionsTypes.UPDATE_VENDOR:
      return {
        ...state,
        creatingVendor: true,
        error: null
      };
    case WarehouseActionsTypes.UPDATE_VENDOR_SUCCESS:
      return {
        ...state,
        creatingVendor: false,
        lastVendor: action.payload
      };
    case WarehouseActionsTypes.UPDATE_VENDOR_FAILURE:
      return {
        ...state,
        creatingVendor: false,
        error: action.payload
      };
    case WarehouseActionsTypes.NEW_ENTRY_WAREHOUSE:
      return {
        ...state,
        creatingEntry: true,
        lastVendor: null,
        error: null
      };
    case WarehouseActionsTypes.NEW_ENTRY_WAREHOUSE_SUCCESS:
      return {
        ...state,
        creatingEntry: false
      };
    case WarehouseActionsTypes.NEW_ENTRY_WAREHOUSE_FAILURE:
      return {
        ...state,
        creatingEntry: false,
        error: action.payload
      };
    case WarehouseActionsTypes.UPDATE_ENTRY_WAREHOUSE:
      return {
        ...state,
        creatingEntry: true,
        lastVendor: null,
        error: null
      };
    case WarehouseActionsTypes.UPDATE_ENTRY_WAREHOUSE_SUCCESS:
      return {
        ...state,
        creatingEntry: false
      };
    case WarehouseActionsTypes.UPDATE_ENTRY_WAREHOUSE_FAILURE:
      return {
        ...state,
        creatingEntry: false,
        error: action.payload
      };
    case WarehouseActionsTypes.GET_ENTRY_WAREHOUSE:
      return {
        ...state,
        lastEntry: null,
        error: null
      };
    case WarehouseActionsTypes.GET_ENTRY_WAREHOUSE_SUCCESS:
      return {
        ...state,
        lastEntry: action.payload
      };
    case WarehouseActionsTypes.GET_ENTRY_WAREHOUSE_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    default:
      return state;
  }
};

const { selectIds, selectEntities, selectAll, selectTotal } = entity.getSelectors();
export const selectWarehouseIds = selectIds;
export const selectWarehouseEntities = selectEntities;
export const selectAllWarehouses = selectAll;
export const selectWarehouseTotal = selectTotal;

export const getWarehouseByCurrentProperty = createSelector(
  (state: AppState) => selectAllWarehouses(state.entities.warehouse),
  (state: AppState) => selectSelectedProperty(state.uiState.global),
  (warehouses: Warehouse[], propertyId: UUID | null) => {
    if (!propertyId) return [];
    return warehouses.filter(w => w.properties?.includes(propertyId));
  }
);

export const getWarehouseDictionaryByCurrentProperty = createSelector(
  (state: AppState) => getWarehouseByCurrentProperty(state),
  (warehouses: Warehouse[]) => {
    return _.groupBy(warehouses, 'id');
  }
);
