export const RECOMMENDATION_NOTES_KEYS = {
  all: ['recommendation-notes'] as const,
  lists: () => [...RECOMMENDATION_NOTES_KEYS.all, 'list'] as const,
  list: (propertyId: string, seasonId: string) => [...RECOMMENDATION_NOTES_KEYS.lists(), propertyId, seasonId] as const
};

export const RECOMMENDATION_NOTES_MUTATION_KEYS = {
  all: ['recommendation-notes'] as const,
  create: () => [...RECOMMENDATION_NOTES_MUTATION_KEYS.all, 'create'] as const
};
