import { Drawer } from 'antdV5';
import { COLOR_OPTIONS, FONT_FAMILY } from 'config/style';
import styled, { css } from 'styled-components';

interface IStyledDrawer {
  centerContent?: boolean;
}

export const StyledDrawer = styled(Drawer).attrs({
  prefixCls: 'antd-v5'
})<IStyledDrawer>`
  .antd-v5-header {
    border-bottom: none;
  }

  .antd-v5-header-title {
    flex-direction: row-reverse;

    .st-typo--heading-5 {
      font-weight: 600;
      font-family: ${FONT_FAMILY};
    }
  }

  .antd-v5-close {
    color: ${COLOR_OPTIONS.NEUTRAL_60};
  }

  ${props =>
    props.centerContent &&
    css`
      .antd-v5-body {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `}
`;
