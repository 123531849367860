// Constants
const LOCAL_HOST = 'http://localhost:8080';
const CHUNK_ANALYTICS_RESULTS = 30;
const PRODUCT_PAGE = 6000;
const CHUNK_PAGE = 1000;
const TEN_MINUTES = 1000 * 60 * 10;
const RETRY = 3;
const MAX_AREAS_ITEMS_IN_AG_OP = 500;

// Environments
const ENV_CW_COMPONENTS = process.env.VITE_ENV_CW_COMPONENTS;
const VITE_ENV = process.env.VITE_ENV;

// Keys
const NEMADIGITAL_APP_ID = process.env.VITE_NEMADIGITAL_APP_ID ?? '';
const PROTECTOR_APP_ID = process.env.VITE_PROTECTOR_APP_ID;
const BALANCE_APP_ID = process.env.VITE_BALANCE_APP_ID;
const AUTH_SECRET_ID = process.env.VITE_AUTH_SECRET_ID;
const S3_BUCKET_NAME = process.env.VITE_S3_BUCKET_NAME;
const GRIS_API_KEY = process.env.VITE_GRIS_API_KEY;
const ROLLOUT_KEY = process.env.VITE_ROLLOUT_KEY;
const MAPBOX_KEY = process.env.VITE_MAPBOX_KEY;
const CLIENT_ID = process.env.VITE_CLIENT_ID;

// URLs
const CORE_SERVICES_API_URL = process.env.VITE_CORE_SERVICES_API_URL ?? LOCAL_HOST;
const PROTECTOR_API_URL = process.env.VITE_PROTECTOR_API_URL ?? LOCAL_HOST;
const API_REMOTE_SENSING_URL = process.env.VITE_API_REMOTE_SENSING_URL;
const OAUTH_CROPWISE_URL = process.env.VITE_OAUTH_CROPWISE_URL;
const NEMADIGITAL_URL = process.env.VITE_NEMADIGITAL_URL;
const BALANCE_URL = process.env.VITE_BALANCE_URL;
const OAUTH_UK_URL = process.env.VITE_OAUTH_UK_URL;
const CROPWISE_URL = process.env.VITE_CROPWISE_URL;
const SENTRY_URL = process.env.VITE_SENTRY_URL;
const CROPWISE_ELEMENTS_MFE_URL = process.env.VITE_CROPWISE_ELEMENTS_MFE_URL;

export {
  API_REMOTE_SENSING_URL,
  AUTH_SECRET_ID,
  BALANCE_APP_ID,
  BALANCE_URL,
  CHUNK_ANALYTICS_RESULTS,
  CHUNK_PAGE,
  CLIENT_ID,
  CORE_SERVICES_API_URL,
  CROPWISE_ELEMENTS_MFE_URL,
  CROPWISE_URL,
  ENV_CW_COMPONENTS,
  GRIS_API_KEY,
  MAPBOX_KEY,
  MAX_AREAS_ITEMS_IN_AG_OP,
  NEMADIGITAL_APP_ID,
  NEMADIGITAL_URL,
  OAUTH_CROPWISE_URL,
  OAUTH_UK_URL,
  PRODUCT_PAGE,
  PROTECTOR_API_URL,
  PROTECTOR_APP_ID,
  RETRY,
  ROLLOUT_KEY,
  S3_BUCKET_NAME,
  SENTRY_URL,
  TEN_MINUTES,
  VITE_ENV
};
