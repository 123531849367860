import type { UUID } from 'core/utils/basic.models';
import { getNameByCurrentLanguage } from 'core/utils/functions';
import { getPhenomena } from 'entities/phenomenon/phenomenon.service';
import { getMethodologiesByIds, getMethodologyIdsForPropertySeasonAndAreas } from 'entities/property/property.service';
import type { Region } from 'entities/region/region.models';
import type { StaticPoint } from 'entities/static-points/static-points.models';
import _ from 'lodash';
import moment from 'moment';
import type { TaskSprayPayload } from 'pages/tasks/task-create/tasks-create.models';
import type { Dictionary } from 'redux-ngrx-entity';
import type { Phenomenon } from '../../entities/phenomenon/phenomenon.models';
import type { IndicatorAverage } from './control-strategy-card-list/control-strategy-card-region.model';
import type { ControlStrategySuggestion, ControlStrategySuggestionCard, SelectedSuggestionsPerStrategy } from './control-strategy-model';
import { ControlStrategyOptionsEnum } from './control-strategy-model';
import { getStaticPointTemplates } from './control-strategy.service';

interface ControlStrategyFrameColor {
  colorFill: string;
  colorStroke: string;
  colorBorder: string;
  colorBorderStroke: string;
  colorFillDark: string;
  colorText: string;
}

const colorFillList = ['#F6F2FE', '#FFF3DD', '#E0F9F7', '#FFF1ED', '#EAF6FF', '#FFF1F3'];
const colorStrokeList = ['#E7DDFC', '#FFE4AE', '#A9EFE8', '#FFDACE', '#C6E6FF', '#FFD8DF'];
const colorBorderList = ['#9664F0', '#744A0B', '#2B9C92', '#FF785A', '#0092E4', '#E85D78'];
const colorBorderStrokeList = ['#9664F0', '#744A0B', '#2B9C92', '#FF785A', '#0092E4', '#E85D78'];
const colorFillDarkList = ['#8354D6', '#744A0B', '#165C56', '#FF785A', '#0092E4', '#E85D78'];
const colorTextList = ['#643AAA', '#744A0B', '#165C56', '#FF785A', '#0092E4', '#E85D78'];
export const controlStrategyFrameColors: ControlStrategyFrameColor[] = colorFillList.map((colorFill, index) => ({
  colorFill,
  colorStroke: colorStrokeList[index],
  colorBorder: colorBorderList[index],
  colorBorderStroke: colorBorderStrokeList[index],
  colorFillDark: colorFillDarkList[index],
  colorText: colorTextList[index]
}));

export const groupSuggestionsPerZone = (
  suggestionsPerZone: Record<string, Record<string, ControlStrategySuggestionCard>>,
  suggestion: ControlStrategySuggestion
): Record<string, Record<string, ControlStrategySuggestionCard>> => {
  const regionDayKey = `${suggestion.group_id}_${suggestion.events_day}`;
  const isFirstInput = !suggestionsPerZone[regionDayKey]?.[suggestion.control_strategy_id];

  const targets = isFirstInput ? [] : suggestionsPerZone[regionDayKey][suggestion.control_strategy_id].targets;
  const previousSelectedTargets = isFirstInput ? [] : suggestionsPerZone[regionDayKey][suggestion.control_strategy_id].selectedTargets;
  const suggestions = isFirstInput ? [] : suggestionsPerZone[regionDayKey][suggestion.control_strategy_id].suggestions_id;
  const highlighted = isFirstInput ? false : suggestionsPerZone[regionDayKey][suggestion.control_strategy_id].highlighted;

  const selected = isFirstInput ? false : suggestionsPerZone[regionDayKey][suggestion.control_strategy_id].selected;
  const selectedTargets = suggestion.selected ? [suggestion.target_id] : [];
  return {
    ...suggestionsPerZone,
    [regionDayKey]: {
      ...suggestionsPerZone[regionDayKey],
      [suggestion.control_strategy_id]: {
        suggestions_id: [...suggestions, suggestion.id],
        targets: suggestion.forced ? targets : [...new Set([...targets, suggestion.target_id])],
        selectedTargets: [...new Set([...previousSelectedTargets, ...selectedTargets])],
        valid_start_date: suggestion.valid_start_date,
        valid_end_date: suggestion.valid_end_date,
        products: [],
        group_id: suggestion.group_id,
        control_strategy_id: suggestion.control_strategy_id,
        acknowledged: suggestion.acknowledged,
        created_at: suggestion.created_at,
        updated_at: suggestion.updated_at,
        updated_by: suggestion.updated_by,
        deleted_at: suggestion.deleted_at,
        selected: selected || suggestion.selected,
        events_day: suggestion.events_day,
        highlighted: highlighted ?? !!suggestion.highlighted
      }
    }
  };
};

const mergeIndicatorsByPhenomenon = (
  indicatorsByPhenomenon: Record<string, string[]>,
  phenomenonIds: string[],
  indicatorIds: string[]
): Record<string, string[]> => {
  return {
    ...indicatorsByPhenomenon,
    ...phenomenonIds.reduce((phenomenaAcc: Record<string, string[]>, phenomenonId: string) => {
      let idsToAppend = indicatorIds;
      if (phenomenonId in phenomenaAcc) {
        idsToAppend = [...idsToAppend, ...phenomenaAcc[phenomenonId]];
      }
      if (phenomenonId in indicatorsByPhenomenon) {
        idsToAppend = [...idsToAppend, ...indicatorsByPhenomenon[phenomenonId]];
      }
      return {
        ...phenomenaAcc,
        [phenomenonId]: [...new Set(idsToAppend)]
      };
    }, {})
  };
};

const getPhenomenonIdsByStaticPoint = (propertyId: UUID): Promise<Record<string, string[]>> => {
  return getStaticPointTemplates(propertyId)
    .toPromise()
    .then(response => {
      const templates: StaticPoint[] = response.data;
      return templates.reduce((indicatorsByPhenomenon, template) => {
        const indicatorIds = template.static_point.analytic_context?.custom_indicator_dtos.map(indicator => indicator.indicator_id) ?? [];
        const phenomenonIds =
          template.static_point.inspection_layout?.ordered_inspection_groups.flatMap(group =>
            group.ordered_categories.flatMap(category => category.ordered_phenomenons.map(phenomenon => phenomenon.id))
          ) ?? [];
        return mergeIndicatorsByPhenomenon(indicatorsByPhenomenon, phenomenonIds, indicatorIds);
      }, {});
    });
};

const getPhenomenonIdsByMethodology = (propertyId: UUID, seasonIds: UUID[], areaIds: UUID[]): Promise<Record<string, string[]>> => {
  return getMethodologyIdsForPropertySeasonAndAreas(propertyId, seasonIds, areaIds)
    .toPromise()
    .then(methodologyIds => {
      return getMethodologiesByIds(methodologyIds)
        .toPromise()
        .then(methodologies => {
          return methodologies.reduce((indicatorsByPhenomenon, methodology) => {
            const indicatorIds = methodology.analytic_context_dto.custom_indicator_dtos.map(indicator => indicator.indicator_id);
            const phenomenonIds = methodology.inspection_layout.ordered_inspection_groups.flatMap(group =>
              group.ordered_categories.flatMap(category => category.ordered_phenomenons.map(phenomenon => phenomenon.component_id))
            );
            return mergeIndicatorsByPhenomenon(indicatorsByPhenomenon, phenomenonIds, indicatorIds);
          }, {});
        });
    });
};

export const getControlStrategyPhenomena = (
  companyId: UUID,
  propertyId: UUID,
  seasonIds: UUID[],
  areaIds: UUID[]
): Promise<Phenomenon[]> => {
  return Promise.all([
    getPhenomena(companyId).then(response => {
      return response;
    }),
    getPhenomenonIdsByMethodology(propertyId, seasonIds, areaIds),
    getPhenomenonIdsByStaticPoint(propertyId)
  ]).then(([phenomena, methodologyIndicatorsByPhenomenon, staticPointIndicatorsByPhenomenon]): Phenomenon[] => {
    const indicatorsByPhenomenon = Object.entries(staticPointIndicatorsByPhenomenon).reduce((acc, [phenomenonId, indicatorIds]) => {
      const accIndicators = acc[phenomenonId] ? [...acc[phenomenonId]] : [];
      const newIndicatorIds = [...new Set([...indicatorIds, ...accIndicators])];
      return {
        ...acc,
        [phenomenonId]: newIndicatorIds
      };
    }, methodologyIndicatorsByPhenomenon);
    const allPhenomena = phenomena.reduce((acc: Phenomenon[], phenomenon) => {
      if (phenomenon.id in indicatorsByPhenomenon) {
        const indicatorIds = indicatorsByPhenomenon[phenomenon.id];
        const filteredIndicators = phenomenon.indicators_dto
          .filter(indicator => indicatorIds.includes(indicator.id))
          .sort((indicatorA, indicatorB) =>
            getNameByCurrentLanguage(indicatorA.name).localeCompare(getNameByCurrentLanguage(indicatorB.name))
          );
        return [
          ...acc,
          {
            ...phenomenon,
            indicators: filteredIndicators.map(indicator => indicator.id),
            indicators_dto: filteredIndicators
          }
        ];
      }
      return acc;
    }, []);

    return [...allPhenomena].sort((indicatorA, indicatorB) =>
      getNameByCurrentLanguage(indicatorA.name).localeCompare(getNameByCurrentLanguage(indicatorB.name))
    );
  });
};

export const wasSuggestionSelected = (
  suggestion: ControlStrategySuggestionCard,
  selectedSuggestions: SelectedSuggestionsPerStrategy
): boolean => {
  return (
    !!selectedSuggestions &&
    !!selectedSuggestions[suggestion.control_strategy_id] &&
    (!!selectedSuggestions[suggestion.control_strategy_id].suggestionsIds.length ||
      !!selectedSuggestions[suggestion.control_strategy_id].forcedTargetsIds.length)
  );
};

const getSuggestionTargets = (suggestion: ControlStrategySuggestionCard, suggestionIds: string[]) => {
  const targets: string[] = [];
  suggestionIds.forEach(suggestionId => {
    const suggestionIndex = suggestion.suggestions_id.findIndex(sId => sId === suggestionId);
    if (suggestionIndex >= 0 && suggestion.targets[suggestionIndex]) {
      targets.push(suggestion.targets[suggestionIndex]);
    }
  });
  return targets;
};

const getSuggestionAreas = (
  region: Region,
  suggestion: ControlStrategySuggestionCard,
  acked: boolean | undefined,
  selectedSuggestions: SelectedSuggestionsPerStrategy
) => {
  if (!region || !suggestion) {
    return [];
  }
  if (acked) {
    return suggestion.selectedTargets.length ? suggestion.selectedTargets : suggestion.targets;
  }
  if (wasSuggestionSelected(suggestion, selectedSuggestions)) {
    const selectedSuggestion = selectedSuggestions[suggestion.control_strategy_id];
    const suggestedTargets = getSuggestionTargets(suggestion, selectedSuggestion.suggestionsIds);
    const targetstIds = [...suggestedTargets, ...selectedSuggestion.forcedTargetsIds];
    return [...new Set(targetstIds)];
  }
  return suggestion.targets.length > region.children.length ? (region.children as string[]) : suggestion.targets;
};

export const getSuggestionAreasData = (
  region: Region,
  suggestion: ControlStrategySuggestionCard,
  acked: boolean | undefined,
  selectedSuggestions: SelectedSuggestionsPerStrategy,
  regions: Dictionary<Region>
) => {
  const suggestionAreas = getSuggestionAreas(region, suggestion, acked, selectedSuggestions);
  const suggestionAreasCount = suggestionAreas.length;
  const suggestionAreasTotalSize = suggestionAreas.reduce<number>((acc, areaId) => {
    const field = regions[areaId];
    return acc + (field?.total_area ?? 0);
  }, 0);
  return { suggestionAreasTotalSize, suggestionAreasCount, suggestionAreas };
};

const colorDamageMap: Record<string, number> = {
  '#696F88': -1,
  '#19A04B': 0,
  '#F0C355': 1,
  '#EB4B4B': 2
};

export const sortResultsByDamage = (indicatorA: IndicatorAverage, indicatorB: IndicatorAverage): number => {
  return colorDamageMap[indicatorB.color] - colorDamageMap[indicatorA.color];
};

export const isAfterToday = (date: moment.Moment | null): boolean => {
  return !!date && date > moment();
};

export const sortAveragesBySeverity = (indicatorA: IndicatorAverage[], indicatorB: IndicatorAverage[]): number => {
  return Math.max(...indicatorB.map(o => colorDamageMap[o.color])) - Math.max(...indicatorA.map(o => colorDamageMap[o.color]));
};

export const getForceSuggestionOption = (
  hideForceSuggestion: boolean,
  controlStrategyForceSuggestion: ControlStrategyOptionsEnum
): ControlStrategyOptionsEnum => {
  if (!hideForceSuggestion) {
    return ControlStrategyOptionsEnum.ALWAYS_ASK;
  }
  return controlStrategyForceSuggestion;
};

export const getForcedTargets = (region: Region, suggestion: ControlStrategySuggestionCard): string[] => {
  return (region.children as string[]).filter(areaId => !suggestion.targets.includes(areaId));
};

export const getSuggestionIds = (
  selectedSuggestions: Record<string, SelectedSuggestionsPerStrategy>,
  suggestion: ControlStrategySuggestionCard
): string[] => {
  const regionEventDay = selectedSuggestions[`${suggestion.group_id}_${suggestion.events_day}`];
  if (!!regionEventDay && !!regionEventDay[suggestion.control_strategy_id]) {
    return [...regionEventDay[suggestion.control_strategy_id].suggestionsIds, ...suggestion.suggestions_id];
  }
  return suggestion.suggestions_id;
};

const groupSpraysByProductUsage = (spray: TaskSprayPayload) => {
  return [...spray.spray_definition.product_usages]
    .sort((productA, productB) => productA.product_id.localeCompare(productB.product_id))
    .reduce((producutUsageKey, productUsage) => {
      return `${producutUsageKey}-${productUsage.concentration}_${productUsage.product_id}_${spray.start_at.substring(0, 10)}`;
    }, '');
};

const mapGroupedSprays = (sprays: TaskSprayPayload[]) => {
  return {
    ...sprays[0],
    spray_definition: {
      ...sprays[0].spray_definition,
      sprayed_areas: sprays.flatMap(s => s.spray_definition.sprayed_areas)
    }
  };
};
export const mergeSpraysWithSameProductUsage = (sprays: TaskSprayPayload[] | undefined): TaskSprayPayload[] => {
  if (!sprays) {
    return [];
  }
  const spraysGrouped = Object.values(_.groupBy(sprays, groupSpraysByProductUsage));
  return spraysGrouped.map(mapGroupedSprays);
};

export const getAckSuggestionOptionFromString = (option: string): ControlStrategyOptionsEnum => {
  switch (option) {
    case 'ALL_AREAS':
      return ControlStrategyOptionsEnum.ALL_AREAS;
    case 'SELECT_INDIVIDUALLY':
      return ControlStrategyOptionsEnum.SELECT_INDIVIDUALLY;
    case 'SUGGESTED_AREAS':
      return ControlStrategyOptionsEnum.SUGGESTED_AREAS;
    default:
      return ControlStrategyOptionsEnum.ALWAYS_ASK;
  }
};

export const clearRegionSelectedSuggestions = (
  regionKey: string,
  strategyId: string,
  selectedSuggestions: Record<string, SelectedSuggestionsPerStrategy>
) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { [strategyId]: removed, ...newStrategies } = selectedSuggestions[regionKey];
  return { ...selectedSuggestions, [regionKey]: newStrategies };
};

export const handleUpdateSelectedSuggestion = (
  regionKey: string,
  strategyId: string,
  suggestionsIds: UUID[],
  forcedTargetsIds: UUID[],
  selectedSuggestions: Record<string, SelectedSuggestionsPerStrategy>
) => {
  return {
    ...selectedSuggestions,
    [regionKey]: {
      ...selectedSuggestions[regionKey],
      [strategyId]: {
        suggestionsIds,
        forcedTargetsIds
      }
    }
  };
};

export const buildSuggestionList = (
  selectedSuggestions: SelectedSuggestionsPerStrategy,
  regionSuggestionsByEventsDay: ControlStrategySuggestionCard[]
) => {
  if (selectedSuggestions) {
    const suggestionsSplittedBySelected = _.partition(regionSuggestionsByEventsDay, suggestion =>
      wasSuggestionSelected(suggestion, selectedSuggestions)
    );
    return [...suggestionsSplittedBySelected[0], ...suggestionsSplittedBySelected[1]];
  } else {
    return regionSuggestionsByEventsDay;
  }
};
