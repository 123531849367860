import { CropTypesEnum } from 'core/core.models';
import type { Crop } from 'core/utils/basic.models';
import { slugify } from 'core/utils/strings';
import _ from 'lodash';

export function isAnyWheatCrop(crop: Crop | undefined | null): boolean {
  if (!crop?.wk_slug) {
    return false;
  }
  const cropType = _.snakeCase(crop.wk_slug.toLocaleLowerCase());

  /**
   * This casting was necessary to work with the generic Crop model
   * which doesn't know the crop names, and the CropTypesEnum internally
   */
  return [CropTypesEnum.WINTER_WHEAT, CropTypesEnum.HARD_WHEAT, CropTypesEnum.WHEAT].includes(cropType as CropTypesEnum);
}

export function isAnyBarleyCrop(crop: Crop | undefined | null, shouldIncludeSpringBarley = false): boolean {
  if (!crop?.wk_slug) {
    return false;
  }
  const cropType = _.snakeCase(crop.wk_slug.toLocaleLowerCase());
  /**
   * This casting was necessary to work with the generic Crop model
   * which doesn't know the crop names, and the CropTypesEnum internally
   */
  return _.compact<CropTypesEnum>([
    CropTypesEnum.WINTER_BARLEY,
    shouldIncludeSpringBarley && CropTypesEnum.SPRING_BARLEY,
    CropTypesEnum.BARLEY
  ]).includes(cropType as CropTypesEnum);
}

export const isAnyCerealCrop = (crop: Crop | undefined | null, shouldIncludeSpringBarley = false): boolean =>
  isAnyBarleyCrop(crop, shouldIncludeSpringBarley) || isAnyWheatCrop(crop);

export function isGrapeCrop(crop: Crop | undefined | null): boolean {
  if (!crop?.wk_slug) {
    return false;
  }
  const cropType = _.snakeCase(crop.wk_slug.toLocaleLowerCase());
  return cropType === CropTypesEnum.GRAPE.toString();
}

export function isPotatoCrop(crop: Crop | undefined | null): boolean {
  if (!crop?.wk_slug) {
    return false;
  }
  const cropType = slugify(crop.wk_slug);
  return cropType === CropTypesEnum.POTATO.toString();
}

export function isSoybeanCrop(crop: Crop | undefined | null): boolean {
  if (!crop?.wk_slug) {
    return false;
  }
  const cropType = slugify(crop.wk_slug);
  return cropType === CropTypesEnum.SOYBEANS.toString() || cropType === CropTypesEnum.SOYBEAN.toString();
}

export function isBrachiariaCrop(crop: Crop | undefined | null): boolean {
  if (!crop?.wk_slug) {
    return false;
  }
  const cropType = slugify(crop.wk_slug);
  return cropType === CropTypesEnum.BRACHIARIA.toString();
}

export function isAcerolaCrop(crop: Crop | undefined | null): boolean {
  if (!crop?.wk_slug) {
    return false;
  }
  const cropType = slugify(crop.wk_slug);
  return cropType === CropTypesEnum.ACEROLA.toString();
}

export function isAlfalfaCrop(crop: Crop | undefined | null): boolean {
  if (!crop?.wk_slug) {
    return false;
  }
  const cropType = slugify(crop.wk_slug);
  return cropType === CropTypesEnum.ALFALFA.toString();
}

export function isAppleCrop(crop: Crop | undefined | null): boolean {
  if (!crop?.wk_slug) {
    return false;
  }
  const cropType = slugify(crop.wk_slug);
  return cropType === CropTypesEnum.APPLE.toString();
}

export function isAsparagusCrop(crop: Crop | undefined | null): boolean {
  if (!crop?.wk_slug) {
    return false;
  }
  const cropType = slugify(crop.wk_slug);
  return cropType === CropTypesEnum.ASPARAGUS.toString();
}

export function isAtemoyaCrop(crop: Crop | undefined | null): boolean {
  if (!crop?.wk_slug) {
    return false;
  }
  const cropType = slugify(crop.wk_slug);
  return cropType === CropTypesEnum.ATEMOYA.toString();
}

export function isAvocadoCrop(crop: Crop | undefined | null): boolean {
  if (!crop?.wk_slug) {
    return false;
  }
  const cropType = slugify(crop.wk_slug);
  return cropType === CropTypesEnum.AVOCADO.toString();
}

export function isBananaCrop(crop: Crop | undefined | null): boolean {
  if (!crop?.wk_slug) {
    return false;
  }
  const cropType = slugify(crop.wk_slug);
  return cropType === CropTypesEnum.BANANA.toString();
}

export function isBeanCrop(crop: Crop | undefined | null): boolean {
  if (!crop?.wk_slug) {
    return false;
  }
  const cropType = slugify(crop.wk_slug);
  return cropType === CropTypesEnum.BEAN.toString();
}

export function isBlueberryCrop(crop: Crop | undefined | null): boolean {
  if (!crop?.wk_slug) {
    return false;
  }
  const cropType = slugify(crop.wk_slug);
  return cropType === CropTypesEnum.BLUEBERRY.toString();
}

export function isCherryCrop(crop: Crop | undefined | null): boolean {
  if (!crop?.wk_slug) {
    return false;
  }
  const cropType = slugify(crop.wk_slug);
  return cropType === CropTypesEnum.CHERRY.toString();
}

export function isCoffeeBeanCrop(crop: Crop | undefined | null): boolean {
  if (!crop?.wk_slug) {
    return false;
  }
  const cropType = slugify(crop.wk_slug);
  return cropType === CropTypesEnum.COFFEE_BEAN.toString() || cropType === CropTypesEnum.COFFEE.toString();
}

export function isCornCrop(crop: Crop | undefined | null): boolean {
  if (!crop?.wk_slug) {
    return false;
  }
  const cropType = slugify(crop.wk_slug);

  return cropType === CropTypesEnum.CORN.toString() || cropType === CropTypesEnum.CORN_MAIZ.toString();
}

export function isCottonCrop(crop: Crop | undefined | null): boolean {
  if (!crop?.wk_slug) {
    return false;
  }
  const cropType = slugify(crop.wk_slug);
  return cropType === CropTypesEnum.COTTON.toString();
}

export function isLemonCrop(crop: Crop | undefined | null): boolean {
  if (!crop?.wk_slug) {
    return false;
  }
  const cropType = slugify(crop.wk_slug);
  return cropType === CropTypesEnum.LEMON.toString();
}

export function isOilseedRapeCrop(crop: Crop | undefined | null): boolean {
  if (!crop?.wk_slug) {
    return false;
  }
  const cropType = slugify(crop.wk_slug);
  return cropType === CropTypesEnum.OILSEED_RAPE.toString();
}

export function isOnionCrop(crop: Crop | undefined | null): boolean {
  if (!crop?.wk_slug) {
    return false;
  }
  const cropType = slugify(crop.wk_slug);
  return cropType === CropTypesEnum.ONION.toString();
}

export function isOrangeCrop(crop: Crop | undefined | null): boolean {
  if (!crop?.wk_slug) {
    return false;
  }
  const cropType = slugify(crop.wk_slug);
  return cropType === CropTypesEnum.ORANGE.toString();
}

export function isRiceCrop(crop: Crop | undefined | null): boolean {
  if (!crop?.wk_slug) {
    return false;
  }
  const cropType = slugify(crop.wk_slug);
  return cropType === CropTypesEnum.RICE.toString();
}

export function isSugarcaneCrop(crop: Crop | undefined | null): boolean {
  if (!crop?.wk_slug) {
    return false;
  }
  const cropType = slugify(crop.wk_slug);
  return cropType === CropTypesEnum.SUGARCANE.toString();
}

export function isSunflowerCrop(crop: Crop | undefined | null): boolean {
  if (!crop?.wk_slug) {
    return false;
  }
  const cropType = slugify(crop.wk_slug);
  return cropType === CropTypesEnum.SUNFLOWER.toString();
}

export function isTomatoCrop(crop: Crop | undefined | null): boolean {
  if (!crop?.wk_slug) {
    return false;
  }
  const cropType = slugify(crop.wk_slug);
  return cropType === CropTypesEnum.TOMATO.toString();
}

export function isTurnipCrop(crop: Crop | undefined | null): boolean {
  if (!crop?.wk_slug) {
    return false;
  }
  const cropType = slugify(crop.wk_slug);
  return cropType === CropTypesEnum.TURNIP.toString();
}

export function isWatermelonCrop(crop: Crop | undefined | null): boolean {
  if (!crop?.wk_slug) {
    return false;
  }
  const cropType = slugify(crop.wk_slug);
  return cropType === CropTypesEnum.WATERMELON.toString();
}
