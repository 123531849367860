import type { UseQueryResult } from '@tanstack/react-query';
import { useMutation, useQuery } from '@tanstack/react-query';
import { RECOMMENDATION_NOTES_KEYS, RECOMMENDATION_NOTES_MUTATION_KEYS } from './disease-risk.constant';
import { getRecommendationNotes, postRecommendationNotes } from './disease-risk.recommendation-notes.service';
import type { IResponseGetRecommendationNotes, PostRecommendationNotesParams } from './disease-risk.type';

export const useGetRecommendationNotes = (propertyId: string, seasonId: string): UseQueryResult<IResponseGetRecommendationNotes[]> => {
  return useQuery({
    queryKey: RECOMMENDATION_NOTES_KEYS.list(propertyId, seasonId),
    queryFn: () => getRecommendationNotes(propertyId, seasonId),
    enabled: Boolean(propertyId && seasonId)
  });
};

export const usePostRecommendationNotes = () => {
  return useMutation({
    mutationKey: RECOMMENDATION_NOTES_MUTATION_KEYS.create(),
    mutationFn: ({ propertyId, note }: PostRecommendationNotesParams) => postRecommendationNotes(propertyId, note)
  });
};
