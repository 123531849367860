import * as React from 'react';

type PaperPencilIconProps = Readonly<
  {
    fill?: string;
  } & React.SVGProps<SVGSVGElement>
>;

function PaperPencilIconComponent({ fill = 'currentColor', ...props }: PaperPencilIconProps) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 25 24' fill={fill} {...props}>
      <path d='M21.14 11.63l.71-.71a.996.996 0 011.41 0l.71.71c.39.39.39 1.02 0 1.41l-.71.71-2.12-2.12zm-.71.71l-5.3 5.3v2.12h2.12l5.3-5.3-2.12-2.12zM17.734 4.503c0-.563-.44-1.02-.981-1.02h-9.81c-.542 0-.981.457-.981 1.02v14.29c0 .563.44 1.02.98 1.02h4.906c.542 0 .98.457.98 1.02 0 .564-.438 1.021-.98 1.021H6.943C5.318 21.854 4 20.484 4 18.792V4.503c0-1.69 1.318-3.062 2.943-3.062h9.81c1.625 0 2.943 1.371 2.943 3.062v5.103c0 .564-.44 1.021-.981 1.021-.542 0-.981-.457-.981-1.02V4.502z' />
      <rect x={7.924} y={6.545} width={7.848} height={2.041} rx={1} />
      <rect x={7.924} y={10.627} width={6.643} height={2.041} rx={1} />
      <rect x={7.924} y={14.709} width={5.019} height={2.041} rx={1} />
    </svg>
  );
}

const PaperPencilIcon = React.memo(PaperPencilIconComponent);
export default PaperPencilIcon;
