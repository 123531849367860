import STTypo from 'core/shared/typo';
import { useTranslation } from 'react-i18next';
import { BalanceIcon } from 'st-protector-icons/Minor/Balance';
import { OpenInNewIcon } from 'st-protector-icons/Minor/OpenInNew';

import * as Styled from './open-in-balance.styles';

const OpenInBalance = () => {
  const { t } = useTranslation();
  return (
    <div>
      <Styled.Divider />
      <Styled.Wrapper>
        <BalanceIcon />
        <STTypo>{t('pages.side_menu.open_in_balance')}</STTypo>
        <OpenInNewIcon />
      </Styled.Wrapper>
    </div>
  );
};

export default OpenInBalance;
