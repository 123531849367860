import type { AxiosResponse } from 'axios';
import axios from 'axios-observable';
import { PROTECTOR_API_URL } from 'config/constants';
import type { Dictionary } from 'config/types';
import { serializeParamsArrayRepeat } from 'core/utils/params-serializer';
import { getDaysIndicators } from 'entities/indicators/indicators.service';
import type { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import type { UUID } from '../../../utils/basic.models';
import type { PostSettingsProps, WidgetId, WidgetRequest } from './widgets.models';
import { EWidgets } from './widgets.models';

const protectorApiUrl = `${PROTECTOR_API_URL}/`;

const defaultHeaders = {
  // Axios ovewrite Content-Type if we dont send some data 🤷🏽‍
  data: [],
  headers: {
    'Content-Type': 'application/json'
  }
};

/* manipulate the responses from back-end */
export const handleResponse = <T>(response: AxiosResponse<T[] | Dictionary<T>>, backendObjectName: string | undefined) => {
  if (backendObjectName) {
    (response.data as unknown) = (response.data as Dictionary<T>)[backendObjectName];
  }

  return response;
};

// We need this handler because backend returns {"[generic]": array} instead of array sometimes
const loadGenericWidgetUsingGet = <T>(
  endpoint: string,
  backendObjectName: string | undefined,
  request: WidgetRequest,
  selectedSeasons?: UUID[]
) => {
  return axios
    .get<T[]>(`${protectorApiUrl}${endpoint}`, {
      ...defaultHeaders,
      params: { ...request, properties_ids: undefined, season_ids: selectedSeasons ? selectedSeasons.join(',') : '' }
    })
    .pipe(map(response => handleResponse<T>(response, backendObjectName)));
};

export const loadProductsType = <T>(
  endpoint: string,
  backendObjectName: string | undefined,
  request: WidgetRequest,
  selectedSeasons?: string[]
) => {
  const propertyId = request?.properties_ids?.[0];

  if (!propertyId) return;

  const params: Record<string, string | string[]> = {
    start: request.start,
    end: request.end
  };

  if (selectedSeasons && selectedSeasons.length > 0) {
    params.season_ids = selectedSeasons;
  }

  return axios
    .get<T[]>(`${protectorApiUrl}${endpoint}/${propertyId}/summary`, {
      ...defaultHeaders,
      params: params,
      paramsSerializer: serializeParamsArrayRepeat
    })
    .pipe(map(response => handleResponse<T>(response, backendObjectName)));
};

const loadGenericWidgetUsingPost = <T>(
  endpoint: string,
  backendObjectName: string | undefined,
  request: WidgetRequest,
  selectedSeasons?: UUID[]
) => {
  return axios
    .post<T[]>(
      `${protectorApiUrl}${endpoint}`,
      { ...request, uuid: undefined, season_ids: selectedSeasons ?? [] },
      {
        ...defaultHeaders
      }
    )
    .pipe(map(response => handleResponse<T>(response, backendObjectName)));
};

export const loadWidget = <T>(
  widgetId: WidgetId,
  request: WidgetRequest,
  selectedSeasons?: UUID[],
  flagSplitRequestDaysIndicators = false
) => {
  /* after treate each case of response, selectet by switch each widget,
  appling endpoint, backendObjectName and method */
  let endpoint;
  let backendObjectName: string | undefined;
  let method: string;
  let customHandleGet: Observable<unknown> | undefined;

  switch (widgetId) {
    case EWidgets.INDIVIDUAL_SCOUTER:
      endpoint = 'v1/monitoring/scouter-performance-summary';
      backendObjectName = 'technician_performance_infos';
      method = 'get';
      break;
    case EWidgets.SCOUTER_DISTANCE_TIME_AVG:
      endpoint = 'v1/monitoring/scouter-stats';
      backendObjectName = 'scouter_distance_and_time_avg_list';
      method = 'get';
      break;
    case EWidgets.MOST_COMMON_ISSUES_PROPERTY_REPORT:
      endpoint = 'v1/monitoring/property-most-common-issues';
      backendObjectName = 'most_common_issues_infos';
      method = 'get';
      break;
    case EWidgets.MONITORING_KPI:
      endpoint = 'v1/monitoring/property-info';
      method = 'get';
      break;
    case EWidgets.SCOUTER_PERFORMANCE_OVER_TIME:
      endpoint = 'v1/monitoring/scouter-daily-performance';
      backendObjectName = 'technician_daily_performance_infos';
      method = 'post';
      break;
    case EWidgets.MONITORING:
      endpoint = 'v1/monitoring/properties-info?allow_past_seasons=true';
      backendObjectName = 'property_collection_summary_list';
      method = 'post';
      break;
    case EWidgets.TASKS:
      endpoint = 'v1/task/properties-status';
      backendObjectName = 'properties_task_summary';
      method = 'post';
      break;
    case EWidgets.TASKS_PROPERTY:
      endpoint = 'v1/task/property-status';
      method = 'get';
      break;
    case EWidgets.COMPANY_WEATHER:
      endpoint = 'v1/weather';
      method = 'get';
      break;
    case EWidgets.SPRAYS:
      endpoint = 'v1/spray/properties-summary';
      method = 'post';
      break;
    case EWidgets.PRODUCT_TYPES:
      endpoint = 'v1/sprays/properties';
      method = 'get';
      break;
    case EWidgets.DURATION_DAMAGE:
      endpoint = 'v1/monitoring/duration-of-damage-summary';
      backendObjectName = 'duration_of_damage_resumes';
      method = 'post';
      break;
    case EWidgets.HEATMAP_OVER_TIME:
      if (flagSplitRequestDaysIndicators) {
        customHandleGet = getDaysIndicators(
          {
            start_date: request.start,
            end_date: request.end,
            propertyId: request.properties_ids ? request.properties_ids[0] : '',
            season_ids: selectedSeasons ?? []
          },
          false
        ).pipe(
          map(daysIndicators => {
            return { data: daysIndicators.indicator };
          })
        );
      }
      endpoint = 'v1/timeline/days-indicators';
      method = 'get';
      break;
    case EWidgets.ABANDONED_AREAS:
      endpoint = 'v1/monitoring/property-abandoned-areas';
      method = 'get';
      break;
    case EWidgets.TASKS_LIST:
      endpoint = 'v1/task/property-tasks';
      method = 'get';
      break;
    default:
      throw new Error('Widget not found');
  }
  if (method === 'get' && widgetId !== EWidgets.PRODUCT_TYPES) {
    return customHandleGet ?? loadGenericWidgetUsingGet<T>(endpoint, backendObjectName, request, selectedSeasons);
  }
  if (method === 'get' && widgetId === EWidgets.PRODUCT_TYPES) {
    return loadProductsType<T>(endpoint, backendObjectName, request, selectedSeasons);
  }
  return loadGenericWidgetUsingPost<T>(endpoint, backendObjectName, request, selectedSeasons);
};

export const loadSettingsMock = () => {
  return axios.get(`${protectorApiUrl}v1/analytics/dashboard?pageable.page=0&pageable.size=32&t.sort.direction=ASC`);
};

export const postSettingsMock = (data: PostSettingsProps) => {
  return axios.post(`${protectorApiUrl}v1/analytics/dashboard`, data);
};
