import styled from 'styled-components';

export const SeasonReportStyledContainer = styled.div`
  display: grid;
  gap: 8px;
`;

export const SeasonReportStyledSelector = styled.div`
  display: grid;
  gap: 8px;
  margin-bottom: 20px;
`;
export const SeasonReportStyledCalendar = styled.div`
  margin-left: -14px;

  .ant-calendar-picker-input {
    max-width: 432px;
  }
`;

export const SeasonReportStyledComments = styled.div`
  margin-top: 8px;
  max-width: 432px;
`;
