import type { AllGeoJSON, BBox, Feature, Id, Point, Properties } from '@turf/turf';
import { COLOR_OPTIONS } from 'config/style';
import type { Dictionary } from 'config/types';
import type { Moment } from 'moment';
import type { MonitoringDetailThreshold } from 'pages/timeline/monitoring.models';
import type { Action, UUID } from './utils/basic.models';

interface CurrentLocation {
  path: string | null;
  title: string | null;
  icon: string | null;
}

interface LayoutState {
  showHeader: boolean;
  showSider: boolean;
  showMonitoringDrawer: boolean;
}

export interface IDateRange {
  from: Moment;
  to: Moment;
}

export interface DashboardState {
  showCompanyWidgets: boolean;
  selectedProperties: UUID[];
  selectedDateRange?: IDateRange;
}

export interface GlobalUiState {
  layoutState: LayoutState;
  currentLocation: CurrentLocation;
  selectedCompany: UUID | null;
  selectedProperty: UUID | null;
  interfaceLanguage: string | null;
  selectedRegion: UUID | null;
  selectedArea: UUID | null;
  currentCurrency: string;
  currentUnitSystem: string;
  appsPermission: unknown[] | null;
  dashboardState: DashboardState;
  selectedSeasons: UUID[];
  systemFlags: Dictionary<boolean | string> | null;
  loadedAppPermissions: boolean;
}

export type SelectEntityType = (entityId: UUID[] | UUID | null) => Action<UUID[] | UUID | null>;

/**
 * These enums are for the "wk_slug" field.
 * Note that the server might return the value in different casing (e.g. "wheat" vs "WHEAT").
 */
export enum CropTypesEnum {
  APPLE = 'apple',
  BEAN = 'bean',
  COFFEE = 'coffee',
  CORN = 'corn',
  CORN_MAIZ = 'maiz',
  COTTON = 'cotton',
  GRAPE = 'grape',
  HARD_WHEAT = 'hard_wheat',
  LEMON = 'lemon',
  ORANGE = 'orange',
  POTATO = 'potato',
  RICE = 'rice',
  SOYBEANS = 'soybeans',
  SOYBEAN = 'soybean',
  SPRING_BARLEY = 'spring_barley',
  SUGARCANE = 'sugarcane',
  TOMATO = 'tomato',
  WATERMELON = 'watermelon',
  WINTER_BARLEY = 'winter_barley',
  WINTER_WHEAT = 'winter_wheat',
  BRACHIARIA = 'brachiaria',
  ACEROLA = 'acerola',
  ALFALFA = 'alfalfa',
  ASPARAGUS = 'asparagus',
  ATEMOYA = 'atemoya',
  AVOCADO = 'avocado',
  BANANA = 'banana',
  BLUEBERRY = 'blueberry',
  CHERRY = 'cherry',
  COFFEE_BEAN = 'coffee_bean',
  OILSEED_RAPE = 'oilseed_rape',
  ONION = 'onion',
  SUNFLOWER = 'sunflower',
  TURNIP = 'turnip',
  /**
   * These two crops are generic ones (bigger than the disease risk
   * feature), and they can be used to filter regions from the Cropwise
   * platform, for example
   */
  WHEAT = 'wheat',
  BARLEY = 'barley'
}

export interface MappedThreshold {
  DAMAGE: MonitoringDetailThreshold;
  CONTROL: MonitoringDetailThreshold;
  ACCEPTANCE: MonitoringDetailThreshold;
}

export enum Threshold {
  DAMAGE = 'DAMAGE',
  ACCEPTANCE = 'ACCEPTANCE',
  CONTROL = 'CONTROL',
  NONE = 'NONE',
  SPRAY = 'SPRAY',
  ENDED = 'ENDED'
}

export const emptyThreshold: MonitoringDetailThreshold = {
  label: Threshold.NONE,
  color: COLOR_OPTIONS.SECONDARY,
  left: -1,
  right: -1
};

export enum ThresholdColor {
  DAMAGE = '#F74141',
  ACCEPTANCE = '#2FB966',
  CONTROL = '#FFCA28',
  SPRAY = '#0568FA',
  NONE = '#707374'
}

export type ListMultimap<B> = Dictionary<B[]>;
export type Nullable<T> = T | null;
export type Pair<A, B> = [A, B];

export type TurfCenter = <P = Properties>(
  geojson: AllGeoJSON,
  options?: {
    properties?: P;
    bbox?: BBox;
    id?: Id;
  }
) => Feature<Point, P>;

export type LatLngTuple = [number, number, number?];
