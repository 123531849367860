import { generateUUID } from 'core/utils/functions';
import { isNil, omitBy, size } from 'lodash';
import { create } from 'zustand';
import type {
  FieldClusterConfigurationStore,
  FieldClusteringRequestBody,
  IFieldClusteringConfigurationData
} from './field-clustering-configuration.models';
import { mapRiskParameters } from './field-clustering-configuration.utils';
import { invalidValues, parseAcceptanceLevelToPercent, parsePercentToAcceptanceLevel } from './steps/parameters/parameters.utils';

export const initialState = {
  selectedPhenomenaIds: undefined,
  minClusterSize: undefined,
  maxClusterSize: undefined,
  regionLevel: {
    level: undefined,
    size: undefined
  },
  density: undefined,
  minTrapsPerCluster: undefined,
  riskParameters: undefined,
  amountRiskParametersSelected: 0,
  acceptanceLevel: 0,
  purity: undefined,
  enableNextSteps: false
};

export const useFieldClusterConfigurationStore = create<FieldClusterConfigurationStore>()((set, get) => ({
  selectedPhenomenaIds: initialState.selectedPhenomenaIds,
  setSelectedPhenomenaIds: selectedPhenomenaIds => set({ selectedPhenomenaIds }),

  minClusterSize: initialState.minClusterSize,
  setMinClusterSize: minClusterSize => set({ minClusterSize }),

  maxClusterSize: initialState.maxClusterSize,
  setMaxClusterSize: maxClusterSize => set({ maxClusterSize }),

  regionLevel: initialState.regionLevel,
  setRegionLevel: regionLevel => set({ regionLevel }),

  density: initialState.density,
  setDensity: density => set({ density }),

  minTrapsPerCluster: initialState.minTrapsPerCluster,
  setMinTrapsPerCluster: minTrapsPerCluster => set({ minTrapsPerCluster }),

  riskParameters: initialState.riskParameters,
  setRiskParameters: riskParameters => set({ riskParameters }),

  amountRiskParametersSelected: initialState.amountRiskParametersSelected,
  setAmountRiskParametersSelected: amountRiskParametersSelected =>
    set({ amountRiskParametersSelected: amountRiskParametersSelected < 0 ? 0 : amountRiskParametersSelected }),

  acceptanceLevel: initialState.acceptanceLevel,
  setAcceptanceLevel: acceptanceLevel => set({ acceptanceLevel }),

  purity: initialState.purity,
  setPurity: purity => set({ purity }),

  enableNextSteps: initialState.enableNextSteps,
  setEnableNextSteps: enableNextSteps => set({ enableNextSteps }),

  resetState: () => set({ ...initialState }),

  getConfigPlanData: company_id => {
    const store = get();
    const data: FieldClusteringRequestBody = {
      id: generateUUID(),
      company_id: company_id,
      phenomenon_ids: store.selectedPhenomenaIds,
      basic_config: {
        max_cluster_size: store.maxClusterSize,
        min_cluster_size: store.minClusterSize,
        purity: store.purity,
        acceptance_level: parseAcceptanceLevelToPercent(store.acceptanceLevel, store.amountRiskParametersSelected),
        region_level: store.regionLevel?.level
      },
      trap_config: {
        min_per_cluster: store.minTrapsPerCluster,
        density: store.density
      },
      risk_parameters: mapRiskParameters(store.riskParameters ?? {})
    };

    return {
      ...data,
      basic_config: omitBy(data.basic_config, isNil),
      trap_config: omitBy(data.trap_config, isNil),
      risk_parameters: omitBy(data.risk_parameters, invalidValues)
    };
  },

  setConfigPlanData: (planData: IFieldClusteringConfigurationData) =>
    set({
      selectedPhenomenaIds: planData?.phenomenon_ids,
      minClusterSize: planData?.basic_config?.min_cluster_size ?? undefined,
      maxClusterSize: planData?.basic_config?.max_cluster_size ?? undefined,
      acceptanceLevel: parsePercentToAcceptanceLevel(planData?.basic_config?.acceptance_level, size(planData?.risk_parameters)),
      purity: planData?.basic_config?.purity ?? undefined,
      regionLevel: {
        level: planData?.basic_config?.region_level ?? undefined,
        size: undefined
      },
      density: planData?.trap_config?.density ?? undefined,
      minTrapsPerCluster: planData?.trap_config?.min_per_cluster ?? undefined,
      riskParameters: planData?.risk_parameters,
      amountRiskParametersSelected: size(planData?.risk_parameters),
      enableNextSteps: true
    })
}));
