import { C_LEVEL_REPORT } from 'authentication/contants';
import { CoreActionsTypes } from 'core/core.actions';
import { LoadProperties, LoadPropertiesAreaInfo, LoadPropertiesFailure } from 'entities/property/property.actions';
import { selectAllProperties } from 'entities/property/property.reducer';
import type { ActionsObservable, StateObservable } from 'redux-observable';
import { ofType } from 'redux-observable';
import type { AppState } from 'redux/app-state';
import { concat, of } from 'rxjs';
import { exhaustMap, filter, map, withLatestFrom } from 'rxjs/operators';
import type { SelectEntityType } from './core.models';
import { FakeLoadAll } from './services/analytics/widgets/widgets.actions';
import type { Action } from './utils/basic.models';

export const requestLoadCompanyData = (action$: ActionsObservable<Action<SelectEntityType>>, state$: StateObservable<AppState>) =>
  action$.pipe(
    ofType(CoreActionsTypes.SELECT_COMPANY),
    filter(() => !window.location.pathname.includes(C_LEVEL_REPORT)),
    withLatestFrom(
      state$.pipe(
        map((state: AppState) => {
          return state.uiState.global.selectedCompany;
        })
      ),
      state$.pipe(map((state: AppState) => (state$.entities ? selectAllProperties(state) : [])))
    ),
    exhaustMap(([, companyId, allProperties]) => {
      sessionStorage.setItem('company_id', companyId);
      if (!companyId) {
        return of(LoadPropertiesFailure("There isn't a selected Company"));
      }
      if (state$.entities && allProperties.length > 0) {
        return of(LoadPropertiesFailure('Properties already looaded'));
      }

      return concat([
        LoadProperties({
          pageable: {
            page: 0,
            size: 10
          },
          query: {
            params: new Map([['company_id', companyId]])
          }
        }),
        LoadPropertiesAreaInfo({
          pageable: {
            page: 0,
            size: 10
          },
          query: {
            params: new Map([['company_id', companyId]])
          }
        }),
        FakeLoadAll()
      ]);
    })
  );
