import * as Sentry from '@sentry/react';
import { Modal, Typography } from 'antd';
import { Flex } from 'antdV5';
import images from 'assets';
import { COLOR_OPTIONS } from 'config/style';
import STTypo, { STTypoModels } from 'core/shared/typo';
import useSegmentTracking from 'core/utils/segment/useSegmentTracking';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledButton, StyledErrorContainer, StyledTextLink } from './something-went-wrong.styles';

export const SomethingWentWrong = ({ error, eventId }: Parameters<Sentry.FallbackRender>[0]) => {
  const [t] = useTranslation();

  const openUserFeedback = () => {
    Sentry.showReportDialog({
      eventId: eventId ?? undefined,
      title: t('whoops.sentry.title'),
      subtitle: t('whoops.sentry.subtitle'),
      subtitle2: t('whoops.sentry.subtitle2'),
      labelName: t('whoops.sentry.labelName'),
      labelComments: t('whoops.sentry.labelComments'),
      labelClose: t('whoops.sentry.labelClose'),
      labelSubmit: t('whoops.sentry.labelSubmit'),
      successMessage: t('whoops.sentry.successMessage')
    });
  };

  const isChunkError = /failed to fetch/i.test((error as Error).message);

  useEffect(() => {
    if (isChunkError) {
      Modal.info({
        title: t('modals.update.title'),
        content: <Typography.Text>{t('modals.update.description')}</Typography.Text>,
        onOk: () => window.location.reload(),
        okText: t('modals.update.button'),
        centered: true,
        keyboard: false,
        transitionName: '',
        maskTransitionName: ''
      });
    }
  }, [isChunkError, t]);

  const segmentTracking = useSegmentTracking();
  const pathLocation = window?.location?.pathname;
  const screen = pathLocation?.split('/')?.[5] ?? pathLocation;

  useEffect(() => {
    segmentTracking.track(`Whoops Screen - Something went wrong`, { isChunkError, segmentEventId: eventId, screen });
  }, [eventId, isChunkError, screen, segmentTracking]);

  const handleReloadPage = useCallback(() => {
    segmentTracking.track(`Whoops Screen - Clicked button try again`, { isChunkError, segmentEventId: eventId, screen });
    window.location.reload();
  }, [eventId, isChunkError, screen, segmentTracking]);

  if (isChunkError) {
    return <></>;
  }

  return (
    <StyledErrorContainer data-testid='something-went-wrong-container'>
      <img src={images.page_couldnt_load} alt='Error illustration' />
      <Flex align='center' justify='center' vertical>
        <STTypo
          type={STTypoModels.TypeOptions.H2}
          color={COLOR_OPTIONS.NEUTRAL_100}
          fontWeight={600}
          fontFamily='Noto Sans'
          lineHeight='48px'>
          {t('whoops.page_could_not_be_loaded.title')}
        </STTypo>
        <STTypo type={STTypoModels.TypeOptions.H4} color={COLOR_OPTIONS.NEUTRAL_90} fontFamily='Noto Sans' lineHeight='32px'>
          {t('whoops.page_could_not_be_loaded.description')}
        </STTypo>
        <STTypo type={STTypoModels.TypeOptions.H4} color={COLOR_OPTIONS.NEUTRAL_90} fontFamily='Noto Sans' lineHeight='32px'>
          {t('whoops.page_could_not_be_loaded.please_try_again')}
        </STTypo>
      </Flex>

      <StyledButton size='large' type='primary' onClick={handleReloadPage}>
        {t('report.try_again')}
      </StyledButton>

      <Flex align='center' justify='center' vertical>
        <STTypo color={COLOR_OPTIONS.NEUTRAL_90} fontFamily='Noto Sans' lineHeight='32px'>
          {t('whoops.whoops.automaticamente')}{' '}
          <StyledTextLink href='#/' data-testid='whoops-open' onClick={openUserFeedback}>
            {t('whoops.whoops.btn')}
          </StyledTextLink>{' '}
          {t('whoops.whoops.help')}
        </STTypo>
      </Flex>
    </StyledErrorContainer>
  );
};
