import { Card } from 'antdV5';
import { COLOR_OPTIONS } from 'config/style';
import styled from 'styled-components';

export const StyledCard = styled(Card)`
  width: 100%;
  text-align: center;
  border-radius: 10px;
  padding: 24px;
  cursor: pointer;
  transition: all 0.3s ease;
  border: none;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  &:hover {
    background-color: ${COLOR_OPTIONS.NEUTRAL_10};
    box-shadow: none;
  }

  .ant-card-body {
    padding: 0;
  }
`;

export const StyledIconWrapper = styled.div`
  background-color: ${COLOR_OPTIONS.GRAY_7};
  border-radius: 50%;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 17px;
`;

export const StyledContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledTitle = styled.div`
  margin: 0;
  color: ${COLOR_OPTIONS.GRAY_1};
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 24px;
`;

export const StyledDescription = styled.div`
  color: ${COLOR_OPTIONS.NEUTRAL_60};
  font-size: 14px;
`;
