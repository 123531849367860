import React from 'react';

type PaperDownProps = Readonly<
  {
    fill?: string;
  } & React.SVGProps<SVGSVGElement>
>;

function PaperDownComponent({ fill = 'currentColor', ...props }: PaperDownProps) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 25 24' fill={fill} {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.848 3.357c.552 0 1 .463 1 1.035v5.18c0 .571.447 1.035 1 1.035.552 0 1-.464 1-1.036V4.392c0-1.716-1.343-3.107-3-3.107h-10c-1.657 0-3 1.391-3 3.107v14.5c0 1.717 1.343 3.108 3 3.108h5c.552 0 1-.464 1-1.036 0-.572-.448-1.035-1-1.035h-5c-.553 0-1-.464-1-1.036v-14.5c0-.573.447-1.036 1-1.036h10zm3.173 13.382l-1.173 1.326v-4.35c0-.573-.448-1.037-1-1.037-.553 0-1 .464-1 1.036v4.339l-1.163-1.314a1.03 1.03 0 00-1.523 0 1.232 1.232 0 000 1.643l2.93 3.277a1.01 1.01 0 001.512 0l2.93-3.277a1.232 1.232 0 000-1.643 1.01 1.01 0 00-1.513 0zM7.848 7.464a1 1 0 011-1h6a1 1 0 011 1v.071a1 1 0 01-1 1h-6a1 1 0 01-1-1v-.071zm1 3.143a1 1 0 00-1 1v.072a1 1 0 001 1h4.84a1 1 0 001-1v-.072a1 1 0 00-1-1h-4.84z'
      />
      <rect x={7.848} y={14.418} width={4.195} height={2.071} rx={1} />
    </svg>
  );
}

const PaperDownIcon = React.memo(PaperDownComponent);
export default PaperDownIcon;
