import { Flex } from 'antdV5';
import { COLOR_OPTIONS } from 'config/style';
import { Entitlements } from 'core/shared/enums/entitlements.enum';
import { useHasAllEntitlements } from 'core/utils/hooks/use-has-all-entitlement';
import { useCallback } from 'react';
import PaperDownIcon from 'st-protector-icons/Glyph/PaperDownIcon';
import SvgDiseaseRisk from 'st-protector-icons/Minor/DiseaseRisk';
import { ReportsTypes } from '../reports-types.enum';
import HeaderTitle from './header-title.component';
import { ReportDiseaseRisk } from './report-disease-risk';
import { ReportNotesCard } from './report-disease-risk-card';
import ReportDiseaseRiskNotes from './report-disease-risk-notes';
import { useReportDiseaseRiskSelectOptions } from './report-disease-risk-select-options.hook';
import { StyledDrawer } from './report-disease-risk-select-options.style';

interface ReportDiseaseRiskSelectOptionsProps {
  open: boolean;
  onClose: () => void;
  closeMainDrawer: () => void;
}

const ReportDiseaseRiskSelectOptions = ({ open, onClose, closeMainDrawer }: ReportDiseaseRiskSelectOptionsProps) => {
  const { reportOptions, drawerToOpen, handleCloseDrawer } = useReportDiseaseRiskSelectOptions();
  const hasDiseaseRiskRecommendations = useHasAllEntitlements([Entitlements.REPORT_DISEASE_RISK_RECOMMENDATIONS]);

  const handleOnClose = useCallback(() => {
    onClose();
    handleCloseDrawer();
  }, [onClose, handleCloseDrawer]);

  if (!hasDiseaseRiskRecommendations) {
    return <ReportDiseaseRisk visible callbackDrawer={onClose} icon={<PaperDownIcon fontSize='24px' fill={COLOR_OPTIONS.ORANGE_50} />} />;
  }

  return (
    <>
      <StyledDrawer
        title={<HeaderTitle icon={<SvgDiseaseRisk fill={COLOR_OPTIONS.ORANGE_50} />} type={ReportsTypes.DISEASE_RISK} />}
        placement='right'
        onClose={onClose}
        open={open}
        width={480}
        centerContent>
        <Flex gap={16} justify='center'>
          {reportOptions.map(
            option =>
              !option.disabled && (
                <ReportNotesCard
                  key={option.title}
                  title={option.title}
                  description={option.description}
                  icon={option.icon}
                  onClick={option.onClick}
                />
              )
          )}
        </Flex>
      </StyledDrawer>

      <ReportDiseaseRisk
        visible={drawerToOpen === ReportsTypes.DISEASE_RISK}
        callbackDrawer={handleOnClose}
        icon={<PaperDownIcon fontSize='24px' fill={COLOR_OPTIONS.ORANGE_50} />}
      />

      <ReportDiseaseRiskNotes
        open={drawerToOpen === ReportsTypes.DISEASE_RISK_NOTES}
        onClose={handleOnClose}
        closeMainDrawer={closeMainDrawer}
      />
    </>
  );
};

export default ReportDiseaseRiskSelectOptions;
