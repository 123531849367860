import { type TabsProps } from 'antdV5';
import type { Dictionary } from 'config/types';
import { isActive } from 'entities/season/season.functions';
import type { Season } from 'entities/season/season.models';
import type Quill from 'quill';
import { type MutableRefObject, useMemo } from 'react';
import { useSelector } from 'react-redux';
import type { AppState } from 'redux/app-state';
import { ReportDiseaseRiskNotesTabsRichText } from './report-disease-risk-notes-tabs-rich-text';
import { StyledTabs } from './report-disease-risk-notes-tabs.style';
import type { RecommendationNote } from './report-disease-risk-notes.type';

interface ReportDiseaseRiskNotesTabsProps {
  handleOnChange: (seasonId: string, ref: Quill | null) => void;
  notesRef: MutableRefObject<RecommendationNote[]>;
}

const ReportDiseaseRiskNotesTabs = ({ handleOnChange, notesRef }: ReportDiseaseRiskNotesTabsProps) => {
  const allSeasons = useSelector<AppState, Dictionary<Season>>(state => state.entities.season.entities);
  const activeSeasons = useMemo(() => Object.values(allSeasons).filter(isActive), [allSeasons]);

  const items: TabsProps['items'] = activeSeasons.map(season => {
    return {
      key: season.id,
      label: season.name,
      children: <ReportDiseaseRiskNotesTabsRichText seasonId={season.id} onChange={handleOnChange} notesRef={notesRef} />
    };
  });

  return <StyledTabs items={items} />;
};

export default ReportDiseaseRiskNotesTabs;
