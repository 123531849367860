import PaperDownIcon from 'st-protector-icons/Glyph/PaperDownIcon';

import { COLOR_OPTIONS } from 'config/style';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PaperPencilIcon from 'st-protector-icons/Glyph/PaperPencilIcon';
import { ReportsTypes } from '../reports-types.enum';

interface ReportOption {
  title: string;
  description: string;
  icon: React.ReactNode;
  onClick?: () => void;
  disabled?: boolean;
}

export const useReportDiseaseRiskSelectOptions = () => {
  const [t] = useTranslation();
  const [drawerToOpen, setDrawerToOpen] = useState<ReportsTypes | null>(null);

  const handleOpenDrawer = (drawer: ReportsTypes) => {
    setDrawerToOpen(drawer);
  };

  const handleCloseDrawer = () => {
    setDrawerToOpen(null);
  };

  const reportOptions: ReportOption[] = [
    {
      title: t('report.disease_risk.options.report_notes_creation.card_title'),
      description: t('report.disease_risk.options.report_notes_creation_description'),
      icon: <PaperPencilIcon fill={COLOR_OPTIONS.ORANGE_50} fontSize='24px' />,
      disabled: false,
      onClick: () => handleOpenDrawer(ReportsTypes.DISEASE_RISK_NOTES)
    },
    {
      title: t('report.disease_risk.options.on_demand_report_generation'),
      description: t('report.disease_risk.options.on_demand_report_generation_description'),
      icon: <PaperDownIcon fill={COLOR_OPTIONS.ORANGE_50} fontSize='24px' />,
      disabled: false,
      onClick: () => handleOpenDrawer(ReportsTypes.DISEASE_RISK)
    }
  ];

  return { reportOptions, drawerToOpen, handleCloseDrawer };
};
