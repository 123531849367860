import { Divider as antdDivider } from 'antd';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
`;

const Divider = styled(antdDivider)`
  margin: 4px 0px;
  background-color: #4d5165 !important;
`;

export { Divider, Wrapper };
