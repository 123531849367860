const trustedDomains = ['.strider.ag', '.cropwise.com', '.syngentadigital.co.uk', '.us-west-2.amazonaws.com'];

const errorHandlingUrls = [
  '/v2/orgs',
  '/panel/properties',
  '/v2/store/workspaces/apps',
  '/v2/accounts/me',
  '/v2/licensing/status',
  '/v2/workspaces'
];

export const isTrustedDomain = (url: string): boolean => {
  try {
    const { hostname } = new URL(url);

    return trustedDomains.some(domain => hostname.endsWith(domain));
  } catch {
    return false;
  }
};

const isErrorHandlingUrls = (url: string): boolean => {
  return errorHandlingUrls.some(error => url.includes(error));
};

export const shouldRaiseError = (url: string): boolean => {
  if (!isTrustedDomain) return false;
  if (!isErrorHandlingUrls(url)) return false;

  return true;
};
