import type { DateISOString, UUID } from 'core/utils/basic.models';

export enum WidgetStatus {
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
  EMPTY = 'EMPTY',
  LOADING = 'LOADING'
}

interface Widget {
  data: unknown[];
  status: WidgetStatus;
}

export interface WidgetSetting {
  index: number;
  selected: boolean;
}

interface CompanyWidgets {
  duration_damage: Widget;
  most_common_issues: Widget;
  sprays: Widget;
  monitoring: Widget;
  tasks: Widget;
}

interface PropertyWidgets {
  heatmap_over_time: Widget;
  individual_scouter: Widget;
  monitoring_kpi: Widget;
  product_types: Widget;
  scouter_performance_over_time: Widget;
  tasks_property: Widget;
  abandoned_areas: Widget;
  tasks_list: Widget;
  scouter_distance_time_avg: Widget;
  most_common_issues_property: Widget;
  company_weather: Widget;
  weather: Widget;
}

export interface WidgetsSettingsData {
  heatmap_over_time: WidgetSetting;
  individual_scouter: WidgetSetting;
  monitoring_kpi: WidgetSetting;
  product_types: WidgetSetting;
  scouter_performance_over_time: WidgetSetting;
  tasks_property: WidgetSetting;
  abandoned_areas: WidgetSetting;
  tasks_list: WidgetSetting;
  scouter_distance_time_avg: WidgetSetting;
  most_common_issues_property: WidgetSetting;
  company_weather: WidgetSetting;
  weather: WidgetSetting;
}

interface WidgetsSettings {
  status: WidgetStatus;
  currentDashboard: UUID | null;
  data: Partial<WidgetsSettingsData>;
}

export interface WidgetsState {
  companyWidgets: CompanyWidgets;
  propertyWidgets: PropertyWidgets;
  widgetsSettings: WidgetsSettings;
}

export interface IPayloadsMostCommonIssues {
  properties_ids: string[];
  season_ids: string[];
  start: moment.Moment | string;
  end: moment.Moment | string;
}

export enum EWidgets {
  INDIVIDUAL_SCOUTER = 'individual_scouter', // Individual Scouter Performace
  SCOUTER_DISTANCE_TIME_AVG = 'scouter_distance_time_avg', // Distance and Avg Time per Technician
  MOST_COMMON_ISSUES_PROPERTY = 'most_common_issues_property', // Most Common Issue Graph
  MOST_COMMON_ISSUES_PROPERTY_REPORT = 'most_common_issues_property_report', // Most Common Issue Graph
  MONITORING_KPI = 'monitoring_kpi', // Monitoring KPI
  SCOUTER_PERFORMANCE_OVER_TIME = 'scouter_performance_over_time', // Technicians performace
  MONITORING = 'monitoring', // Monitoring comparison
  MOST_COMMON_ISSUES = 'most_common_issues', // Most Common Issue Graph - o mesmo, porem com outra funcionalidade
  COMPANY_WEATHER = 'company_weather', // Company Weather
  TASKS = 'tasks', // task overview
  TASKS_PROPERTY = 'tasks_property', // nao identificado no figma, aparece no sistema em 'widget-task-farm'
  SPRAYS = 'sprays', // applocations comparison
  PRODUCT_TYPES = 'product_types', // Product Type of Applications
  DURATION_DAMAGE = 'duration_damage', // Duration Demage
  HEATMAP_OVER_TIME = 'heatmap_over_time', // Heatmap Over Time
  ABANDONED_AREAS = 'abandoned_areas', // Days without monitoring
  TASKS_LIST = 'tasks_list', // task lista table
  WEATHER = 'weather' // task lista table
}

export type WidgetId = EWidgets;
export type WidgetLoadError = string | null;

export enum WidgetType {
  COMPANY = 'companyWidgets',
  PROPERTY = 'propertyWidgets'
}

export interface WidgetRequest {
  start: DateISOString;
  end: DateISOString;
  uuid?: UUID;
  seasons?: UUID[];
  properties_ids?: UUID[];
}

export interface PostSettingsProps {
  id: string;
  name: string;
  company_id: string;
  widgets: (
    | {
        name: string;
        order: number;
      }
    | undefined
  )[];
}
