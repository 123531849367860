import type { UUID } from 'core/utils/basic.models';
import type { JobStatusEnum } from 'syngenta-digital-react-cropwise-nemadigital';
import type { NemadigitalCropCycleReport } from 'syngenta-digital-react-cropwise-nemadigital/dist/module/map-overlay/map-overlay.types';

export enum NemadigitalQueryType {
  GET_PROPERTY_NEMADIGITAL_JOBS = 'GET_PROPERTY_NEMADIGITAL_JOBS',
  GET_NEMADIGITAL_GEOJSON = 'GET_NEMADIGITAL_GEOJSON'
}

interface NemadigitalFieldResult {
  field_id: string;
  damage: number;
  end_date: string;
  confidence_index: number;
  link_nematode_damage_map: string;
  link_nematode_damage_parcel: string;
  link_nematode_geojson: string;
}

interface NemadigitalReport {
  crop_cycle_year_start: string;
  crop_cycle_year_end: string;
  report_type: string;
  job_id: number;
  execution_id: number;
  status: JobStatusEnum;
  damage: {
    '0-4%': number;
    '4-8%': number;
    '8-12%': number;
    '>=12%': number;
  };
  fields: NemadigitalFieldResult[];
}

export interface NemadigitalExecution {
  property_id: UUID;
  reports: NemadigitalReport[];
}

interface ExtendedNemadigitalCropCycleReport extends NemadigitalCropCycleReport {
  executionId?: string;
}

export interface NemadigitalPropertyReports {
  cropCycles: string[];
  reportsPerCropCycles: Record<string, ExtendedNemadigitalCropCycleReport>;
}

export interface NemadigitalPropertyReportsQueryResponse {
  cropCycles: string[];
  reportsPerCropCycles: Record<string, NemadigitalCropCycleReport>;
  isLoadingNemadigital: boolean;
  lastCropCyclesFinished: string;
}

export enum NemadigitalMapTypes {
  CHOROPLETH_MAP = 'CHOROPLETH_MAP',
  INTENSITY_MAP = 'INTENSITY_MAP'
}
