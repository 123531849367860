import type { GlobalUiState } from 'core/core.models';
import STTypo from 'core/shared/typo';
import { TypeOptions } from 'core/shared/typo/typo.models';
import { useGetPropertyLatestExecution } from 'querys/nemadigital/nemadigital.query';
import type React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NemaSamplingReportSvgCard } from 'st-protector-icons/Minor/NemaSamplingReportCard';
import { useShowOnlyReportsForAllOrg } from '../report-drawer.component.hook';
import { ReportsTypes } from '../reports-types.enum';
import ReportButton from './report-button.component';

interface ReportDrawerNemaDigitalButtonsProps {
  setReportToOpen: (reportType: ReportsTypes) => void;
  systemFlags: GlobalUiState['systemFlags'];
}

export const ReportDrawerNemaDigitalButtons: React.FC<ReportDrawerNemaDigitalButtonsProps> = ({ setReportToOpen, systemFlags }) => {
  const showOnlyReportsForAllOrg = useShowOnlyReportsForAllOrg();
  const [enableQuery, setEnableQuery] = useState(true);
  const [t] = useTranslation();
  const { lastCropCyclesFinished } = useGetPropertyLatestExecution(enableQuery);

  useEffect(() => {
    if (lastCropCyclesFinished !== '') {
      setEnableQuery(false);
    }
  }, [lastCropCyclesFinished]);

  const isNemaSamplingPointsReportEnabled = lastCropCyclesFinished !== '' && !!systemFlags?.P40_45235_nema_sampling_points_report;

  if (!isNemaSamplingPointsReportEnabled || showOnlyReportsForAllOrg) return null;

  return (
    <>
      <div className='st-report-drawer__subtitle-group' data-testid='report-subtitle'>
        <STTypo type={TypeOptions.P2} color='#696F88' uppercase>
          <STTypo>{t('report.title.group.nema_digital')}</STTypo>
        </STTypo>
      </div>

      <ReportButton
        className='st-report-drawer__item-sample'
        setReportToOpen={setReportToOpen}
        text={t('report.nema_soil_sampling.name')}
        type={ReportsTypes.NEMA_SAMPLING_POINTS}
        icon={<NemaSamplingReportSvgCard />}
      />
    </>
  );
};
